// Core
import {MutableRefObject, useLayoutEffect, useRef, useState} from 'react'


type TDimensions = {
	width: number
	height: number
}

type TResolve = [
	MutableRefObject<HTMLDivElement | null>,
	TDimensions
]

const useSizeComponent = (): TResolve => {
	const targetRef = useRef<HTMLDivElement>(null)
	const [dimensions, setDimensions] = useState<TDimensions>({ width: 0, height: 0 })

	// holds the timer for setTimeout and clearInterval
	const timer = useRef<any>(null)

	// the number of ms the window size must stay the same size before the
	// dimension state variable is reset
	const RESET_TIMEOUT = 100

	const test_dimensions = () => {
		// For some reason targetRef.current.getBoundingClientRect was not available
		// I found this worked for me, but unfortunately I can't find the
		// documentation to explain this experience
		if (targetRef.current) {
			setDimensions({
				width: targetRef.current.offsetWidth,
				height: targetRef.current.offsetHeight
			})
		}
	};

	// This sets the dimensions on the first render
	useLayoutEffect(() => {
		test_dimensions();
	}, [])

	// every time the window is resized, the timer is cleared and set again
	// the net effect is the component will only reset after the window size
	// is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
	// redrawing of the component for more complex components such as charts
	window.addEventListener("resize", () => {
		clearInterval(timer.current)
		timer.current = setTimeout(test_dimensions, RESET_TIMEOUT);
	})

	return [
		targetRef,
		dimensions,
	]
}

export default useSizeComponent
