// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Action
import {cabinetMainActions, portfolioActions} from 'actions/cabinet'
// Selector
import {cabinetMainSelect, portfolioSelect} from 'selectors/cabinet'
// Actions
import {appActions} from "actions";
// Types
import {TWalletItem} from "types/cabinet/portfoli-type";


export const useReceiveAssetsModalEf = () => {
    const dispatch = useDispatch()


    // Selectors

    const receiveAssetsMode = useSelector(cabinetMainSelect.receive_asset_mode)
    const wallets = useSelector(portfolioSelect.wallets)
    const isQrCode = useSelector(cabinetMainSelect.receive_asset_only_qr)
    const config = useSelector(cabinetMainSelect.receive_assets_config)
    const title = useSelector(cabinetMainSelect.receive_assets_title)
    const portfolioWalletDetails = useSelector(portfolioSelect.currency_details_data)


    // State

    const [wallet, setWallet] = useState<TWalletItem | null>(null)
    const [selected, setSelected] = useState<string | undefined>(undefined)


    // Effects

    useEffect(() => {
        !wallets && dispatch(portfolioActions.watch_wallet())
    },[wallets])

    useEffect(() => {
        isQrCode && portfolioWalletDetails && setWallet(getWallet(portfolioWalletDetails.id) || null)

        isQrCode && portfolioWalletDetails && setSelected(portfolioWalletDetails.id)

    },[isQrCode, portfolioWalletDetails])

    // return wallet via {id} param
    const getWallet = (id: string) => wallets?.items.find(item => item.id === id)

    // Handlers

    const handlerClose = () => {
        dispatch(cabinetMainActions.set_receive_asset_mode(false, false))
        setWallet(null)
        handlerClearSelect()
    }

    const handlerSelectChange = (value: string) => {
        const wallet = getWallet(value)
        setSelected(value)

        wallet && setWallet(wallet)
    }

    const handlerClearSelect = () => {
        setSelected(undefined)
    }

    const handlerCopy = () => {
        dispatch(appActions.set_push({
            title: 'Success',
            content: 'Wallet address has been successfully copied'
        }))
    }

    return {
        receiveAssetsMode, wallets, wallet, isQrCode, selected, config, title,
        handlerSelectChange, handlerClose, handlerCopy
    }
}
