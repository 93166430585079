// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Action
import {cabinetMainActions} from "actions/cabinet"
// Selector
import cabinetMainSelect from 'selectors/cabinet/cabinet-main-selector'
import {string} from "yup";


export const useNotificationEf = () => {
    const dispatch = useDispatch()


    // Selectors

    const notificationMode = useSelector(cabinetMainSelect.notification_mode)
    const notifications = useSelector(cabinetMainSelect.notifications)


    // Effects


    // Handlers

    const handlerNotification = () => {
        dispatch(cabinetMainActions.set_notification_mode(!notificationMode))
    }

    const handlerReadAll = () => {
        dispatch(cabinetMainActions.watch_read_notifications())
    }

    return {
        notificationMode, notifications,
        handlerNotification, handlerReadAll
    }
}
