// Core
import {useSelector, useDispatch} from 'react-redux'
// Action
import {portfolioActions} from 'actions/cabinet'
// Selector
import {portfolioSelect} from 'selectors/cabinet'
// Hook
import {useParseToCurrency} from "hooks"
// Types
import {RadioChangeEvent} from "antd/lib/radio"
import {EWalletDuration} from "../../../../../../types/cabinet/cabinet-main-type";


export const useBalanceEf = () => {
    const dispatch = useDispatch()


    // Store

    const durationsConfig = [
            {
                name: '1d',
                value: EWalletDuration.DAY
            },
            {
                name: '1w',
                value: EWalletDuration.WEEK
            },
            {
                name: '1m',
                value: EWalletDuration.MOUNTH
            },
            {
                name: 'All time',
                value: EWalletDuration.ALL_TIME
            },
        ]


    // Selectors

    const loadFirst = useSelector(portfolioSelect.load_first)
    const loadBalanceChart = useSelector(portfolioSelect.load_balance_chart)
    const chart = useSelector(portfolioSelect.chart)
    const balanceDuration = useSelector(portfolioSelect.balance_duration)
    const balance = useSelector(portfolioSelect.balance)

    const parseValue = useParseToCurrency(balance?.base_fiat_sign)


    // Handlers

    const handlerChange = (e: RadioChangeEvent) => {
        dispatch(portfolioActions.watch_balance(e.target.value))
    }

    const handlerChangeResent = () => {
        dispatch(portfolioActions.watch_balance(balanceDuration))
    }


    return {
        chart, loadFirst: !loadFirst.includes('balance'), loadBalanceChart, balanceDuration, parseValue, durationsConfig,
        handlerChange, handlerChangeResent
    }
}
