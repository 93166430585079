// Core
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
// Actions
import {appSelect, authSelect} from "selectors"


export const useMainEf = () => {
    const isAuth = useSelector(authSelect.is_auth)
    const lang = useSelector(appSelect.language)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [window])

    return {
        isAuth, lang,
    }
}
