export default (type: 'lower' | 'upper', val: string,  symbols =  [] as number[]): string => {
	const stub = type === 'lower' ? val.toLocaleLowerCase() : val.toLocaleUpperCase()

	if (symbols.length) {
		const data = stub.split('')

		symbols.forEach(elem => {
			data[elem] && (data[elem] =  type === 'lower' ? data[elem].toLocaleUpperCase() : data[elem].toLocaleLowerCase())
		})

		return data.join('')
	}
	return stub
}