// Core
import React, {FC, memo} from 'react'
// Ant Components
import {DatePicker, Modal} from 'antd'
// Ant Icon
import {LoadingOutlined} from '@ant-design/icons'
// Style
import './GenerateReport.scss'
// Hook
import {useGenerateReportEf} from './useGenerateReportEf'


import {Button, ComingSoon, Typography} from "elements"
import {Form, Radio, Select} from "formik-antd";
import {config, selectCaseString} from "../../../../../../utils";
import {Formik} from "formik";


type PropsType = {
    children?: never
}

const GenerateReport: FC<PropsType> = memo(() => {
    const {
        generateReportMode, showRange, selectedCurrency, wallets, type, duration, loadGeneratePdf,
        handlerClose, handlerSubmit, handlerSelectCurrencyChange, handlerType, handlerDuration, handlerDateRange
    } = useGenerateReportEf()

    const init = {
        walletId: '',
        type: 'all',
        duration:  config.cabinet.portfolio.report.duration[0]
    }

    return (
        <Modal
            title={'Generate report '}
            centered
            visible={generateReportMode}
            onCancel={handlerClose}
            width={421}
            footer={false}
        >
            <Formik
                onSubmit={handlerSubmit}
                initialValues={init}
                // validationSchema={SignupSchema}
                enableReinitialize={true}
            >
                {(formik) => (
                    <Form>
                            <Form.Item
                                name={'walletId'}
                                label={'Assets'}
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                            >
                                <Select
                                    allowClear
                                    placeholder="Select assets"
                                    style={{ maxWidth: '210px' }}
                                    className={'input-select rotate-false'}
                                    name='walletId'
                                    value={selectedCurrency}
                                    onChange={handlerSelectCurrencyChange}
                                >
                                    {
                                        wallets?.items.map(elem => (
                                            <Select.Option key={elem.name} value={elem.id}>
                                                <div className={`transaction-filter__option`}>
                                                    <img src={`${config.app.apiURL}${elem.icon}`} alt={elem.name}/>
                                                    {elem.name}
                                                </div>
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={'type'}
                                label={'Transaction type'}
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                            >
                                <Radio.Group
                                    name={'type'}
                                    value={type}
                                    onChange={handlerType}
                                >
                                    {
                                        config.cabinet.portfolio.report.type.map(elem => <Radio.Button value={elem}>
                                            {selectCaseString('lower', elem, [0])}
                                        </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name={'duration'}
                                label={'Date range'}
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                            >
                                <Radio.Group
                                    name={'duration'}
                                    value={duration}
                                    onChange={handlerDuration}
                                >
                                    {
                                        config.cabinet.portfolio.report.duration.map(elem => <Radio.Button value={elem}>
                                            {selectCaseString('lower', elem.title, [0])}
                                        </Radio.Button>)
                                    }
                                </Radio.Group>
                            </Form.Item>
                            {
                                showRange &&
                                <div style={{marginBottom: 24}}>
                                    <div style={{display: 'flex', marginBottom: 10}}>
                                        <Typography type={'text-16'} color={'primary'}>
                                            From
                                        </Typography>
                                        <Typography style={{marginLeft: 105}} type={'text-16'} color={'primary'}>
                                            To
                                        </Typography>
                                    </div>
                                    <DatePicker.RangePicker
                                        clearIcon={false}
                                        format={'DD/MM/YY'}
                                        // value={hackValue || valueDates}
                                        // disabledDate={disabledDate}
                                        onCalendarChange={handlerDateRange}
                                        //onChange={handlerDateRange}
                                        // onOpenChange={onOpenChange}
                                    />
                                </div>
                            }
                            <Button
                                className={'send-assets__submit'}
                                type="submit"
                                size={"full"}
                                color={"secondary"}
                                variant={"contained"}
                                loaderPosition={"center"}
                                disabled={!selectedCurrency}
                                loader={
                                    loadGeneratePdf
                                        ? () => <LoadingOutlined className={'sign__loader-icon'} spin/>
                                        : null
                                }
                            >
                                Generate report
                            </Button>
                    </Form>
                    )}
            </Formik>
        </Modal>
    )
})

export default GenerateReport
