// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Selector
import { portfolioSelect } from 'selectors/cabinet'
// Action
import {cabinetMainActions, portfolioActions} from 'actions/cabinet'
import {appActions} from 'actions'


export const useHeaderPortfolioActionEf = () => {
	const dispatch = useDispatch()


	// Selectors

	const loadCurrencyDetail = useSelector(portfolioSelect.load_currency_detail)
	const currency_detailsData = useSelector(portfolioSelect.currency_details_data)
	const wallets = useSelector(portfolioSelect.wallets)


	// Handlers

	const handlerAsset = () => {
		dispatch(cabinetMainActions.set_send_asset_mode(true))
	}

	const handlerFavorite = (id: string, inFavorites: boolean) => {

		const wallet = wallets ? wallets.items.find(item => item.cur_id === id) : null

		inFavorites
			? wallet && dispatch(cabinetMainActions.watch_remove_favorites({...wallet, id: wallet.cur_id}))
			: wallet && dispatch(cabinetMainActions.watch_add_favorites({...wallet, id: wallet.cur_id}))
	}

	const handlerCopy = () => {
		dispatch(appActions.set_push({
			title: 'Success',
			content: 'Wallet address has been successfully copied'
		}))
	}

	const handlerQr = () => {
		dispatch(cabinetMainActions.set_receive_asset_mode(true, true, null, 'Wallet address'))
	}

	return {
		loadCurrencyDetail, currency_detailsData,
		handlerAsset, handlerFavorite, handlerCopy, handlerQr,
	}
}
