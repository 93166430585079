// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button, Input, Spin, Table, Skeleton} from 'antd'
// Ant Icon
import {
    ArrowDownOutlined,
    FilterOutlined,
    LoadingOutlined,
    SearchOutlined,
} from '@ant-design/icons'
// Style
import './TransactionsTable.scss'
// Element
import {NodeError} from "#/elements"
import {Typography} from 'elements'
// Icon
import {EmptyStatus} from 'icons'
// Components
import {TransactionInfo, TransactionFilter, TransactionFilterTag} from '../'
// Hook
import {useTransactionsTableEf} from './useTransactionsTableEf'
// Utils
import {config} from 'utils'
// Type
import {
    TTransactionData,
    TTransactionItem,
    TTransactionQueryBase,
    TTransactionQueryFilter
} from 'types/cabinet/transactions'
import {Key, SorterResult, TablePaginationConfig} from 'antd/lib/table/interface'

type TColumns = {
    loading: boolean
    sort: string | null
    symbol: string
}

const columns = ({sort, symbol, loading}: TColumns) => ([
    {
        title: 'Date/Time',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        defaultSortOrder: sort === 'date'
            ? 'ascend'
            : sort === '-date'
                ?  'descend'
                : false,
        render: (text: string, record: TTransactionItem) => <Typography type={'text-16'} color={'gray'}>{record.created_at}</Typography>
    },
    {
        title: 'Assets Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        defaultSortOrder: sort === 'currency'
            ? 'ascend'
            : sort === '-currency'
                ?  'descend'
                : false,
        render: (text: string, record: TTransactionItem) => (
            <div className={'base-table__icon-wrap'}>
                <div className={'base-table__icon'}>
                    <img src={`${config.app.apiURL}${record.icon}`} alt={record.name} className={'ant-image-img'}/>
                </div>
                {record.full_name}
            </div>
        )
    },
    {
        title: 'Transaction type',
        dataIndex: 'type_id',
        key: 'type_id',
        render: (text: string, record: TTransactionItem) => (
            <div className={`portfolio-transactions__table__item__type`}>
                <ArrowDownOutlined className={`portfolio-transactions__table__item__type_${record.type_id === 'OUT' ? 'up' : 'down'}`} />
                <Typography type={'text-16'} transform={'capitalize'} color={'purple'}>
                    {record.type_id.toLowerCase()}
                </Typography>
            </div>
        )
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        defaultSortOrder: sort === 'amount'
            ? 'ascend'
            : sort === '-amount'
                ?  'descend'
                : false,
        render: (text: string, record: TTransactionItem) => (
            <div className={`portfolio-transactions__table__item__amount`}>
                <Typography type={'text-16'} color={record.type_id === 'OUT' ? 'error' : 'success'}>
                    {record.type_id === 'OUT' ? '- ' : '+ '}
                    {record.amount}
                    <span style={{marginLeft: 5}}>{record.name}</span>
                </Typography>
            </div>
        )
    },
    {
        title: 'Value, USD',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
        defaultSortOrder: sort === 'value'
            ? 'ascend'
            : sort === '-value'
                ?  'descend'
                : false,
        render: (text: string, record: TTransactionItem) => (
            <Typography type={'text-16'} color={'primary'}>
                <span style={{marginRight: 5}}>{symbol}</span>
                {record.value}
            </Typography>
        )
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text: string, record: TTransactionItem) => {
            return (
                <Typography type={'text-16'} transform={'capitalize'} className={`status ${record.status.toLowerCase()}`}>
                    {record.status.toLowerCase()}
                </Typography>
            )
        }
    },
])

const emptyColomns = [
    {
        title: 'Date/Time',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: true,
        render: () => <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
    },
    {
        title: 'Assets Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: () => <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
    },
    {
        title: 'Transaction type',
        dataIndex: 'type_id',
        key: 'type_id',
        render: () => <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
        render: () => <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
    },
    {
        title: 'Value, USD',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
        render: () => <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: () => <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
    }
]

// props

type PropsType = {
    children?: never,
    // transactions table props
    loadTransactions: boolean,
    transactions: TTransactionData | null,
    transactionsFilter: TTransactionQueryFilter,
    transactionsBaseQuery: TTransactionQueryBase,
    transactionsCountFilter: number,
    emptyTransactions: TTransactionItem[],
    enableSearch?: boolean,
    enableCurrencyFilter?: boolean,
    handlerTableChange: (
        pagination: TablePaginationConfig,
        filters: Record<string, Key[] | null>,
        sorter: SorterResult<any> | SorterResult<any>[]
    ) => any,
    handlerFilter: () => void
    // filter props
    mode: boolean,
    handlerCloseFilter: () => void,
	handlerSubmitFilter: (val: Omit<TTransactionQueryFilter, 'name'>) => void,
    searchTransactions: (search: string | null) => void,
    handlerSetFilterCount: (count: number) => void,
    handlerChangeFilterCount: (type: 'inc' | 'dec') => void
}

const TransactionTable: FC<PropsType> = memo(({
      loadTransactions = false,
      transactions,
      emptyTransactions,
      transactionsFilter,
      transactionsBaseQuery,
      transactionsCountFilter,
      enableSearch = true,
      enableCurrencyFilter = true,
      handlerTableChange,
      handlerFilter,
      mode,
      handlerCloseFilter,
      handlerSubmitFilter,
      searchTransactions,
      handlerSetFilterCount,
      handlerChangeFilterCount
  }) => {
    const {
        search, handlerSearch
    } = useTransactionsTableEf({searchTransactions, transactionsFilter})


    return (<>
        <div className={'portfolio-transactions'}>
            <div className="transactions-table__header">
                <div className={'portfolio-transactions__header_subheader'}>
                    <Typography type={'title-18'} color={'primary'}>
                        Transactions
                    </Typography>
                    <div className="portfolio-transactions__header__action">

                        <Button
                        	type={'default'}
                        	onClick={handlerFilter}
                        	disabled={loadTransactions || !transactions?.items.length}
                        >
                        	<FilterOutlined />
                        	Filters
                        	{ transactionsCountFilter > 0 ? ` (${transactionsCountFilter})` : ''}
                        </Button>

                        {
                            enableSearch &&
                            <Input
                                placeholder="Search"
                                prefix={
                                    loadTransactions
                                        ? <Spin indicator={<LoadingOutlined className={'profile__addresses__header__action_load'} style={{fontSize: 18}} spin />} />
                                    : <SearchOutlined />
                                }
                                style={{width: 220}}
                                className={'input-item portfolio-add-wallet-modal__input'}
                                size={'middle'}
                                onChange={handlerSearch}
                                value={search ? search : ''}
                                disabled={loadTransactions}
                                allowClear={true}
                            />
                        }
                    </div>
                </div>
                <TransactionFilterTag
                    transactionsFilter={transactionsFilter}
                    transactionsCountFilter={transactionsCountFilter}
                    handlerChangeFilterCount={handlerChangeFilterCount}
                    handlerSubmitFilter={handlerSubmitFilter}
                />
            </div>
            {
                !loadTransactions ?
                    transactions ?
                        <Table
                            className={'base-table portfolio-transactions__table base-table__transactions-table'}
                            onChange={handlerTableChange}
                            loading={loadTransactions}
                            scroll={{ y: 580}}
                            expandable={{expandedRowRender: (data) => <TransactionInfo data={data}/>}}
                            //@ts-ignore
                            columns={columns({
                                symbol: transactions ? transactions.base_fiat_sign : '$',
                                sort: transactionsBaseQuery.sort ? transactionsBaseQuery.sort : null
                            })}
                            pagination={{
                                total: transactions ? transactions.pagination.totalCount : 0,
                                current: transactionsBaseQuery.page ? transactionsBaseQuery.page : 1,
                                pageSizeOptions: ['10', '20', '30', '40', '50'],
                                pageSize: transactionsBaseQuery.pageSize ? +transactionsBaseQuery.pageSize : 10,
                            }}
                            dataSource={transactions ? transactions.items.map(elem => ({...elem, key: elem.id})) :[]}
                        />
                    :
                        <NodeError
                            errorImage={<EmptyStatus/>}
                            className={'home-card__error'}
                            description={'No transactions.'}
                        />
                :
                    <Table
                        className={'base-table portfolio-transactions__table'}
                        scroll={{ y: 580}}
                        //@ts-ignore
                        columns={emptyColomns}
                        rowKey="id"
                        dataSource={emptyTransactions}
                    />
            }

        </div>
        <TransactionFilter
            mode={mode}
            filter={transactionsFilter}
            enableCurrencyFilter={enableCurrencyFilter}
            loadTransactions={loadTransactions}
            handlerCloseFilter={handlerCloseFilter}
            handlerSubmitFilter={handlerSubmitFilter}
            handlerSetFilterCount={handlerSetFilterCount}
        />
    </>)
})

export default TransactionTable
