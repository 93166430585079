// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Actions
import {cabinetMainActions} from "actions/cabinet";
// Selectors
import {homeSelector} from "selectors/cabinet";
// Types
import {TTransactionsTable} from "types/cabinet/transactions";
// Utils
import {range} from "utils";


export const useResentTransactionsEf = () => {
    const dispatch = useDispatch()
    const loadTransactions = useSelector(homeSelector.home_load_chart)
    const transactions = useSelector(homeSelector.transactions)

    const [showTransactionsDetails, setShowTransactionsDetails] = useState<boolean>(false)
    const [transaction, setTransaction] = useState<TTransactionsTable[] | null>()

    // get mock data for skeleton
    const getMockTransactionsData = (id: string):TTransactionsTable  => ({
        id,
        type_id: '',
        amount: 0,
        name: '',
        status: '',
        created_at: ''
    })

    const emptyResentTransactions = range(0,4).map(elem => getMockTransactionsData(`${elem}`))


    // open receive assets modal
    const handleReceiveAssets = () => {
        dispatch(cabinetMainActions.set_receive_asset_mode(true))
    }

    const handlerAllTransactions = () => {
        dispatch(cabinetMainActions.set_menu_mode('transactions'));
    }

    const handlerTransactionsDetails = () => {
        setShowTransactionsDetails(!showTransactionsDetails)
    }

    const handlerShowTransactions = (transaction: TTransactionsTable) => {
        setShowTransactionsDetails(true)
        setTransaction([transaction])
    }

    return {
        loadTransactions, transactions, emptyResentTransactions, showTransactionsDetails, transaction,
        handleReceiveAssets, handlerAllTransactions, handlerTransactionsDetails, handlerShowTransactions
    }
}
