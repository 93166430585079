// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Input, Modal} from 'antd'
// Ant Icon
import {SearchOutlined} from '@ant-design/icons'
// Style
import './WalletAddressQr.scss'
// Hook
import {useWalletAddressQrEf} from './useWalletAddressQrEf'
// Element
import {ComingSoon} from 'elements'


type PropsType = {
	children?: never
}

const WalletAddressQr: FC<PropsType> = memo(() => {
	const {
		walletAddressQrMode,
		handlerClose,
	} = useWalletAddressQrEf()

	return (
		<Modal
			className={'portfolio-add-wallet-modal'}
			title={'Wallet address'}
			centered
			visible={walletAddressQrMode}
			onCancel={handlerClose}
			width={450}
			footer={false}
		>
			<ComingSoon/>
		</Modal>
	)
})

export default WalletAddressQr
