// Core
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
// Actions
import {cabinetMainActions, homeActions, settingsCabinetAction} from "actions/cabinet";
// Types
import {EWalletDuration} from "types/cabinet/cabinet-main-type";
import {cabinetMainSelect, homeSelector, settingsCabinetSelect} from "selectors/cabinet";


export const useHomeEf = () => {
    const dispatch = useDispatch()

    // Selectors

    const tabName = useSelector(cabinetMainSelect.menu_mode)
    const currencyListCrypto = useSelector(cabinetMainSelect.currency_list_crypto)
    const currentCryptoCurrencyId = useSelector(homeSelector.currency_crypto_id)
    const overview = useSelector(homeSelector.home_data_overview)
    const duration = useSelector(homeSelector.current_duration)
    const currencyItems = useSelector(cabinetMainSelect.currency_items)
    const favoritesLocal = useSelector(cabinetMainSelect.favorites_local)
    const changedFavorites = useSelector(homeSelector.home_changed_favorites)
    const updatedSettings = useSelector(settingsCabinetSelect.updated_settings)



    // get base data for home page
    useEffect(() => {

        tabName === 'home' &&
            !overview && dispatch(homeActions.watch_home_base({duration: EWalletDuration.HOUR}))
            !duration && dispatch(homeActions.home_set_duration(EWalletDuration.HOUR))
            updatedSettings && dispatch(homeActions.watch_home_base({duration: EWalletDuration.HOUR}))
            changedFavorites && dispatch(homeActions.watch_home_base({duration: duration || EWalletDuration.HOUR})) && dispatch(homeActions.home_set_changed_favorites(false))
    },[dispatch, tabName])


    // get crypto currency list
    useEffect(() => {
        if (favoritesLocal) {
            !currencyItems && dispatch(cabinetMainActions.watch_favorites_list(true, favoritesLocal))
            updatedSettings && dispatch(cabinetMainActions.watch_favorites_list(false))
        } else {
            !currencyItems && dispatch(cabinetMainActions.watch_favorites_list(false))
            updatedSettings && dispatch(cabinetMainActions.watch_favorites_list(false))
        }

    }, [dispatch])

    // set current crypto currency
    useEffect(() => {
        currencyListCrypto && currentCryptoCurrencyId && dispatch(homeActions.home_set_crypto_currency(currentCryptoCurrencyId.toString()))
    },[currencyListCrypto, currentCryptoCurrencyId])


    return {}
}
