// Core
import { SagaIterator}  from 'redux-saga'
import { all, takeLeading, takeEvery, fork } from 'redux-saga/effects'
// Actions
import {
    CABINET__WATCH__CURRENCY_LIST,
    CABINET__WATCH__FAVORITES,
    CABINET__WATCH__ADD_FAVORITES,
    CABINET__WATCH__REMOVE_FAVORITES,
    CABINET__WATCH__ADDRESSES,
    CABINET__WATCH__SEND_ASSETS,
    CABINET__WATCH__SAVE_ADDRESS,
    CABINET__WATCH__IMPORT_ADDRESS,
    CABINET__WATCH__EXPORT_ADDRESS,
    CABINET__WATCH__NOTIFICATIONS,
    CABINET__WATCH__READ_NOTIFICATIONS
} from 'actions/cabinet/cab-main-action'
// Worker
import {
    workerCurrencyList,
    workerFavorites,
    workerAddFavorites,
    workerRemoveFavorites,
    workerWalletAddresses,
    workerSendAssets,
    workerSaveAddress,
    workerImportAddress, workerExportAddress, workerNotifications, workerReadNotifications
} from './worker-saga'


function* watchCurrencyList(): SagaIterator {
    yield takeEvery(CABINET__WATCH__CURRENCY_LIST, workerCurrencyList)
}

function* watchFavorites(): SagaIterator {
    yield takeEvery(CABINET__WATCH__FAVORITES, workerFavorites)
}

function* watchAddFavorites(): SagaIterator {
    yield takeEvery(CABINET__WATCH__ADD_FAVORITES, workerAddFavorites)
}

function* watchRemoveFavorites(): SagaIterator {
    yield takeEvery(CABINET__WATCH__REMOVE_FAVORITES, workerRemoveFavorites)
}

function* watchWalletAddresses(): SagaIterator {
    yield takeEvery(CABINET__WATCH__ADDRESSES, workerWalletAddresses)
}

function* watchSendAssets(): SagaIterator {
    yield takeEvery(CABINET__WATCH__SEND_ASSETS, workerSendAssets)
}

function* watchSaveAddress(): SagaIterator {
    yield takeEvery(CABINET__WATCH__SAVE_ADDRESS, workerSaveAddress)
}

function* watchImportAddress(): SagaIterator {
    yield takeEvery(CABINET__WATCH__IMPORT_ADDRESS, workerImportAddress)
}

function* watchExportAddress(): SagaIterator {
    yield takeEvery(CABINET__WATCH__EXPORT_ADDRESS, workerExportAddress)
}

function* watchNotifications(): SagaIterator {
    yield takeEvery(CABINET__WATCH__NOTIFICATIONS, workerNotifications)
}

function* watchNotificationsRead(): SagaIterator {
    yield takeEvery(CABINET__WATCH__READ_NOTIFICATIONS, workerReadNotifications)
}

export default function* cabinetMainSagaWatcher(): SagaIterator {
    yield all([
        fork(watchCurrencyList),
        fork(watchFavorites),
        fork(watchAddFavorites),
        fork(watchRemoveFavorites),
        fork(watchWalletAddresses),
        fork(watchSendAssets),
        fork(watchSaveAddress),
        fork(watchImportAddress),
        fork(watchExportAddress),
        fork(watchNotifications),
        fork(watchNotificationsRead)
    ])
}
