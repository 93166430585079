// Core
import {select} from "redux-saga/effects"
// Selector
import {portfolioSelect} from 'selectors/cabinet'
// Api
import {portfolioApi, transactionApi} from "api/cabinet"
// Action
import {cabinetMainActions, portfolioActions} from 'actions/cabinet'
import {appActions} from 'actions'
// Template
import {makeRequest} from 'sagas/template'
// Type
import {AlertType} from "types/app-type"
import {WorkerProps} from "sagas/root-saga"
import {EWalletDuration} from "types/cabinet/cabinet-main-type"
import {TCurrency, TCurrencyData, TGenerateReport, TLoadFirst, TWallet, TWalletItem} from "types/cabinet/portfoli-type"
import {TTransactionData, TTransactionQueryBase, TTransactionQueryFilter} from 'types/cabinet/transactions'


export function* workerBalance({payload}: WorkerProps<EWalletDuration>): Generator {
    const loadFirst = (yield select(portfolioSelect.load_first)) as TLoadFirst[]

    const prepareFinish = [
        {action: portfolioActions.load_balance_chart, handler: () => false},
        {action: portfolioActions.load_first, handler: () => 'balance'},
    ]

    const prepareSuccess = [
        {action: portfolioActions.set_balance_chart},
        {action: portfolioActions.set_balance_duration, handler: () => payload},
    ]

     yield makeRequest({
         // @ts-ignore
         fetcher: portfolioApi.balance,
         fetcherParam: [
             payload,
         ],
         start: [
             {action: portfolioActions.load_balance_chart, handler: () => true},
         ],
         finish: !loadFirst.includes('balance') ? prepareFinish : [prepareFinish[0]],
         success: loadFirst.includes('cart-details') ? prepareSuccess : [prepareSuccess[0]],
     })
}

export function* workerWallet(): Generator {
    const loadFirst = (yield select(portfolioSelect.load_first)) as TLoadFirst[]

    const prepareFinish = [
        {action: portfolioActions.load_wallet, handler: () => false},
        {action: portfolioActions.load_first, handler: () => 'wallets'},
    ]

    yield makeRequest({
        // @ts-ignore
        fetcher: portfolioApi.wallet,
        start: [
            {action:portfolioActions.load_wallet, handler: () => true}
        ],
        finish: !loadFirst.includes('wallets') ? prepareFinish : [prepareFinish[0]],
        success: [
            {action:portfolioActions.set_wallets, handler: (data:TWallet) => data}
        ],
    })
}

export function* workerCurrency({payload}: WorkerProps<{data: TWalletItem, duration: EWalletDuration}>): Generator {
    const loadFirstCurrency = (yield select(portfolioSelect.load_first_currency)) as string[]
    const currencies = (yield select(portfolioSelect.currencies)) as TCurrency[]
    const hadCurrency = currencies.find(elem => elem.id === payload.data.id)

    const prepareSuccessFirst = [
        {action: portfolioActions.add_currency, handler: (data: TCurrencyData) => ({id: payload.data.id, data: data})},
        {action: portfolioActions.add_currency_duration, handler: () => ({id: payload.data.id, duration: payload.duration})},
    ]

    const prepareSuccess = [
        {action: portfolioActions.update_currency, handler: (data: TCurrencyData) => ({id: payload.data.id, data})},
        {action: portfolioActions.update_currency_duration, handler: () => ({id: payload.data.id, duration: payload.duration})},
    ]

    const prepareFinish = [
        {action: portfolioActions.load_currency, handler: () => payload.data.id},
        {action: portfolioActions.load_first_currency, handler: () => payload.data.id},
    ]

    yield makeRequest({
        // @ts-ignore
        fetcher: portfolioApi.currency,
        fetcherParam: [
            payload.data.cur_id, payload.duration
        ],
        start: [
            {action: portfolioActions.load_currency, handler: () => payload.data.id},
        ],
        finish:  loadFirstCurrency.includes(payload.data.id) ? [prepareFinish[0]] : prepareFinish,
        success: hadCurrency ? prepareSuccess : prepareSuccessFirst,
    })
}

export function* workerCurrencyDetail({payload}: WorkerProps<{ id: string, duration: EWalletDuration}>): Generator {
    const loadFirstCurrency = (yield select(portfolioSelect.load_first_currency)) as TLoadFirst[]

    const finishPrepare = [
        {action: portfolioActions.load_currency_detail, handler: () => false},
        {action: portfolioActions.load_first, handler: () => 'currency-detail'},
    ]

    yield makeRequest({
        // @ts-ignore
        fetcher: portfolioApi.currency_detail,
        fetcherParam: [
            payload.id,
        ],
        start: [
            {action: portfolioActions.load_currency_detail, handler: () => true},
        ],
        finish: !loadFirstCurrency.includes('currency-detail') ? finishPrepare : [finishPrepare[0]],
        success: [
            {action: portfolioActions.set_currency_detail_data},
        ],
    })
}

export function* workerCurrencyDetailChart({payload}: WorkerProps<{ id: string, duration: EWalletDuration}>): Generator {
    const loadFirst = (yield select(portfolioSelect.load_first)) as TLoadFirst[]

    const prepareFinish = [
        {action: portfolioActions.load_currency_detail_chart, handler: () => false},
        {action: portfolioActions.load_first, handler: () => 'cart-details'},
    ]

    const prepareSuccess = [
        {action: portfolioActions.set_currency_detail_chart},
        {action: portfolioActions.set_currency_details_chart_duration, handler: () => payload.duration},
    ]

    yield makeRequest({
        // @ts-ignore
        fetcher: portfolioApi.currency,
        fetcherParam: [
            payload.id, payload.duration
        ],
        start: [
            {action: portfolioActions.load_currency_detail_chart, handler: () => true},
        ],
        finish: !loadFirst.includes('cart-details') ? prepareFinish : [prepareFinish[0]],
        success: loadFirst.includes('cart-details') ? prepareSuccess : [prepareSuccess[0]],
    })
}

export function* workerCurrencyTransactions({payload}: WorkerProps<{id: string, query: TTransactionQueryBase, filter: TTransactionQueryFilter}>): Generator {
    const loadFirst = (yield select(portfolioSelect.load_first)) as TLoadFirst[]

    const prepareFinish = [
        {action: portfolioActions.load_transactions, handler: () => false},
        {action: portfolioActions.load_first, handler: () => 'transactions'},
    ]

    yield makeRequest({
        // @ts-ignore
        fetcher: transactionApi.list,
        fetcherParam: [
            payload.query,
            {
                ...payload.filter,
                duration: payload.filter.duration && ['all', 'custom'].includes(payload.filter.duration) ? null :  payload.filter.duration,
            },
            payload.id
        ],
        start: [
            {action: portfolioActions.load_transactions, handler: () => true},
        ],
        finish: !loadFirst.includes('transactions') ? prepareFinish : [prepareFinish[0]],
        success: [
            {action: portfolioActions.set_transactions, handler: (data: TTransactionData) => ({...data})},
        ],
    })
}

export function* workerCreateWallet({payload}: WorkerProps<{id: string}>):Generator {

    yield makeRequest({
         // @ts-ignore
         fetcher: portfolioApi.wallet_create,
         fetcherParam: [
            payload.id
         ],
        start: [
            {action: portfolioActions.load_create_wallet, handler: () => true},
        ],
        finish: [
            {action: portfolioActions.load_create_wallet, handler: () => false}
        ],
        success: [
            {action: cabinetMainActions.remove_from_currency_list_crypto, handler: () => payload.id},
            {action: portfolioActions.watch_wallet, handler: () => {}},
            {
                action: appActions.set_alert,
                handler: () => ({
                    type: "success",
                    header: 'Success',
                    text: 'Your wallet has been successfully created.',
                } as AlertType)
            }
        ],
        validation: [
            {
                action: appActions.set_alert,
                handler: (data) => (
                    {
                    type: "error",
                    header: 'Errror',
                    text: data.message,
                } as AlertType)
            }
        ],
        with_message: true
    })
}

export function* workerCreateWalletAddress({payload}: WorkerProps<{id: string}>):Generator {

    yield makeRequest({
        // @ts-ignore
        fetcher: portfolioApi.wallet_create_address,
        fetcherParam: [
            payload.id
        ],
        start: [
            {action: portfolioActions.load_create_wallet_address, handler: () => true},
        ],
        finish: [
            {action: portfolioActions.load_create_wallet_address, handler: () => false}
        ],
        success: [
            {action: portfolioActions.add_wallet_address, handler: (data) => data},
        ],
        validation: [
            {
                action: appActions.set_alert,
                handler: (data) => (
                    {
                        type: "error",
                        header: 'Errror',
                        text: data.message,
                    } as AlertType)
            }
        ],
        with_message: true
    })
}

export function* workerGenerateReport({payload}: WorkerProps<{data: TGenerateReport}>):Generator {

    yield makeRequest({
        // @ts-ignore
        fetcher: portfolioApi.generate_report,
        fetcherParam: [
            payload.data
        ],
        finish: [
            {action: portfolioActions.set_generate_report_mode, handler: () => false},
        ]
    })
}

