// Core
import React, {FC, memo} from 'react'
// Ant Components
import {} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
import {LoadingOutlined} from '@ant-design/icons'
// Hook
import {useExportViaGoogleEf} from './useExportViaGoogleEf'
import {filters} from "../../../../../../utils";
import * as Yup from "yup";
import {Form, Input} from "formik-antd";
import {Button} from "../../../../../../elements";
import {Formik} from "formik";
import {TExportViaMethod} from "../../../../../../types/cabinet/cabinet-main-type";
import {FormikHandler} from "../../../../../../components";
// QR code component
const QRCode = require('qrcode.react');


type PropsType = {
    children?: never,
    address: string,
    handlerSubmit: (code: TExportViaMethod) => void,
    loading: boolean,
    formErrors: any
}

const CodeScheme = Yup.object().shape({
    code: Yup.string()
        .required('Required')
})

const ExportViaGoogle: FC<PropsType> = memo(({address, handlerSubmit, loading, formErrors}) => {
    const {} = useExportViaGoogleEf()

    const init = {
        code: ''
    }

    return <>
        <p className={'export-address__text'}>To confirm the export address <span className={'export-address__text-address'}>{filters.hideMiddleString(address)}</span><br/> open Google Authenticator and enter code below.</p>
        {/*<div className={'twofa__qr'}>*/}
        {/*    <div className={'twofa__qr-box'}>*/}
        {/*        <QRCode value={'otpauth://totp/CatchCoin:'} size={140} fgColor={'#332E54'}/>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<p className={'export-address__text'}>If you have any problem with scanning the QR code enter code generated by the App.</p>*/}
        <Formik onSubmit={handlerSubmit} initialValues={init} validationSchema={CodeScheme} autoComplete={'off'}>
            {({errors, values, touched,setFieldValue}) => (
                <Form>
                    <Form.Item
                        name={'code'}
                        label={'Code'}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        hasFeedback={false}
                        style={{marginBottom: 40}}
                    >
                        <Input
                            // onFocus={handlerFocus}
                            // onBlur={handlerBlur}
                            className={'input-item medium'}
                            size={'middle'}
                            name='code'
                            placeholder={'Enter Code'}
                        />
                    </Form.Item>
                    <Button
                        className={'send-assets__submit'}
                        type="submit"
                        size={"full"}
                        color={"secondary"}
                        variant={"contained"}
                        loaderPosition={"center"}
                        // disabled={canSubmit}
                        loader={
                            loading
                                ? () => <LoadingOutlined className={'sign__loader-icon'} spin/>
                                : null
                        }
                    >
                        Confirm
                    </Button>
                    <FormikHandler data={formErrors}/>
                </Form>
            )}
        </Formik>
    </>
})

export default ExportViaGoogle
