// Core
import React, {FC, memo} from 'react'
// Ant Components
import {} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'


type PropsType = {
    children?: never
}

const Outcome: FC<PropsType> = memo(() => {
    return <>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill="#FFF1F0"/>
            <path d="M19.0786 19.7895L19.5951 12.1923C19.5998 12.1236 19.5897 12.0546 19.5654 11.99C19.5412 11.9253 19.5033 11.8666 19.4545 11.8177C19.4056 11.7689 19.3469 11.731 19.2823 11.7068C19.2176 11.6825 19.1486 11.6724 19.0799 11.6771L11.4838 12.1947C11.4608 12.1962 11.4389 12.2043 11.4205 12.218C11.4021 12.2317 11.3881 12.2504 11.3801 12.2719C11.3721 12.2934 11.3704 12.3168 11.3753 12.3393C11.3802 12.3617 11.3915 12.3824 11.4078 12.3987L12.2718 13.2627C12.3208 13.3118 12.389 13.3375 12.4592 13.3334L17.4701 12.9925L11.4978 18.9648C11.4511 19.0115 11.4513 19.0881 11.4982 19.135L12.1383 19.775C12.1852 19.822 12.2618 19.8222 12.3084 19.7755L18.2808 13.8032L17.9398 18.8141C17.9347 18.8832 17.9604 18.9514 18.0106 19.0015L18.8746 19.8655C18.9471 19.9381 19.0714 19.8927 19.0786 19.7895Z" fill="#D23E50"/>
        </svg>
    </>
})

export default Outcome
