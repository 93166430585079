// Core
import {useEffect, useState} from "react"
import {useSelector, useDispatch} from 'react-redux'
// select
import {profileSelect, cabinetMainSelect} from 'selectors/cabinet'
// Action
import {profileActions, cabinetMainActions} from 'actions/cabinet'
// Types
import {IAddressTableItemBase} from "types/cabinet/profile"
import {IFavoritesItem} from "../../../../types/cabinet/cabinet-main-type";


export const useSavedAddressesModalFormEf = (id: string | null, address_to: string, mode: boolean) => {
    const dispatch = useDispatch()


    // State

    const init = {
        currency_id:  id,
        wallet_address: address_to,
        name: ''
    } as IAddressTableItemBase

    const [currencyCrypto, setCurrencyCrypto] = useState<IFavoritesItem[] | null>(null)


    // Selectors

    const currencyListCrypto = useSelector(cabinetMainSelect.currency_list_crypto)
    const loadAddressTableModalForm = useSelector(profileSelect.load_address_table_modal_form)
    const errorAddressTableModalForm = useSelector(profileSelect.error_address_table_modal_form)


    // Effects

    useEffect(() => {

        const crypto = currencyListCrypto?.find(item => item.id === id)
        crypto && setCurrencyCrypto([crypto])
    }, [currencyListCrypto, id])


   // Handlers

    const handlerAdd = (data: IAddressTableItemBase) => {
        dispatch(cabinetMainActions.watch_save_address(data))
    }


    return {
        init, currencyCrypto, loadAddressTableModalForm, errorAddressTableModalForm,
        handlerAdd,
    }
}
