// Core
import React, {FC, memo} from 'react'
// Ant Components
import {} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
import {NodeError as Error} from "icons";
import {NodeError} from "#/elements";
// Styles
import './CabinetBung.scss'

type PropsType = {
    children?: never
}

const CabinetBung: FC<PropsType> = memo(() => {

    return <div className={'cabinet-bung'}>
        <NodeError
            errorImage={<Error/>}
            className={'home-card__error'}
            description={'Please download mobile app for successful work on this screen.'}
        />
    </div>
})

export default CabinetBung
