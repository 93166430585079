// Core
import { Logo } from 'icons'
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// selector
import {cabinetMainSelect} from 'selectors/cabinet'
// Type
import {
    TTransactionQueryFilter
} from 'types/cabinet/transactions'
import { date } from 'yup'

type TTransactionsFilterTagEf = {
	transactionsFilter: TTransactionQueryFilter | null,
	handlerSubmitFilter: (val: Omit<TTransactionQueryFilter, 'name'>) => void,
	handlerChangeFilterCount: (type: 'inc' | 'dec') => void,
}

export const useTransactionFilterTagEf = ({transactionsFilter, handlerSubmitFilter, handlerChangeFilterCount}: TTransactionsFilterTagEf) => {
	const dispatch = useDispatch()
	const currencyList = useSelector(cabinetMainSelect.currency_list_crypto)

	// handlers

	const handlerUpdeteFilter = (tagType: string, tagId: string) => {
		const data = {...transactionsFilter}
		
		tagType.toLowerCase() === 'currency' 
			&& (data.currency = data.currency?.filter(item => item !== tagId))

		tagType.toLowerCase() === 'duration' 
			&& (
				data.duration?.toLowerCase() === 'custom' 
					? ([data.duration, data.dateFrom, data.dateTo] = [null, null, null]) 
					: data.duration = null
				)

		tagType.toLowerCase() === 'date' 
			&& ([data.duration, data.dateFrom, data.dateTo] = [null, null, null])
			
		tagType.toLowerCase() === 'status' 
			&& (data.status = null)

		tagType.toLowerCase() === 'type' 
			&& (data.type = null)

		handlerChangeFilterCount('dec')

		handlerSubmitFilter(data)
	}

	return {
		currencyList, handlerUpdeteFilter
	}
}
