// Core
import {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Select
import {portfolioSelect, cabinetMainSelect} from 'selectors/cabinet'
// Action
import {portfolioActions} from 'actions/cabinet'
// Types
import {IFavoritesItem, IWalletItem} from 'types/cabinet/cabinet-main-type'
import Item from 'antd/lib/list/Item'


export const useAddWalletModalEf = () => {
    const dispatch = useDispatch()

    // selectors

    const addWalletMode = useSelector(portfolioSelect.add_wallet__mode)
    const addSearchWallet = useSelector(portfolioSelect.add_search_wallet)
    const currencyList = useSelector(cabinetMainSelect.currency_list_crypto)
    const walletsPrepare = useSelector(portfolioSelect.wallets)
    const loadWalletCreate = useSelector(portfolioSelect.load_wallet_create)

    //data

    const [freeCryptoList , setFreeCryproList] = useState<IWalletItem[] | null>(null);


    // effects

    useEffect(() => {
        const cryptoList:IWalletItem[] = []

        currencyList?.forEach((item) => {

            const currencyIndex = walletsPrepare && walletsPrepare.items.findIndex(wallet => wallet.cur_id === item.id)

            cryptoList.push({...item, disabled: currencyIndex === -1 ? false : true})
        })

        cryptoList && setFreeCryproList(cryptoList)

    },[walletsPrepare, currencyList])


    // handlers

    const handlerClose = () => {
        dispatch(portfolioActions.set_add_wallet__mode(false))
    }

    const handlerSearchWallet = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(portfolioActions.set_add_search_wallet(e.target.value))
    }

    const handlerAddWallet = (wallet: IFavoritesItem | null) => {
        wallet && dispatch(portfolioActions.watch_create_wallet(wallet?.id))
    }

    return {
        addWalletMode, addSearchWallet, currencyList, freeCryptoList, loadWalletCreate, walletsPrepare,
        handlerClose, handlerSearchWallet, handlerAddWallet
    }
}
