// Type
import { RootState } from 'store'


const portfolioSelect = {
    tab: (store: RootState) => store.portfolio.tab,
    generate_report_mode: (store: RootState) => store.portfolio.generate_report_mode,
    add_wallet__mode: (store: RootState) => store.portfolio.add_wallet__mode,
    wallet_address_qr_mode: (store: RootState) => store.portfolio.wallet_address_qr_mode,
    balance_duration: (store: RootState) => store.portfolio.balance_duration,
    currency_duration: (store: RootState) => store.portfolio.currency_duration,
    chart: (store: RootState) => store.portfolio.chart,
    balance: (store: RootState) => store.portfolio.balance,
    empty_balance_mode: (store: RootState) => store.portfolio.empty_balance_mode,
    search_wallet: (store: RootState) => store.portfolio.search_wallet,
    add_search_wallet: (store: RootState) => store.portfolio.add_search_wallet,
    wallets: (store: RootState) => store.portfolio.wallets,
    // wallets: (store: RootState) => ({
    //     base_fiat_name: 'USD',
    //     base_fiat_sign: '$',
    //     items: [{
    //         id: "92",
    //         cur_id: "3",
    //         name: "ETH",
    //         icon: "/currency/currency-image/3?size=72x",
    //         full_name: "Ethereum",
    //         address: "0x0db5f97fb6e66fc24e77e78145c9818ed09eed5d",
    //         balance: "0.0032",
    //         value: "3454.43",
    //         price: 35020.25,
    //         percent_change: 0,
    //         in_favorites: 0,
    //         percent: "34"
    //     }]
    // }),
    currencies: (store: RootState) => store.portfolio.currencies,
    currency_details: (store: RootState) => store.portfolio.currency_details,
    currency_details_data: (store: RootState) => store.portfolio.currency_details_data,
    currency_details_chart_duration: (store: RootState) => store.portfolio.currency_details_chart_duration,
    currency_details_chart: (store: RootState) => store.portfolio.currency_details_chart,
    transactions: (store: RootState) => store.portfolio.transactions,
    transactions_filter_mode: (store: RootState) => store.portfolio.transactions_filter_mode,
    transactions_base_query: (store: RootState) => store.portfolio.transactions_base_query,
    transactions_filter: (store: RootState) => store.portfolio.transactions_filter,
    transactions_count_filter: (store: RootState) => store.portfolio.transactions_count_filter,
    // Error

    // Load
    load_first: (store: RootState) => store.portfolio.load_first,
    load_first_currency: (store: RootState) => store.portfolio.load_first_currency,
    load_balance_chart: (store: RootState) => store.portfolio.load_balance_chart,
    load_wallet: (store: RootState) => store.portfolio.load_wallet,
    load_currency: (store: RootState) => store.portfolio.load_currency,
    load_currency_detail: (store: RootState) => store.portfolio.load_currency_detail,
    load_currency_detail_chart: (store: RootState) => store.portfolio.load_currency_detail_chart,
    load_transactions: (store: RootState) => store.portfolio.load_transactions,
    load_wallet_create: (store: RootState) => store.portfolio.load_wallet_create,
    load_add_address: (store: RootState) => store.portfolio.load_add_address,
}

export default portfolioSelect
