// Core
import React, {FC, memo} from 'react'
// Ant Components
import {} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
// Style
import './SkeletonChartSmall.scss'


type PropsType = {
        children?: never
        className?: string
}

const SkeletonChartSmall: FC<PropsType> = memo(({className}) => (
    <svg className={`skeleton-chart-small ${className}`} width="763" height="254" viewBox="0 0 763 254" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M60 106.29L111.142 80.8754L159.872 106.29L222.594 44L275.184 192L332.598 160.108L384.705 97.8182L436.812 80.8754L493.744 115.758H553.089L594.581 137.185L654.891 80.8754L705.068 106.29L762 80.8754V228H60V106.29Z" fill="#F2F2F2"/>
            <path d="M60 106.29L111.142 80.8754L159.872 106.29L222.594 44L275.184 192L332.598 160.108L384.705 97.8182L436.812 80.8754L493.744 115.758H553.089L594.581 137.185L654.891 80.8754L705.068 106.29L762 80.8754" stroke="#BFBFBF"/>
            <path d="M177 232V228" stroke="#BFBFBF"/>
            <path d="M60 232V228" stroke="#BFBFBF"/>
            <path d="M60 228H56" stroke="#BFBFBF"/>
            <path d="M60 184H56" stroke="#BFBFBF"/>
            <path d="M60 140H56" stroke="#BFBFBF"/>
            <path d="M60 96H56" stroke="#BFBFBF"/>
            <path d="M60 52H56" stroke="#BFBFBF"/>
            <path d="M60.5 8H56" stroke="#BFBFBF"/>
            <path d="M411 232V228" stroke="#BFBFBF"/>
            <path d="M528 232V228" stroke="#BFBFBF"/>
            <path d="M294 232V228" stroke="#BFBFBF"/>
            <path d="M645 232V228" stroke="#BFBFBF"/>
            <rect width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect y="44" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect y="88" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect y="131" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect y="176" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect y="220" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect x="60" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect x="155" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect x="272" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect x="389" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect x="506" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect x="623" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
            <rect x="718" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
    </svg>
))

export default SkeletonChartSmall
