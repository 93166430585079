// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Actions
import {transactionsActions, cabinetMainActions} from "actions/cabinet";
// Selector
import {transactionsSelect, cabinetMainSelect} from "selectors/cabinet";
// Type
import {Key, SorterResult, TablePaginationConfig} from 'antd/lib/table/interface'
import {TTransactionItem, TTransactionTableSort, TTransactionQueryFilter} from 'types/cabinet/transactions'
import {TGetSortParams} from 'types/app-type'
// Utils
import {range} from "utils";


export const useTransactionsEf = () => {
    const dispatch = useDispatch()
    // transactions table data
    const transactions = useSelector(transactionsSelect.transactions)
    const loadTransactions = useSelector(transactionsSelect.transactions_load)
    const currencyList = useSelector(cabinetMainSelect.currency_list_crypto)
    const mode = useSelector(transactionsSelect.transactions_filter_mode)
    // filter data
    const transactionsFilter = useSelector(transactionsSelect.transactions_filter)
    const transactionsBaseQuery = useSelector(transactionsSelect.transactions_base_query)
    const transactionsCountFilter = useSelector(transactionsSelect.transactions_count_filter)

    // get mock data for skeleton
    const getMockTransactionsData = (id: string):TTransactionItem  => ({
      id,
      type_id: 'IN',
      sub_type_id: 'sell',
      status: 'WAITING',
      name: '',
      full_name: '',
      icon: '',
      amount: 0,
      value: 0,
      hash: '',
      address_to: '',
      addresses_from: [],
      fee: '',
      fee_value: '',
      description: '',
      created_at: ''
    })

    const emptyTransactions = range(0,9).map(elem => getMockTransactionsData(`${elem}`))

    const getSort = ({order, column}: TGetSortParams<'created_at' | 'name' | 'amount' | 'value'>): TTransactionTableSort | null => {
      if (column) {
          switch (column.key) {
              case "created_at":
                  return order !== undefined
                      ? order === 'ascend'
                          ? 'date'
                          : '-date'
                      : null
              case "name":
                  return order !== undefined
                      ? order === 'ascend'
                          ? 'currency'
                          : '-currency'
                      : null
              case "amount":
                  return order !== undefined
                      ? order === 'ascend'
                          ? 'amount'
                          : '-amount'
                      : null
              case "value":
                  return order !== undefined
                      ? order === 'ascend'
                          ? 'value'
                          : '-value'
                      : null
              default: return null
          }
      } else {
          return null
      }
    }

    // effects

    useEffect(() => {
        dispatch(transactionsActions.watch_transactions({ query: transactionsBaseQuery, filter: transactionsFilter }));
    },[dispatch, transactionsBaseQuery, transactionsFilter])

    useEffect(() => {
        !currencyList &&  dispatch(cabinetMainActions.watch_currency_list_crypto())
    }, [dispatch, currencyList])

    // handlers

    const handlerTableChange = (
      pagination: TablePaginationConfig,
      filters: Record<string, Key[] | null>,
      sorter: SorterResult<any> | SorterResult<any>[],
    ) => {
      const sorterPrepare = sorter as TGetSortParams<'created_at' | 'name' | 'amount' | 'value'>
      const sortParams = getSort(sorterPrepare)

      dispatch(transactionsActions.set_transactions_base_query({
          ...transactionsBaseQuery,
          page: pagination.current ? pagination.current : null,
          pageSize: pagination.pageSize !== undefined ? `${pagination.pageSize}` : pagination.pageSize,
          sort: sortParams,
      } ))
    }

    const handlerFilter = () => {
        dispatch(transactionsActions.set_transactions_filter_mode(true))
    }

    const handlerCloseFilter = () => {
		dispatch(transactionsActions.set_transactions_filter_mode(false))
	}

	const handlerSubmitFilter = (val: Omit<TTransactionQueryFilter, 'name'>) => {
		dispatch(transactionsActions.set_transactions_filter(val))
	}

    const handlerSearchTransactions = (search: string | null) => {
        dispatch(transactionsActions.set_transactions_filter({
            ...transactionsFilter,
            name: search,
        }))
    }

    const handlerSetFilterCount = (count: number) => {
        dispatch(transactionsActions.set_transactions_filter_count(count))
    }

    const handlerChangeFilterCount = (type: 'inc' | 'dec') => {
        dispatch(
            type.toLowerCase() === 'inc'
            ? transactionsActions.add_transactions_count_filter(1)
            : transactionsActions.delete_transactions_count_filter(1))
    }

    return {
      transactions, loadTransactions, transactionsFilter,
      transactionsBaseQuery, transactionsCountFilter, mode,
      emptyTransactions, handlerTableChange, handlerFilter, handlerCloseFilter, handlerSubmitFilter,
      handlerSearchTransactions, handlerSetFilterCount, handlerChangeFilterCount
    }
}
