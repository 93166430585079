// Core
import {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {cabinetMainSelect, portfolioSelect} from "../../../../selectors/cabinet";
import {cabinetMainActions} from "../../../../actions/cabinet";
import {TImportAddress} from "../../../../types/cabinet/cabinet-main-type";
import {boolean, string} from "yup";


export const useImportAddressEf = () => {
    const dispatch = useDispatch()


    // Selectors

    const mode = useSelector(cabinetMainSelect.import_address)
    const currencyDetails = useSelector(portfolioSelect.currency_details)
    const formErrors = useSelector(cabinetMainSelect.import_address_errors)
    const load = useSelector(cabinetMainSelect.load_import_address)


    // State

    const [canSubmit, setCanSubmit] = useState<boolean>(true)
    const [requiredFields, setRequiredFields] = useState<{private_key: boolean, address: boolean}>({private_key: false, address: false})

    // Effects
    useEffect(() => {
        requiredFields.address && requiredFields.private_key
            ? setCanSubmit(false)
            : setCanSubmit(true)
    },[requiredFields])


    // Handlers

    const handlerClose = () => {
        dispatch(cabinetMainActions.set_import_assets(false))
    }

    const handlerSubmit = (values: any) => {
        currencyDetails && dispatch(cabinetMainActions.watch_import_address({...values, wallet_id: currencyDetails.id}))
    }

    const handlerChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        e.target.value.length > 0
            ? setRequiredFields(prev => ({...prev, [e.target.name]: true}))
            : setRequiredFields(prev => ({...prev, [e.target.name]: false}))
    }


    return {
        mode, canSubmit, formErrors, load,
        handlerClose, handlerSubmit, handlerChangeInput
    }
}
