// Type
import { ActionsCreatorType } from 'store'
// Types
import {
    ICurrencyItem, IExportAddressData,
    IFavoritesItem,
    ISetExportAddress,
    ITransactionsSend,
    ITransactionsSendAssets,
    MenuList,
    TExportAddress, TExportAddressData,
    TGetWalletAddresses,
    TImportAddress,
    TNotification,
    TReceiveAssetsConfig,
    TWalletAddresses,
} from 'types/cabinet/cabinet-main-type'
import {TWalletAddress} from "../../types/cabinet/portfoli-type";
import {IAddressTableItemBase} from "../../types/cabinet/profile";

// Async
export const CABINET__WATCH__CURRENCY_LIST = 'CABINET__WATCH__CURRENCY_LIST'
export const CABINET__WATCH__FAVORITES = 'CABINET__WATCH__FAVORITES'
export const CABINET__WATCH__ADD_FAVORITES = 'CABINET__WATCH__ADD_FAVORITES'
export const CABINET__WATCH__REMOVE_FAVORITES = 'CABINET__WATCH__REMOVE_FAVORITES'
export const CABINET__WATCH__ADDRESSES = 'CABINET__WATCH__ADDRESSES'
export const CABINET__WATCH__SEND_ASSETS = 'CABINET__WATCH__SEND_ASSETS'
export const CABINET__WATCH__SAVE_ADDRESS = 'CABINET__WATCH__SAVE_ADDRESS'
export const CABINET__WATCH__IMPORT_ADDRESS = 'CABINET__WATCH__IMPORT_ADDRESS'
export const CABINET__WATCH__EXPORT_ADDRESS = 'CABINET__WATCH__EXPORT_ADDRESS'
export const CABINET__WATCH__NOTIFICATIONS = 'CABINET__WATCH__NOTIFICATIONS'
export const CABINET__WATCH__READ_NOTIFICATIONS = 'CABINET__WATCH__READ_NOTIFICATIONS'
// Sync
export const CABINET__SET__FAVORITES = 'CABINET__SET__FAVORITES'
export const CABINET__SET__FAVORITES_LOCAL = 'CABINET__SET__FAVORITES_LOCAL'
export const CABINET__SET__WALLET_ADDRESSES = 'CABINET__SET__WALLET_ADDRESSES'
export const CABINET__SET__CURRENCY_ITEMS = 'CABINET__SET__CURRENCY_ITEMS'
export const CABINET__SET__SEND_ASSETS_DATA = 'CABINET__SET__SEND_ASSETS_DATA'
export const CABINET__SET__SEND_ASSETS_ERRORS = 'CABINET__SET__SEND_ASSETS_ERRORS'
export const CABINET__SET__SEARCH_RECIPIENT = 'CABINET__SET__SEARCH_RECIPIENT'
export const CABINET__SET__IMPORT_ASSETS = 'CABINET__SET__IMPORT_ASSETS'
export const CABINET__SET__EXPORT_ASSETS = 'CABINET__SET__EXPORT_ASSETS'
export const CABINET__SET__EXPORT_ADDRESS_FINAL = 'CABINET__SET__EXPORT_ADDRESS_FINAL'
export const CABINET__SET__HELP_TAB = 'CABINET__SET__HELP_TAB'
export const CABINET__SET__IMPORT_ADDRESS_ERRORS = 'CABINET__SET__IMPORT_ADDRESS_ERRORS'
export const CABINET__SET__EXPORT_ADDRESS_ERRORS = 'CABINET__SET__EXPORT_ADDRESS_ERRORS'
export const CABINET__SET__NOTIFICATIONS = 'CABINET__SET__NOTIFICATIONS'
export const CABINET__SET__EXPORT_ADDRESS_DATA = 'CABINET__SET__EXPORT_ADDRESS_DATA'

export const CABINET__ADD__FAVORITES = 'CABINET__ADD__FAVORITES'

export const CABINET__UPDATE__FAVORITES = 'CABINET__UPDATE__FAVORITES'
export const CABINET__UPDATE__WALLET_ADDRESSES = 'CABINET__UPDATE__WALLET_ADDRESSES'

export const CABINET__REMOVE_FAVORITES_LOCAL = 'CABINET__REMOVE_FAVORITES_LOCAL'
export const CABINET__REMOVE__FAVORITES = 'CABINET__REMOVE__FAVORITES'
export const CABINET__REMOVE__CURRENCY_LIST_CRYPTO = 'CABINET__REMOVE__CURRENCY_LIST_CRYPTO'


const cabinetMainActions = {
    // Setter
    set_form_errors: (data: any) => ({type: 'CABINET__SET__ERROR_FORM', payload: {data}} as const),
    set_menu_mode: (mode: MenuList) => ({type: 'CABINET__SET__MENU_MODE', payload: {mode}} as const),
    set_sider_mode: (mode: boolean) => ({type: 'CABINET__SET__SIDER_MODE', payload: {mode}} as const),
    set_auto_logout: (second: number | null) => ({type: 'CABINET__SET__AUTO_LOGOUT', payload: {second}} as const),
    set_currency_list_crypto: (data: IFavoritesItem[]) => ({type: 'CABINET__SET__CURRENCY_LIST_CRYPTO', payload: {data}} as const),
    set_currency_list_fiat: (data: ICurrencyItem[]) => ({type: 'CABINET__SET__CURRENCY_LIST_FIAT', payload: {data}} as const),
    set_favorites: (data: IFavoritesItem[]) => ({type: CABINET__SET__FAVORITES, payload: {data}} as const),
    set_favorites_local: (data: IFavoritesItem[]) => ({type: CABINET__SET__FAVORITES_LOCAL, payload: {data}} as const),
    set_currency_items: (data: IFavoritesItem[]) => ({type: CABINET__SET__CURRENCY_ITEMS, payload: {data}} as const),
    set_send_asset_mode: (mode: boolean) => ({type: 'CABINET__SET__SEND_ASSET_MODE', payload: {mode}} as const),
    set_receive_asset_mode: (mode: boolean, onlyQrCode?: boolean, config?: TReceiveAssetsConfig | null, title?: string) => ({type: 'CABINET__SET__RECEIVE_ASSET_MODE', payload: {mode, onlyQrCode, config, title}} as const),
    set_notifications: (data: TNotification[]) => ({type: CABINET__SET__NOTIFICATIONS, payload: {data}} as const),
    set_notification_mode: (mode: boolean) => ({type: 'CABINET__SET__NOTIFICATION_MODE', payload: {mode}} as const),
    set_wallet_addresses: (data: TWalletAddresses) => ({type: CABINET__SET__WALLET_ADDRESSES, payload: {data}} as const),
    set_wallet_addresses_mode: (mode: boolean) => ({type: 'CABINET__SET__WALLET_ADDRESSES_MODE', payload: {mode}} as const),
    set_wallet_recipient_address: (address: string | null) => ({type: 'CABINET__SET__WALLET_ADDRESS_RECIPIENT', payload: {address}} as const),
    set_send_assets_data: (data: ITransactionsSendAssets | null) => ({type: CABINET__SET__SEND_ASSETS_DATA, payload: {data}} as const),
    set_send_assets_errors: (data: any) => ({type: CABINET__SET__SEND_ASSETS_ERRORS, payload: {data}} as const),
    set_search_recipient: (value: string) => ({type: CABINET__SET__SEARCH_RECIPIENT, payload: {value}} as const),
    set_import_assets: (mode: boolean) => ({type: CABINET__SET__IMPORT_ASSETS, payload: {mode}} as const),
    set_export_assets: (data: IExportAddressData) => ({type: CABINET__SET__EXPORT_ASSETS, payload: {data}} as const),
    set_help_tab:(mode: string) => ({type: CABINET__SET__HELP_TAB, payload: {mode}} as const),
    set_import_address_errors: (data: any | null) => ({type: CABINET__SET__IMPORT_ADDRESS_ERRORS, payload: {data}} as const),
    set_export_address_errors: (data: any | null) => ({type: CABINET__SET__EXPORT_ADDRESS_ERRORS, payload: {data}} as const),
    // Create

    // Update
    tick_auto_logout: () => ({type: 'CABINET__TICK__AUTO_LOGOUT'} as const),
    add_to_favorites: (data: IFavoritesItem) => ({type: CABINET__ADD__FAVORITES, payload: {data}} as const),
    update_favorites: (data: IFavoritesItem[]) => ({type: CABINET__UPDATE__FAVORITES, payload: {data}} as const),
    update_wallet_addresses: (data: TWalletAddresses) => ({type: CABINET__UPDATE__WALLET_ADDRESSES, payload: {data}} as const),
    remove_from_favorites: (data: IFavoritesItem) => ({type: CABINET__REMOVE__FAVORITES, payload: {data}} as const),
    remove_favorites_local: () => ({type: CABINET__REMOVE_FAVORITES_LOCAL} as const),
    remove_from_currency_list_crypto: (id: string) => ({type: CABINET__REMOVE__CURRENCY_LIST_CRYPTO, payload: {id}} as const),
    // Delete

    // Loader
    load_form: (mode: boolean) => ({type: 'CABINET__LOAD__FORM', payload: {mode}} as const),
    load_currency_list_crypto: (mode: boolean) => ({type: 'CABINET__LOAD__CURRENCY_LIST_CRYPTO', payload: {mode}} as const),
    load_currency_list_fiat: (mode: boolean) => ({type: 'CABINET__LOAD__CURRENCY_LIST_FIAT', payload: {mode}} as const),
    load_wallet_addresses: (mode: boolean) => ({type: 'CABINET__LOAD__WALLET_ADDRESSES', payload: {mode}} as const),
    load_send_assets: (mode: boolean) => ({type: 'CABINET__LOAD__SEND_ASSETS', payload: {mode}} as const),
    load_import_address: (mode: boolean) => ({type: 'CABINET__LOAD__IMPORT_ADDRESS', payload: {mode}} as const),
    load_export_address: (mode: boolean) => ({type: 'CABINET__LOAD__EXPORT_ADDRESS', payload: {mode}} as const),

    // Clear
    clear_menu_mode: (mode: boolean) => ({type: 'CABINET__CLEAR__MENU_MODE', payload: {mode}} as const),
    // Async
    watch_currency_list_crypto: () => ({type: CABINET__WATCH__CURRENCY_LIST, payload: 'crypto'} as const),
    watch_currency_list_fiat: () => ({type: CABINET__WATCH__CURRENCY_LIST, payload: 'fiat'} as const),
    watch_favorites_list: (mode: boolean, favoritesLocal?: IFavoritesItem[] | null) => ({type: CABINET__WATCH__FAVORITES, payload: {mode, favoritesLocal}} as const),
    watch_add_favorites: (data: IFavoritesItem) => ({type: CABINET__WATCH__ADD_FAVORITES, payload: {data}} as const),
    watch_remove_favorites: (data: IFavoritesItem) => ({type: CABINET__WATCH__REMOVE_FAVORITES, payload: {data}} as const),
    watch_wallet_addresses: (data: TGetWalletAddresses) => ({type: CABINET__WATCH__ADDRESSES, payload: {data}} as const),
    watch_send_assets: (data: ITransactionsSend ) => ({type: CABINET__WATCH__SEND_ASSETS, payload: {data}} as const),
    watch_save_address: (data: IAddressTableItemBase) => ({type: CABINET__WATCH__SAVE_ADDRESS, payload: {data}} as const),
    watch_import_address: (data: TImportAddress) => ({type: CABINET__WATCH__IMPORT_ADDRESS, payload: {data}} as const),
    watch_export_address: (data: TExportAddress) => ({type: CABINET__WATCH__EXPORT_ADDRESS, payload: {data}} as const),
    watch_notifications: () => ({type: CABINET__WATCH__NOTIFICATIONS} as const),
    watch_read_notifications: () => ({type: CABINET__WATCH__READ_NOTIFICATIONS} as const),
}

export type CabinetActionReducerType = ActionsCreatorType<typeof cabinetMainActions>

export default cabinetMainActions
