// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Selectors
import {cabinetMainSelect} from "selectors/cabinet";
import {cabinetMainActions} from "../../../../../../actions/cabinet";


type TSendAssetsEfProps = {
    handlerFullClose: () => void
}
export const useSuccessTransactionsEf = ({handlerFullClose}: TSendAssetsEfProps) => {
    const dispatch = useDispatch()

    // State
    const [mode, setMode] = useState<boolean>(false)
    const [save, setSave] = useState<boolean>(false)


    // Selectors

    const sendAssetsData = useSelector(cabinetMainSelect.send_assets_data)


    // Effects

    useEffect(() => {
        sendAssetsData?.step === '2' && setMode(true)
        !sendAssetsData && setSave(false)
        !sendAssetsData && handlerFullClose()
    },[sendAssetsData])


    // Handlers

    const handlerCloseModal = () => {
        setMode(false)
        dispatch(cabinetMainActions.set_send_assets_data(null))
        handlerFullClose()
    }

    const handlerSaveAddress = () => {
        setMode(false)
        setSave(true)
    }

    const handlerCloseSaveAddressModal = () => {
        setMode(true)
        setSave(false)
    }


    return {
        mode, sendAssetsData, save,
        handlerCloseModal, handlerSaveAddress, handlerCloseSaveAddressModal
    }
}
