// Core
import React, {FC, memo, useEffect} from 'react'
import {Redirect} from "react-router-dom"
// Hook
import {useMainEf} from './useMainEf'
// Components
import {HeadArea, CointyBlock, MultiCoin, Secure, Receive, Device} from './components'


type PropsType = {
    children?: never
}

const Main: FC<PropsType> = memo(() => {
    const {
        isAuth, lang,
    } = useMainEf()

    if (isAuth) return <Redirect to={`/${lang}/cabinet`}/>

    return (
        <div className={'main-pages'}>
            <div className={'sm--pd-x-2'}>
                <HeadArea/>
                <CointyBlock/>
                <MultiCoin/>
            </div>
            <Secure/>
            <div className={'sm--pd-x-2'}>
                <Receive/>
                <Device/>
            </div>
        </div>
    )
})

export default Main
