// Core
import {useSelector} from 'react-redux'
import {useEffect} from "react"
// Selector
import {appSelect, authSelect} from "selectors"


export const usePrivacyPolicyEf = () => {
    const isAuth = useSelector(authSelect.is_auth)
    const lang = useSelector(appSelect.language)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [window])

    return {
        isAuth, lang,
    }
}
