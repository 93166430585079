// Core
import {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {cabinetMainSelect} from "../../../../../../selectors/cabinet";
import {cabinetMainActions} from "../../../../../../actions/cabinet";



export const useSendAssetsConfirmEf = () => {
    const dispatch = useDispatch()

    // State
    const [password, setPassword] = useState<null | string>(null)
    const [mode, setMode] = useState<boolean>(false)

    // Selectors

    const sendAssetsData = useSelector(cabinetMainSelect.send_assets_data)
    const loadSendAssets = useSelector(cabinetMainSelect.load_send_assets)
    const formErrors = useSelector(cabinetMainSelect.send_assets_errors)


    // Effects

    useEffect(() => {
        sendAssetsData?.step === '1' && setMode(true)
        sendAssetsData?.step === '2' && setMode(false)
    },[sendAssetsData])

    // Handlers

    const handlerClose = () => {
        setMode(false)
        dispatch(cabinetMainActions.set_send_assets_data(null))
        dispatch(cabinetMainActions.set_send_asset_mode(true))
    }

    const handlerChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value)
    }

    const handlerSubmitPassword = (value: { password: string }) => {
        sendAssetsData && dispatch(cabinetMainActions.watch_send_assets({
            wallet_id: sendAssetsData.wallet_id,
            wallet_currency_id: sendAssetsData.wallet_currency_id,
            address:  sendAssetsData.address,
            address_to: sendAssetsData.address_to,
            amount: sendAssetsData.amount,
            notes: sendAssetsData.notes,
            tax_from: sendAssetsData.tax_from,
            amount_fiat: sendAssetsData.amount_fiat,
            crypto_name: sendAssetsData.crypto_name,
            fiat_sign: sendAssetsData.fiat_sign,
            password: value.password
        }))
    }

    return {
        mode, sendAssetsData, password, loadSendAssets, formErrors,
        handlerClose, handlerSubmitPassword, handlerChangePassword
    }
}
