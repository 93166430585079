// Core
import React, {FC, LegacyRef, memo, useEffect, useRef} from 'react'
import clsx from "clsx"
// Hook
import {usePageWrapperEf} from './usePageWrapperEf'
// Component
import {Footer} from "#/components"


type TProp = {
    bg?: 'width' | 'transparent'
    className?: string
    handlerRef?: (ref: LegacyRef<any>) => void
}

const PageWrapper: FC<TProp> = memo(({children, bg = 'width', className = '', handlerRef}) => {
    const {
        siderMode, $container,
    } = usePageWrapperEf(handlerRef)

    return (
        <div className={`cabinet__content__wrapper ${className}`} ref={$container}>
            <div className={clsx('cabinet__content__wrapper_main', bg, {'close_sider': siderMode})}>
                {children}
            </div>
            <Footer/>
        </div>
    )
})

export default PageWrapper
