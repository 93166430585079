// Core
import {useEffect, LegacyRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Action
import {portfolioActions, cabinetMainActions} from 'actions/cabinet'
// Selector
import {portfolioSelect, cabinetMainSelect} from 'selectors/cabinet'


export const usePortfolioEf = () => {
    const dispatch = useDispatch()

    // Selectors

    const tab = useSelector(portfolioSelect.tab)
    const balanceDuration = useSelector(portfolioSelect.balance_duration)
    const currencyDetails = useSelector(portfolioSelect.currency_details)
    const currencyDetailsChart = useSelector(portfolioSelect.currency_details_chart)
    const currencyDetailsChartDuration = useSelector(portfolioSelect.currency_details_chart_duration)
    const transactionsFilter = useSelector(portfolioSelect.transactions_filter)
    const transactionsBaseQuery = useSelector(portfolioSelect.transactions_base_query)
    const loadTransactions = useSelector(portfolioSelect.load_transactions)
    const transactions = useSelector(portfolioSelect.transactions)
    const currencyList = useSelector(cabinetMainSelect.currency_list_crypto)

    const [ref, setRef] = useState<any>(null)

    const handlerRef = (ref: LegacyRef<any>) => setRef(ref)


    // Effects

    useEffect(() => {
        !currencyDetails && dispatch(portfolioActions.watch_balance(balanceDuration))
        !currencyDetails && dispatch(portfolioActions.watch_wallet())
    }, [dispatch, currencyDetails])

    useEffect(() => {
        currencyDetails && dispatch(portfolioActions.watch_currency_detail(currencyDetails.id))
        currencyDetails && !currencyDetailsChart && dispatch(portfolioActions.watch_currency_detail_chart(currencyDetails.cur_id, currencyDetailsChartDuration))
    }, [currencyDetails, dispatch])

    useEffect(() => {
        currencyDetails && tab === 'transactions' && dispatch(portfolioActions.watch_transactions(currencyDetails.id, transactionsBaseQuery, transactionsFilter))
    }, [dispatch, tab, transactionsBaseQuery, transactionsFilter, currencyDetails])

    useEffect(() => {
        !currencyList &&  dispatch(cabinetMainActions.watch_currency_list_crypto())
    }, [dispatch, currencyList, tab, currencyDetails])

    useEffect(() => {
        ref && (ref.scrollTop = 0)
    }, [tab, ref, currencyDetails])


    return {
        balanceDuration, currencyDetails, tab,
        handlerRef,
    }
}
