// Types
import {Language} from 'types/app-type'
import {MenuList} from 'types/cabinet/cabinet-main-type'
import { TTransactionItemStatus, TTransactionItemType } from 'types/cabinet/transactions'
import {TPortfolioDuration} from "../types/cabinet/portfoli-type";


const isDev = process.env.NODE_ENV === 'development'
const config =  {
    app: {
        isDev,
        // apiURL: isDev ? 'http://192.168.83.180/' : 'https://api.catchcoin.io/',
        apiURL: 'https://catchcoin-api.simplexpage.dev',
        domain: isDev ? 'localhost' : 'catchcoin.simplexpage.dev',
        pages_with_layout: ['', 'about', 'news', 'news-page', 'faq', 'contacts', 'terms-of-use', 'privacy-policy'],
        pages_with_coming_soon: ['terms-of-use', 'privacy-policy'],
        page_header_active_shadow: ['about', 'news', 'news-page', 'faq'],
        language_list: ['en',] as Language[],
        page_token: [ 'about', 'faq', 'contacts', 'cabinet', 'signin', 'signup'],
        months: [
            {title: 'January', val:'1'}, {title: 'February', val:'2'}, {title: 'March', val:'3'},{title: 'April', val:'4'},
            {title: 'May', val:'5'}, {title: 'June', val:'6'}, {title: 'July', val:'7'}, {title: 'August', val:'8'},
            {title: 'September', val:'9'}, {title: 'October', val:'10'}, {title: 'November', val:'11'}, {title: 'December', val:'12'},
        ],
    },
    cabinet: {
        main: {
            menu_mode: ['home', 'portfolio', 'transactions', 'setting', 'profile', 'help'] as MenuList[],
        },
        profile: {
            tab: [ 'account-details', 'saved-addresses', 'activity'] as string[]
        },
        setting: {
            tab: ['preferences','security','notifications'] as string[]
        },
        portfolio: {
            tab: ['overview','transactions','added-addresses'] as string[],
            report: {
                type: ['all', 'send', 'receive'],
                duration: [
                    {
                        title: 'All time',
                        value: null
                    },
                    {
                        title: 'Last year',
                        value: 'year'
                    },
                    {
                        title: 'Last month',
                        value: 'month'
                    },
                    {
                        title: 'Custom',
                        value: 'custom'
                    },
                ] as TPortfolioDuration[]
            }
        },
        transaction: {
            filter: {
                status: ['WAITING', 'PROCESSED', 'CANCELED'] as TTransactionItemStatus[],
                type: ['IN', 'OUT'] as TTransactionItemType[],
                date_format: 'YYYY-MM-DD',
                date_range: [
                    {title: 'All time', val: 'all'}, {title: 'Today', val: 'today'}, {title: 'This week', val: 'week'}, {title: 'This month', val: 'month'},
                    {title: 'Last week', val: 'last-week'}, {title: 'Last month', val: 'last-month'}, {title: 'Custom period', val: 'custom'},
                ]
            }
        }
    }
}

export default config
