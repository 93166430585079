// Core
import {useDispatch, useSelector} from 'react-redux'
// Selector
import {portfolioSelect} from 'selectors/cabinet'
// Action
import {portfolioActions} from 'actions/cabinet'
// Type
import {EWalletDuration} from 'types/cabinet/cabinet-main-type'


export const useHeaderPortfolioEf = () => {
	const dispatch = useDispatch()
	const tab = useSelector(portfolioSelect.tab)
	const currencyDetails = useSelector(portfolioSelect.currency_details)
	const loadCurrencyDetail = useSelector(portfolioSelect.load_currency_detail)

	const handlerTab = (val: string) => {
		dispatch(portfolioActions.set_tab(val))
	}

	const handlerBack = () => {
		dispatch(portfolioActions.set_currency_detail(null))
		dispatch(portfolioActions.set_currency_detail_data(null))
		dispatch(portfolioActions.set_currency_detail_chart(null))
		dispatch(portfolioActions.set_currency_details_chart_duration(EWalletDuration.HOUR))
		dispatch(portfolioActions.delete_load_first('currency-detail'))
	}

	return {
		tab, currencyDetails, loadCurrencyDetail,
		handlerTab, handlerBack,
	}
}
