// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Selector
import {portfolioSelect} from 'selectors/cabinet'
// Action
import {portfolioActions} from 'actions/cabinet'


export const useWalletAddressQrEf = () => {
	const dispatch = useDispatch()
	const walletAddressQrMode = useSelector(portfolioSelect.wallet_address_qr_mode)

	const handlerClose = () => {
		dispatch(portfolioActions.set_wallet_address_qr_mode(false))
	}

	return {
		walletAddressQrMode,
		handlerClose,
	}
}
