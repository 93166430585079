// Core
import React, {FC, memo} from 'react'
// Style
import './Portfolio.scss'
// Hook
import {usePortfolioEf} from './usePortfolioEf'
// Components
import { GenerateReport, AddWalletModal, Overview, Transactions, WalletAddressQr} from './components'
import {PageWrapper} from "#/components"
import {Balance, Wallets, AddedAddresses} from "./components"
import ImportAddress from "#/components/import-address/ImportAddress";
import ExportAddress from "#/components/export-address/ExportAddress";


type PropsType = {
    children?: never
}

const getTabContent =(value: string) => {
    switch (value) {
        case 'overview': return <Overview/>
        case 'transactions': return <Transactions/>
        case 'added-addresses': return <AddedAddresses/>
        default: return ''
    }
}

const Portfolio: FC<PropsType> = memo(() => {
    const {
        currencyDetails, tab,
        handlerRef,
    } = usePortfolioEf()

    return (<>
        <PageWrapper bg={"transparent"} className={'portfolio'} handlerRef={handlerRef} >
            {
                !currencyDetails
                    ? (<>
                        <Balance/>
                        <Wallets/>
                    </>)
                    : (
                        <section className={'portfolio__cur-det'}>
                            {tab && getTabContent(tab)}
                        </section>
                    )
            }
        </PageWrapper>
        <GenerateReport/>
        <AddWalletModal/>
        <WalletAddressQr/>
        <ImportAddress />
        <ExportAddress />
    </>)
})

export default Portfolio
