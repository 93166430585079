// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Type
import { TTransactionItem } from 'types/cabinet/transactions'
// Action
import {appActions} from 'actions'


export const useTransactionInfoEf = () => {
	const dispatch = useDispatch()
	//const x = useSelector(x)

	const handlerCopy = (data: TTransactionItem, type: boolean) => {
		dispatch(appActions.set_push({
			title: 'Success',
			content: `${type ? 'Transaction ID' : 'Send to'} has been successfully copied`
		}))
	}

	return {
		handlerCopy,
	}
}
