// Core
import {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Formik, Field, Form, useField, useFormikContext } from 'formik';
// Action
import {cabinetMainActions, portfolioActions} from 'actions/cabinet'
// Selector
import {cabinetMainSelect, portfolioSelect} from 'selectors/cabinet'
import {TWalletItem} from "types/cabinet/portfoli-type";
import {RadioChangeEvent} from "antd/es/radio";
import {string} from "yup";
import {log} from "util";


// Local Types and Interfaces

type TTransactionsType = 'sender' | 'receiver'

type TTransactionsInfo = {
    crypto: string | number,
    fiat: string | number
} | null

type TTransactionsAmount = {
    amount: string | number | null,
    amount_fiat: string | number | null
}

export const useSendAssetsModalEf = () => {
    const dispatch = useDispatch()

    const nullTransactionsAmount: TTransactionsAmount = {
        amount: null,
        amount_fiat: null
    }

    // Selectors

    const sendAssetsMode = useSelector(cabinetMainSelect.send_asset_mode)
    const wallets = useSelector(portfolioSelect.wallets)
    const walletAddressRecipient = useSelector(cabinetMainSelect.wallet_address_recipient)
    const loadRecipients = useSelector(cabinetMainSelect.load_wallet_addresses)
    const loadSendAssets = useSelector(cabinetMainSelect.load_send_assets)
    const formErrors = useSelector(cabinetMainSelect.send_assets_errors)
    const menuMode = useSelector(cabinetMainSelect.menu_mode)
    const currentCurrencyDetails = useSelector(portfolioSelect.currency_details_data)


    // State

    const [selected, setSelected] = useState<string | undefined>(undefined)
    const [wallet, setWallet] = useState<TWalletItem | null>(null)
    const [resetForm, setResetForm] = useState<boolean>(false)
    const [closerModal, setCloserModal] = useState<boolean>(false)
    const [recipientModal, setRecipientModal] = useState<boolean>(false)
    const [transactionsType, setTransactionsType] = useState<TTransactionsType>('receiver')
    const [transactionsInfo, setTransactionsInfo] = useState<TTransactionsInfo>(null)
    const [transactionsAmount, setTransactionsAmount] = useState<TTransactionsAmount>(nullTransactionsAmount)
    const [transactionsErrors, setTransactionsErrors] = useState<TTransactionsAmount>(nullTransactionsAmount)
    const transactionsFee = {
        '1': '0.05',
        '2': '0.08'
    }


    // Effects

    useEffect(() => {
        !wallets && dispatch(portfolioActions.watch_wallet())
    },[wallets])

    useEffect(() => {
        !sendAssetsMode && setResetForm(false)
        sendAssetsMode && menuMode?.toLowerCase() === 'portfolio' && currentCurrencyDetails && setSelectedWallet(currentCurrencyDetails.id)
    },[sendAssetsMode])


    // Handlers

    // return wallet via {id} param
    const getWallet = (id: string) => wallets?.items.find(item => item.id === id)

    const handlerSendAll = () => {
        setTransactionsAmount({amount: wallet?.balance || 0, amount_fiat: wallet?.value || 0})
    }

    const handlerChangeTransactionType = (e: RadioChangeEvent) => {
        setTransactionsType(e.target.value);
    }

    const setSelectedWallet = (walletId: string) => {
        const wallet = getWallet(walletId)

        wallet && setSelected(wallet.id)
        wallet && setWallet(wallet)
    }

    const handlerAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
        // check input type ('amount' or 'amount_fiat')
        if (e.target.name.toLowerCase() === 'amount') {
            // check if amount more than wallet balance
            if (Number(e.target.value) > Number(wallet?.balance)) {
                // set `amount` errors
                setTransactionsErrors((values) => ({...values, amount: `Your Amount balance is ${wallet?.balance}`}))
            } else {

                setTransactionsAmount(({amount_fiat, amount}) =>
                    ({
                        amount: e.target.value,
                        // check if `amount` was changed
                        amount_fiat: amount !== e.target.value && Number(e.target.value) !== 0
                            // calculate current `amount_fiat`
                            ? ((Number(e.target.value) * Number(wallet?.value)) / Number(wallet?.balance)).toFixed(8)
                            : amount_fiat
                    })
                )
                // reset `amount` errors
                setTransactionsErrors((values) => ({...values, amount: null}))
            }
        } else {
            // check if amount_fia more than wallet value
            if (Number(e.target.value) > Number(wallet?.value)) {
                // set `amount_fiat` errors
                setTransactionsErrors((values) => ({...values, amount_fiat: `Your value balance is ${wallet?.value}`}))
            } else {

                setTransactionsAmount(({amount_fiat, amount}) =>
                    ({
                        // check if `amount_fiat` was changed
                        amount: amount_fiat !== e.target.value && Number(e.target.value) !== 0
                            // calculate current `amount`
                            ? ((Number(e.target.value) * Number(wallet?.balance)) / Number(wallet?.value)).toFixed(8)
                            : amount,
                        amount_fiat:  e.target.value
                    })
                )
                // reset `amount_fiat` errors
                setTransactionsErrors((values) => ({...values, amount_fiat: null}))
            }

        }

    }

    const handlerClose = () => {
        dispatch(cabinetMainActions.set_send_asset_mode(false))
        setCloserModal(true)
    }

    const handlerCloseCloserModal = () => {
        setCloserModal(false)
        dispatch(cabinetMainActions.set_send_asset_mode(true))
    }

    const handlerCloseRecipientModal = () => {
        dispatch(cabinetMainActions.set_send_asset_mode(true))
        setRecipientModal(false)
    }

    const handlerOpenRecipientModal = () => {
        dispatch(cabinetMainActions.watch_wallet_addresses({}))
        // dispatch(cabinetMainActions.set_send_asset_mode(false))
        // setRecipientModal(true)
    }

    const handlerFullCloseAssets = () => {
        // reset all values
        setCloserModal(false)
        setWallet(null)
        setTransactionsInfo(null)
        setTransactionsAmount(nullTransactionsAmount)
        setTransactionsErrors(nullTransactionsAmount)
        setTransactionsType('receiver')
        handlerClearSelect()
        dispatch(cabinetMainActions.set_wallet_recipient_address(null))
        setResetForm(true)
        setTimeout(() => {
            setResetForm(false)
        },100)
    }

    const handlerSelectChange = (value: string) => {
        // get current crypto wallet
        const wallet = getWallet(value)
        setSelected(value)
        // put current wallet to local store
        wallet && setWallet(wallet)
        wallet && setTransactionsAmount({amount: 0, amount_fiat: 0})
    }

    const handlerClearSelect = () => {
        setSelected(undefined)
    }

    const handlerSubmitSendAssets = (values: any) => {
        wallet && dispatch(cabinetMainActions.watch_send_assets({
            wallet_id: wallet?.id,
            wallet_currency_id: wallet?.cur_id,
            address:  wallet?.address,
            address_to: values.address_to,
            amount: values.amount,
            notes: values.notes,
            tax_from: transactionsType,
            amount_fiat: transactionsAmount.amount_fiat || 0,
            crypto_name: wallet?.name,
            fiat_sign: wallets?.base_fiat_sign || ''
        }))
    }

    return {
        sendAssetsMode, selected, wallets, wallet, closerModal, transactionsType, walletAddressRecipient, formErrors, loadSendAssets,
        transactionsFee, transactionsInfo, transactionsAmount, transactionsErrors, resetForm, loadRecipients,
        recipientModal, handlerOpenRecipientModal ,handlerCloseRecipientModal,
        handlerClose, handlerSelectChange, handlerSubmitSendAssets, handlerAmountChange,
        handlerFullCloseAssets, handlerCloseCloserModal, handlerSendAll, handlerChangeTransactionType
    }
}
