// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Selector
import {cabinetMainSelect, homeSelector} from "selectors/cabinet"
// Hooks
import {useParseToCurrency} from "hooks";
// Types
import {ICurrencyItem, IFavoritesItem} from "types/cabinet/cabinet-main-type"
import { homeActions} from "actions/cabinet";
import {RadioChangeEvent} from "antd/lib/radio";


export const usePriceChartEf = () => {
    const dispatch = useDispatch()

    const chartData = useSelector(homeSelector.home_data_chart)
    const overview = useSelector(homeSelector.home_data_overview)
    const baseFiatName = useSelector(homeSelector.base_fiat_name)
    const baseFiatSign = useSelector(homeSelector.base_sign_name)
    const currentCryptoId = useSelector(homeSelector.currency_crypto_id)
    // get current duration for charts
    const currentDuration = useSelector(homeSelector.current_duration)
    // parse value to correct currency format
    const parseValue = useParseToCurrency(baseFiatSign || '')
    const loadChart = useSelector(homeSelector.home_load_chart)
    const updatingChart = useSelector(homeSelector.home_updating_chart)
    const changedFavorites = useSelector(homeSelector.home_changed_favorites)
    // const favoritesLocal = useSelector(cabinetMainSelect.favorites_local)
    const cryptoFavoritesList: null | IFavoritesItem[] = useSelector(cabinetMainSelect.currency_favorites)
    const cryptoCurrencyList: null | ICurrencyItem[]  = useSelector(homeSelector.currency_list)


    // active currency crypto tab
    const [activeCrypto, setActiveCrypto] = useState<ICurrencyItem | null>(null)
    // show favorites modal
    const [showFavorites, setShowFavorites] = useState<boolean>(false)
    const [currencyList, setCurrencyList] = useState<null | ICurrencyItem[]>()

    // get current crypto currency
    useEffect(() => {
        const currentCryptoItem  = currencyList?.find( item => item.id.toLowerCase() === currentCryptoId?.toString())

        setActiveCrypto(currentCryptoItem || null)
    },[currencyList, currentCryptoId])

    // set to currency list - favorites or crypto currency list
    useEffect(() => {

        cryptoFavoritesList && cryptoFavoritesList.length ?
            setCurrencyList(cryptoFavoritesList)
        :
            setCurrencyList(cryptoCurrencyList)

    },[cryptoCurrencyList, cryptoFavoritesList])


    // Handlers

    const handleChangeCrypto = (value: string) => {
        dispatch(homeActions.watch_update_home_base({duration: currentDuration, currencyId: value}))
        dispatch(homeActions.home_set_crypto_currency(value))
    }

    const handlerRequestChartData = () => {
        dispatch(homeActions.watch_home_base({duration: currentDuration}))
    }

    // change current duration for chart
    const handlerChangeDuration = (event: RadioChangeEvent) => {
        dispatch(homeActions.home_set_duration(event.target.value))

        currentCryptoId && dispatch(homeActions.watch_update_home_base({duration: event.target.value, currencyId: currentCryptoId.toString()}))
    }
    // toggle change favorites modal
    const handlerChangeFavorites = () => {
        setShowFavorites(!showFavorites)
    }

    const handlerCloseFavorites = () => {
        changedFavorites && dispatch(homeActions.watch_home_base({duration: currentDuration}))
        setShowFavorites(false)
        dispatch(homeActions.home_set_changed_favorites(false))
    }


    return {
        currencyList, chartData, overview, showFavorites, activeCrypto, baseFiatName,
        currentDuration, loadChart, updatingChart, currentCryptoId, baseFiatSign,
        handleChangeCrypto, handlerChangeFavorites, handlerChangeDuration, handlerRequestChartData, handlerCloseFavorites, parseValue
    }
}
