// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button, Input, Radio} from 'antd'
// Ant Icon
import {CopyOutlined, QrcodeOutlined} from '@ant-design/icons'
// Hook
import {useExportFinalEf} from './useExportFinalEf'
import {CopyToClipboard} from "react-copy-to-clipboard";
import {TExportAddressKeys} from "../../../../../../types/cabinet/cabinet-main-type";


type PropsType = {
    children?: never,
    address: string,
    private_key: string,
    handlerCopy: () => void,
    handlerQr: (address: string, title: string) => void,
    handlerClose: () => void
}

const ExportFinal: FC<PropsType> = memo(({address, private_key, handlerCopy, handlerQr, handlerClose}) => {
    const { key, keys, handlerKeysChange } = useExportFinalEf({private_key})

    return <>
        <p className={'export-address__text'}>A private key, as opposed to a public key, is secret information that no one but its owner must know. Whoever owns the private key owns the funds stored on it. Therefore, it is very important to ensure safe storage and not to forget about the method of sweeping, especially if you lose access to the key vault or if it has fallen into the hands of others.</p>
        <label className={'export-address__label'}>Address</label>
        <div className={'export-address__input-wrap'}>
            <Input placeholder="Address" value={address} className={'export-address__input'} disabled={true} />
            <CopyToClipboard text={address}>
                <Button
                    onClick={handlerCopy}
                    type={'default'}
                    className={'portfolio__copy-btn portfolio__copy-btn_margin'}
                >
                    <CopyOutlined />
                </Button>
            </CopyToClipboard>
            <Button
                onClick={() => handlerQr(address, 'Copy address')}
                type={'default'}
                className={'portfolio__copy-btn'}
            >
                <QrcodeOutlined />
            </Button>
        </div>
        <div>
            <p className={'export-address__text export-address__text_mt'}>Private key type</p>
            <Radio.Group onChange={handlerKeysChange} value={key} className={'export-address__radio'}>
                {
                    keys?.map(item =>
                        <Radio value={item.value} key={item.value} className={'export-address__radio-item'}>{item.title}</Radio>)
                }
            </Radio.Group>
        </div>
        <p className={'export-address__text'}>Private key</p>
        <div className={'export-address__input-wrap'}>
            <Input placeholder="Address" className={'export-address__input'} disabled={true} value={key} />
            <CopyToClipboard text={key}>
                <Button
                    onClick={handlerCopy}
                    type={'default'}
                    className={'portfolio__copy-btn portfolio__copy-btn_margin'}
                >
                    <CopyOutlined />
                </Button>
            </CopyToClipboard>
            <Button
                onClick={() => handlerQr(key, 'Copy key')}
                type={'default'}
                className={'portfolio__copy-btn'}
            >
                <QrcodeOutlined />
            </Button>
        </div>
        <Button
            className={'receive-assets__button'}
            onClick={handlerClose}
            type="primary"
        >
            Done
        </Button>
    </>
})

export default ExportFinal
