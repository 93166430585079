// Core
import {ChangeEvent, useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {cabinetMainSelect} from "../../../../../../selectors/cabinet";
import {cabinetMainActions, profileActions} from "../../../../../../actions/cabinet";


export const useSelectRecipientEf = () => {
    const dispatch = useDispatch()

    // State

    const [searchAction, setSearchAction] = useState(false)

    const timer = useRef<any>(null)


    // Selectors

    const mode = useSelector(cabinetMainSelect.wallets_addresses_mode)
    const walletsAddresses = useSelector(cabinetMainSelect.wallets_addresses)
    const loadSearch = useSelector(cabinetMainSelect.load_wallet_addresses)
    const searchRecpient = useSelector(cabinetMainSelect.search_recipient)


    // Effects

    useEffect(() => {
        searchAction &&  handlerThrottlingSearch()
    }, [searchRecpient, searchAction])


    // Handlers

    const handlerCloseModal = () => {
        dispatch(cabinetMainActions.set_wallet_addresses_mode(false))
        dispatch(cabinetMainActions.set_send_asset_mode(true))
    }

    const handlerChooseAddress = (address: string) => {
        dispatch(cabinetMainActions.set_wallet_recipient_address(address))
        handlerCloseModal()
    }

    const handlerSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(cabinetMainActions.set_search_recipient(e.target.value))
        setSearchAction(true)
    }

    const handlerThrottlingSearch = () => {
        clearTimeout(timer.current)

        timer.current = setTimeout(() => {
            walletsAddresses?.items && dispatch(cabinetMainActions.watch_wallet_addresses({
                name: searchRecpient,
                // full_name: searchRecpient
            }))
            setSearchAction(false)
        }, 500)
    }

    const handlerOnScrollAddresses = () => {
        walletsAddresses && +walletsAddresses.pagination.totalCount > 10 && dispatch(cabinetMainActions.watch_wallet_addresses({
           page: walletsAddresses.pagination.page + 1,
        }))
    }

    return {
        mode, walletsAddresses, loadSearch, searchRecpient,
        handlerSearch, handlerCloseModal, handlerChooseAddress,
        handlerOnScrollAddresses
    }
}
