// Core
import React, {FC, memo, useState} from 'react'
// Ant Components
import {Input, Modal, Table, Button, Radio, Divider} from 'antd'
// Ant Icon
import {SearchOutlined} from '@ant-design/icons'
// Style
import './AddWalletModal.scss'
// Hook
import {useAddWalletModalEf} from './useAddWalletModalEf'
// Element
import {ComingSoon} from "elements"
// Types
import { IFavoritesItem, IWalletItem } from 'types/cabinet/cabinet-main-type'
// Utils
import {config} from 'utils'


const sort = (A: string | number, B: string | number) => {
    if (A < B) return -1
    if (A > B) return 1
    return 0
}

type TColumns = {
    symbol: string
}


const columns = ({symbol}: TColumns) => [
    {
        title: 'Assets Name',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        disable: true,
        sorter: {
            compare: (a: IFavoritesItem, b: IFavoritesItem) => sort(a.full_name, b.full_name),
        },
        render: (text: string, record: IFavoritesItem) => (<>
            {
                <div>
                    <img width={24} src={config.app.apiURL + record.icon} alt={`${record.full_name} icon`} />
                    {record.full_name}
                </div>
            }
        </>)
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        sorter: {
            compare: (a: IFavoritesItem, b: IFavoritesItem) => sort(a.price, b.price),
        },
        render: (text: string, record: IFavoritesItem) => `${symbol} ${record.price}`
    },
    {
        title: 'Change (24H)',
        dataIndex: 'percent_change',
        key: 'percent_change',
        align: 'right',
        sorter: {
            compare: (a: IFavoritesItem, b: IFavoritesItem) => sort(a.percent_change, b.percent_change),
        },
        render: (text: string, record: IFavoritesItem) => `${record.percent_change}%`
    },
    {
        title: 'Market Cap',
        dataIndex: 'market_cap',
        key: 'market_cap',
        align: 'right',
        // sorter: {
        //     compare: (a: IFavoritesItem, b: IFavoritesItem) => sort(a.market_cap, b.market_cap),
        // },
        render: (text: string, record: IFavoritesItem) => `${record.market_cap}`
    }
]

type PropsType = {
    children?: never
}

const AddWalletModal: FC<PropsType> = memo(() => {
    const {
        addWalletMode, addSearchWallet, currencyList, freeCryptoList, loadWalletCreate, walletsPrepare,
        handlerClose, handlerSearchWallet, handlerAddWallet
    } = useAddWalletModalEf()

    const [checkedWallet, setCheckedWallet] = useState<null | IWalletItem>(null)

    // rowSelection object indicates the need for row selection
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IWalletItem[]) => {
            setCheckedWallet(selectedRows[0])
        },
        getCheckboxProps: (record: IWalletItem) => ({
            disabled: record.disabled, // Column configuration not to be checked
          }),
    };

    return (
        <Modal
            className={'portfolio-add-wallet-modal'}
            title={(<>
                Add Wallet
                {/* <Input
                    placeholder="Search"
                    prefix={<SearchOutlined />}
                    style={{width: 230}}
                    className={'input-item portfolio-add-wallet-modal__input'}
                    size={'middle'}
                    onChange={handlerSearchWallet}
                    value={addSearchWallet}
                    // disabled={loadAddressTable}
                    allowClear={true}
                /> */}
            </>)}
            centered
            visible={addWalletMode}
            onCancel={handlerClose}
            width={904}
            footer={false}
        >
                {
                    freeCryptoList &&
                        <Table
                            className={'base-table portfolio-wallet__table'}
                            rowKey="id"
                            rowSelection={{
                                type: 'radio',
                                ...rowSelection,
                            }}
                            // loading={loadWallet && loadFirst.includes('wallets')}
                            // scroll={{ y: tableHeight}}
                            pagination={false}
                            // expandable={!loadFirst.includes('wallets') ? {} : {expandedRowRender: (data) => <WalletChart fiatSign={fiatSymbol} data={data}/>}}
                            //@ts-ignore
                            columns={columns({symbol:walletsPrepare ? walletsPrepare.base_fiat_sign : ''})}
                            dataSource={freeCryptoList}
                        />
                }
                <div className="portfolio-add-wallet-modal__bottom">
                    <Button
                        className={'portfolio-wallet__header__action_item'}
                        type="primary"
                        loading={loadWalletCreate}
                        disabled={!checkedWallet}
                        onClick={() => {
                            handlerAddWallet(checkedWallet)
                            setCheckedWallet(null)
                        }}
                    >
                        Add Wallet
                    </Button>
                </div>
        </Modal>
    )
})

export default AddWalletModal
