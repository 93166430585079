// Type
import { RootState } from 'store'


const cabinetMainSelect = {
    form_errors: (store: RootState) => store.cabinet.form_errors,
    menu_mode: (store: RootState) => store.cabinet.menu_mode,
    sider_mode: (store: RootState) => store.cabinet.sider_mode,
    auto_logout: (store: RootState) => store.cabinet.auto_logout,
    currency_list_crypto: (store: RootState) => store.cabinet.currency_list_crypto,
    currency_list_fiat: (store: RootState) => store.cabinet.currency_list_fiat,
    currency_favorites: (store: RootState) => store.cabinet.currency_favorites,
    currency_items: (store: RootState) => store.cabinet.currency_items,
    send_asset_mode: (store: RootState) => store.cabinet.send_asset_mode,
    send_assets_data: (store: RootState) => store.cabinet.send_assets_data,
    receive_asset_mode: (store: RootState) => store.cabinet.receive_asset_mode,
    receive_asset_only_qr: (store: RootState) => store.cabinet.receive_asset_only_qr,
    receive_assets_config: (store: RootState) => store.cabinet.receive_assets_config,
    receive_assets_title: (store: RootState) => store.cabinet.receive_assets_title,
    notification_mode: (store: RootState) => store.cabinet.notification_mode,
    favorites_local: (store: RootState) => store.cabinet.favoritesLocal,
    wallets_addresses: (store: RootState) => store.cabinet.wallet_addresses,
    wallets_addresses_mode: (store: RootState) => store.cabinet.wallet_addresses_mode,
    wallet_address_recipient: (store: RootState) => store.cabinet.wallet_address_recipient,
    send_assets_errors: (store: RootState) => store.cabinet.send_assets_errors,
    search_recipient: (store: RootState) => store.cabinet.search_recipient,
    import_address: (store: RootState) => store.cabinet.import_address,
    import_address_errors: (store: RootState) => store.cabinet.import_address_errors,
    export_address: (store: RootState) => store.cabinet.export_address,
    export_address_errors: (store: RootState) => store.cabinet.export_address_errors,
    help_tab: (store: RootState) => store.cabinet.help_tab,
    notifications: (store: RootState) => store.cabinet.notifications,
    notificationsLength: (store: RootState) => store.cabinet.notifications?.length,
    // Load
    load_form: (store: RootState) => store.cabinet.load_form,
    load_currency_list_crypto: (store: RootState) => store.cabinet.load_currency_list_crypto,
    load_currency_list_fiat: (store: RootState) => store.cabinet.load_currency_list_fiat,
    load_wallet_addresses: (store: RootState) => store.cabinet.load_wallet_addresses,
    load_send_assets: (store: RootState) => store.cabinet.load_send_assets,
    load_import_address: (store: RootState) => store.cabinet.load_import_address,
    load_export_address: (store: RootState) => store.cabinet.load_export_address
}

export default cabinetMainSelect
