// Core
import {ChangeEvent, useEffect, useRef, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Selector
import {portfolioSelect} from 'selectors/cabinet'
//Action
import {portfolioActions} from 'actions/cabinet'
// Type
import {Key, SorterResult, TablePaginationConfig} from 'antd/lib/table/interface'
import {TTransactionQueryBase, TTransactionTableSort, TTransactionItem, TTransactionQueryFilter} from 'types/cabinet/transactions'
import {TGetSortParams} from 'types/app-type'
// Utils
import {range} from "utils";


export const useTransactionsEf = () => {
	const dispatch = useDispatch()
	 // transactions table data
	const transactions = useSelector(portfolioSelect.transactions)
	const loadTransactions = useSelector(portfolioSelect.load_transactions)
	const mode = useSelector(portfolioSelect.transactions_filter_mode)
	// filter data
	const transactionsFilter = useSelector(portfolioSelect.transactions_filter)
	const transactionsBaseQuery = useSelector(portfolioSelect.transactions_base_query)
	const transactionsCountFilter = useSelector(portfolioSelect.transactions_count_filter)

	//const loadFirst = useSelector(portfolioSelect.load_first)

	// get mock data for skeleton
    const getMockTransactionsData = (id: string):TTransactionItem  => ({
		id,
		type_id: 'IN',
		sub_type_id: 'sell',
		status: 'WAITING',
		name: '',
		full_name: '',
		icon: '',
		amount: 0,
		value: 0,
		hash: '',
		address_to: '',
		addresses_from: [],
		fee: '',
		fee_value: '',
		description: '',
		created_at: ''
	})

	const emptyTransactions = range(0,9).map(elem => getMockTransactionsData(`${elem}`))

    const getSort = ({order, column}: TGetSortParams<'created_at' | 'name' | 'amount' | 'value'>): TTransactionTableSort | null => {
      if (column) {
          switch (column.key) {
              case "created_at":
                  return order !== undefined
                      ? order === 'ascend'
                          ? 'date'
                          : '-date'
                      : null
              case "name":
                  return order !== undefined
                      ? order === 'ascend'
                          ? 'currency'
                          : '-currency'
                      : null
              case "amount":
                  return order !== undefined
                      ? order === 'ascend'
                          ? 'amount'
                          : '-amount'
                      : null
              case "value":
                  return order !== undefined
                      ? order === 'ascend'
                          ? 'value'
                          : '-value'
                      : null
              default: return null
          }
      } else {
          return null
      }
    }

	// handlers

    const handlerTableChange = (
		pagination: TablePaginationConfig,
		filters: Record<string, Key[] | null>,
		sorter: SorterResult<any> | SorterResult<any>[],
	  ) => {
		const sorterPrepare = sorter as TGetSortParams<'created_at' | 'name' | 'amount' | 'value'>
		const sortParams = getSort(sorterPrepare)

		dispatch(portfolioActions.set_transactions_base_query({
			...transactionsBaseQuery,
			page: pagination.current ? pagination.current : null,
			pageSize: pagination.pageSize !== undefined ? `${pagination.pageSize}` : pagination.pageSize,
			sort: sortParams,
		} ))
	  }

	  const handlerFilter = () => {
		  dispatch(portfolioActions.set_transactions_filter_mode(true))
	  }

	  const handlerCloseFilter = () => {
		  dispatch(portfolioActions.set_transactions_filter_mode(false))
	  }

	  const handlerSubmitFilter = (val: Omit<TTransactionQueryFilter, 'name'>) => {
		  dispatch(portfolioActions.set_transactions_filter(val))
	  }

	  const handlerSearchTransactions = (search: string | null) => {
		  dispatch(portfolioActions.set_transactions_filter({
			  ...transactionsFilter,
			  name: search,
		  }))
	  }

	  const handlerSetFilterCount = (count: number) => {
		  dispatch(portfolioActions.set_transactions_filter_count(count))
	  }

	  const handlerChangeFilterCount = (type: 'inc' | 'dec') => {
		  dispatch(
			  type.toLowerCase() === 'inc'
			  ? portfolioActions.add_transactions_count_filter(1)
			  : portfolioActions.delete_transactions_count_filter(1))
	  }




	// const timer = useRef<any>(null)

	// const [searchAction, setSearchAction] = useState(false)
	// const [search, setSearch] = useState(transactionsFilter.name)

	// const getSort = ({order, column}: TGetSortParams<'created_at' | 'name' | 'amount' | 'value'>): TTransactionTableSort | null => {
	// 	if (column) {
	// 		switch (column.key) {
	// 			case "created_at":
	// 				return order !== undefined
	// 					? order === 'ascend'
	// 						? 'date'
	// 						: '-date'
	// 					: null
	// 			case "name":
	// 				return order !== undefined
	// 					? order === 'ascend'
	// 						? 'currency'
	// 						: '-currency'
	// 					: null
	// 			case "amount":
	// 				return order !== undefined
	// 					? order === 'ascend'
	// 						? 'amount'
	// 						: '-amount'
	// 					: null
	// 			case "value":
	// 				return order !== undefined
	// 					? order === 'ascend'
	// 						? 'value'
	// 						: '-value'
	// 					: null
	// 			default: return null
	// 		}
	// 	} else {
	// 		return null
	// 	}
	// }

	// const handlerFilter = () => {
	// 	dispatch(portfolioActions.set_transactions_filter_mode(true))
	// }

	// const handlerTableSearch = () => {
	// 	clearTimeout(timer.current)
	// 	setSearchAction(false)

	// 	timer.current = setTimeout(() => {
	// 		dispatch(portfolioActions.set_transactions_filter({
	// 			...transactionsFilter,
	// 			name: search,
	// 		}))
	// 	}, 500)
	// }

	// const handlerSearch = (e: ChangeEvent<HTMLInputElement>) => {
	// 	setSearch(e.target.value.length ? e.target.value : null)
	// 	setSearchAction(true)
	// }

	// const handlerTableChange = (
	// 	pagination: TablePaginationConfig,
	// 	filters: Record<string, Key[] | null>,
	// 	sorter: SorterResult<any> | SorterResult<any>[],
	// ) => {
	// 	const sorterPrepare = sorter as TGetSortParams<'created_at' | 'name' | 'amount' | 'value'>
	// 	const sortParams = getSort(sorterPrepare)

	// 	dispatch(portfolioActions.set_transactions_base_query({
	// 		...transactionsBaseQuery,
	// 		page: pagination.current ? pagination.current : null,
	// 		pageSize: pagination.pageSize !== undefined ? `${pagination.pageSize}` : pagination.pageSize,
	// 		sort: sortParams,
	// 	} ))
	// }

	// useEffect(() => {
	// 	searchAction && handlerTableSearch()
	// }, [searchAction])


	return {
		transactions, loadTransactions, transactionsFilter,
		transactionsBaseQuery, transactionsCountFilter, mode,
		emptyTransactions, handlerTableChange, handlerFilter, handlerCloseFilter, handlerSubmitFilter,
		handlerSearchTransactions, handlerSetFilterCount, handlerChangeFilterCount
	}
}
