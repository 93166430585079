// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button, Button as ButtonAnt, Empty, Image, Input, Modal, Spin} from 'antd'
// Ant Icon
import {LoadingOutlined, SearchOutlined} from '@ant-design/icons'
// Style
import './SelectRecipient.scss'
import 'react-perfect-scrollbar/dist/css/styles.css';
// Hook
import {useSelectRecipientEf} from './useSelectRecipientEf'
import closeImage from "../../../../../../assets/img/close_action.png";
import {Typography} from "../../../../../../elements";
import PerfectScrollbar from 'react-perfect-scrollbar'
import {NodeError} from "#/elements";
import {ErrorMessage} from "formik";


type PropsType = {
    children?: never,
}

const SelectRecipient: FC<PropsType> = memo(() => {
    const {
        mode, walletsAddresses, loadSearch, searchRecpient,
        handlerSearch, handlerCloseModal, handlerChooseAddress,
        handlerOnScrollAddresses
    } = useSelectRecipientEf()

    return <>
        <Modal
            title={'Select Recipient'}
            centered
            visible={mode}
            onCancel={handlerCloseModal}
            className={'select-recipient__modal'}
            width={450}
            footer={false}
        >
            <Input
                placeholder="Search Assets name"
                prefix={
                    loadSearch
                        ? <Spin indicator={<LoadingOutlined className={'profile__addresses__header__action_load'} style={{fontSize: 18}} spin />} />
                        : <SearchOutlined />
                }
                style={{width: 230}}
                className={'input-item'}
                size={'middle'}
                onChange={handlerSearch}
                value={searchRecpient}
                disabled={loadSearch}
                allowClear={true}
            />
                <PerfectScrollbar onYReachEnd={handlerOnScrollAddresses}>
                    <ul style={{height: 300}} className={'select-recipient__list'}>
                    {
                        walletsAddresses?.items && walletsAddresses.items.length ?
                            walletsAddresses.items.map(item =>
                                <li key={item.id} className={'select-recipient__list-item'}>
                                    <button className={'select-recipient__list-button'} onClick={() => handlerChooseAddress(item.wallet_address)}>
                                        <h3 className={'select-recipient__list-title'}>{item.name}</h3>
                                        <p className={'select-recipient__list-text'}>Wallet address: <span className={'select-recipient__list-address'}>{item.wallet_address}</span></p>
                                    </button>
                                </li>
                            )
                            :
                            <NodeError errorImage={<Empty/>} description={''}/>
                    }
                    </ul>
                </PerfectScrollbar>
            <Button className={'default secondary select-recipient__button'} onClick={() => handlerCloseModal()}>
                Go back
            </Button>
        </Modal>
    </>
})

export default SelectRecipient
