// Core
import React, {FC, memo} from 'react'
import {NavLink} from 'react-router-dom'
// Ant Components
import {Button, Image, Table, Card, Skeleton} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
// Hook
import {useYourPortfolioEf} from './useYourPortfolioEf'
import {Typography} from "elements";
import {IWalletDataItem} from "types/cabinet/settings-type";
import {config} from "utils";
import {TPortfolioTable} from "types/cabinet/cabinet-main-type";
// Components
import {NodeError} from "#/elements";



type PropsType = {
    children?: never
}

const YourPortfolio: FC<PropsType> = memo(() => {
    const { loadPortfolio, portfolioData, baseFiatSign, parseCurrency, emptyPortfolioData, handlerAllAssets } = useYourPortfolioEf()

    const { Column } = Table;

    return <Card className={'home-card home-card_portfolio'}>
        <div className={'home-card__header'}>
            <h2 className="cab-home__title">Your Portfolio</h2>
            <Button type="link" className={'ant-btn-default'} onClick={() => handlerAllAssets()}>All Assets</Button>
        </div>
        <div className={'home-card__body'}>
            {
                !loadPortfolio ?
                    portfolioData ?
                        <Table
                            dataSource={portfolioData.length > 5 ? portfolioData.slice(0,5) : portfolioData}
                            pagination={false}
                            rowKey="id"
                            className={'base-table base-table__portfolio'}
                        >
                            <Column
                                title={'Assets Name'}
                                dataIndex={'name'}
                                key={'name'}
                                fixed={'left'}
                                width={160}
                                render={ (name: string, row: TPortfolioTable) => (
                                    <div className={'base-table__icon-wrap'}>
                                        <Image src={config.app.apiURL+row.icon} preview={false} className={'base-table__icon'} />
                                        {name}
                                    </div>
                                )}
                            />
                            <Column
                                title={'Balance'}
                                dataIndex={'balance'}
                                key={'balance'}
                                fixed={true}
                                render={(value, row: TPortfolioTable) => value+ ' ' + row.name}
                            />
                            <Column
                                title={`Value, ${baseFiatSign}`}
                                dataIndex={'value'}
                                key={'value'}
                                fixed={true}
                                render={(value) => parseCurrency(value).integer()+parseCurrency(value).fractional()}
                            />
                            <Column
                                title={`Portfolio, %`}
                                dataIndex={'percent'}
                                key={'percent'}
                                fixed={true}
                                render={(value) => value + ' %'}
                            />
                        </Table>
                    :
                        <NodeError className={'home-card__error'} />
                :
                    <Table
                        dataSource={emptyPortfolioData}
                        rowKey="id"
                        pagination={false}
                        className={'base-table base-table__portfolio'}
                    >
                        <Column
                            title={'Assets Name'}
                            fixed={'left'}
                            width={160}
                            render={() => <Skeleton.Input active={true} style={{width: 60, height: 24}} size={'small'} />}
                        />
                        <Column
                            title={'Balance'}
                            fixed={true}
                            render={() => <Skeleton.Input active={true} style={{width: 60, height: 24}} size={'small'} />}
                        />
                        <Column
                            title={`Value, ${baseFiatSign}`}
                            fixed={true}
                            render={() => <Skeleton.Input active={true} style={{width: 60, height: 24}} size={'small'} />}
                        />
                        <Column
                            title={`Portfolio, %`}
                            fixed={true}
                            render={() => <Skeleton.Input active={true} style={{width: 60, height: 24}} size={'small'} />}
                        />
                    </Table>
            }
        </div>
    </Card>
})

export default YourPortfolio
