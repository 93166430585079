import {strict} from "assert";
import {string} from "yup";
import {TwoFaAuth} from "../auth-type";
import {TTwoFaType} from "./settings-type";

export interface Mock {
    name: string
}

export type MenuList = 'home' | 'portfolio' | 'transactions' | 'help' | 'setting' | 'profile'

export type ModalAssets = 'send' | 'receive' | 'def'

export type TCurrencyListType = 'crypto' | 'fiat'


export type TCurrencyList = {
    id: string
    name: string
    full_name: string,
    sign?: string
    icon?: string
}

export interface ICurrencyItem {
    id: string
    name: string
    full_name: string,
    sign?: string
    icon?: string
}

export enum EWalletDuration  {
    // MINUTE = '1m',
    HOUR = '1h',
    DAY = '1D',
    WEEK = '1W',
    MOUNTH = '1M',
    YEAR = '1Y',
    ALL_TIME = 'all'
}

export type TDuration = EWalletDuration.HOUR | EWalletDuration.DAY | EWalletDuration.WEEK | EWalletDuration.MOUNTH | EWalletDuration.YEAR | EWalletDuration.ALL_TIME

export type TDurationConfig = {
    name: string,
    value: TDuration
}

export type TChart = {
    xAxis: number,
    xAxisFormatted: string,
    yAxis: number
}

export type TOverview = {
    current_price: number,
    percent_change:number,
    market_cap: number,
    volume: number,
    high_price: number,
    low_price: number
}

export type TPortfolioTableFormatted = {
    id: string,
    name: string,
    balance: string,
    value: string,
    icon: string,
    percent: number
}

export type TPortfolioTable = {
    id: string,
    cur_id: string,
    name: string,
    full_name: string,
    balance: string,
    value: string,
    price: string,
    percent_change: number,
    in_favorites: number,
    percent: number,
    icon: string
}

export type TFavoritesItem = {
    id: number | string,
    name: string,
    icon: string,
    full_name: string,
    price: number,
    percent_change: number,
    market_cap: number
}

export interface IFavoritesItem extends ICurrencyItem {
    price: number,
    percent_change: number,
    market_cap?: number,
    wallet_exists?: number
}

export interface IWalletItem extends IFavoritesItem{
    disabled: boolean
}

export type TFavoritesRequestData = {
    favorites?: TFavoritesItem[],
    items?: TFavoritesItem[]
}

export type TUpdateFavorites = {
    currencyId: string
}

export type TGetWalletAddresses = {
    page?: number,
    perPage?: number,
    sort?: string,
    name?: string,
    full_name?: string
}

type TWalletAddressItem = {
    id: string,
    name: string,
    wallet_address: string,
    full_name: string,
    currency_id: string,
    icon: string
}

export type TWalletAddresses = {
    items: TWalletAddressItem[],
    pagination: {
        links: {
            self: string,
            first: string,
            last: string,
            next: string
        },
        shownFrom: number,
        shownTo: number,
        totalCount: number,
        pageCount: number,
        pageSize: number,
        pageParam: string,
        page: number
    }
}


export interface ITransactionsSendData {
    step?: string,
    transaction_id?: string,
    hash?: string
}

export interface ITransactionsSend  {
    wallet_id: string,
    wallet_currency_id: string,
    address: string,
    address_to: string,
    amount: string,
    tax_from: 'sender' | 'receiver',
    notes?: string,
    password?: string,
    amount_fiat: string | number | null,
    crypto_name:string,
    fiat_sign: string
}

export interface ITransactionsSendAssets extends ITransactionsSendData, ITransactionsSend {

}

export type TReceiveAssetsConfig = {
    showSelector: boolean,
    address: string,
    handlerClose?: () => void
}

export type TImportAddress = {
    wallet_id: string,
    private_key: string,
    address: string,
    description?: string
}

export enum EExportType {
    DEFAULT,
    EMAIL,
    GOOGLE,
    FINAL
}

export type TExportConfirmData = {
    hash: string,
    method: string
} | null

export type TExportFinalData = {
    address: string,
    private_key: string
} | null

export interface ISetExportAddress {
    type: EExportType,
    address: string,
    password?: string,
    confirmData?: TExportConfirmData,
    finalData?: TExportFinalData
}

export interface IExportAddressData{
    mode: boolean,
    config: ISetExportAddress | null
}

export type TExportAddressKeys = {
    title: string,
    value: string
}

export type THelp = {
    id: string | number
    title: string,
    tab: string,
}

export type TNotification = {
    id: string | number,
    type: string,
    status: boolean,
    description: string
}

export type TExportAddress = {
    address: string,
    password: string,
    code?: string,
    hash?: string
}

export type TExportAddressData = {
    address: string,
    private_key: string
}

export type TExportViaMethod = {
    code: string
}
