import {WorkerProps} from "../../root-saga";
import {makeRequest} from "../../template";
// Types
import {TTransactionData, TTransactionQueryBase, TTransactionQueryFilter} from "types/cabinet/transactions";
// Actions
import {transactionsActions} from "../../../actions/cabinet";
// Api
import {transactionApi} from "../../../api/cabinet";

export function* workerTransactions({payload}: WorkerProps<{id?: string, query: TTransactionQueryBase, filter: TTransactionQueryFilter}>): Generator {
    //const loadFirst = (yield select(portfolioSelect.load_first)) as TLoadFirst[]

    // const prepareFinish = [
    //     {action: portfolioActions.load_transactions, handler: () => false},
    //     {action: portfolioActions.load_first, handler: () => 'transactions'},
    // ]

    yield makeRequest({
        // @ts-ignore
        fetcher: transactionApi.list,
        fetcherParam: [
            payload.query,
            {
                ...payload.filter,
                duration: payload.filter.duration && ['all', 'custom'].includes(payload.filter.duration) ? null :  payload.filter.duration,
            },
            payload.id
        ],
        start: [
            {action: transactionsActions.set_load_transactions, handler: () => true},
        ],
        finish: [
            {action: transactionsActions.set_load_transactions, handler: () => false},
        ],
        success: [
            {action: transactionsActions.set_transactions, handler: (data: TTransactionData) => ({...data})},
        ],
    })
}
