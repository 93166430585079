// Core
import {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {RadioChangeEvent} from "antd/es/radio";
import {TExportAddressKeys} from "../../../../../../types/cabinet/cabinet-main-type";
// Libs
var wif = require('wif');

type TExportFinalEf = {
    private_key: string
}

export const useExportFinalEf = ({private_key}: TExportFinalEf) => {
    const dispatch = useDispatch()


    // State

    const [key, setKey] = useState<string>(private_key)
    const [keys, setKeys] = useState<null | TExportAddressKeys[]>(null)


    // Effects

    useEffect(() => {
        private_key.length && prepareKeys(private_key)
    },[private_key])


    // Handlers

    const prepareKeys = (key: string) => {
        const keys = [
            {
                title: 'Hex',
                value: key
            },
            // {
            //     title: 'WIF',
            //     value: 'wif' + key
            // },
            // {
            //     title: 'WIF-compressed',
            //     value: 'wfc' + key
            // }
        ]

        setKeys(keys)
    }

    const handlerKeysChange = (e: RadioChangeEvent) => {
        setKey(e.target.value)
    }

    return {key, keys, handlerKeysChange}
}
