// Core
import produce, {Draft} from 'immer'
import {transactionsReducerType} from "actions/cabinet/transactions-actions";
// Types
import {
    TTransactionData,
    TTransactionQueryBase,
    TTransactionQueryFilter,
    TTransactionsTable
} from 'types/cabinet/transactions'


const initialState = {
    transactions_filter_mode: false,
    transactions: null as TTransactionData | null,
    transactions_count_filter: 0,
    transactions_base_query: {
        page: 1,
        pageSize: '10',
        sort: null,
    } as TTransactionQueryBase,
    transactions_filter: {
        name: null,
        duration: null,
        dateFrom: null,
        dateTo: null,
        type: null,
        status: null,
        currency: [],
    } as TTransactionQueryFilter,
    load_transactions: false
}

type InitialStateType = typeof initialState

export const transactionsReducer = (state: InitialStateType = initialState, action: transactionsReducerType): InitialStateType => {
    return produce(state, (draft: Draft<InitialStateType>) => {
        switch (action.type) {
            // Async
            case "TRANSACTIONS__WATCH_TRANSACTIONS": return state
            // Sync
            case "TRANSACTIONS__SET_TRANSACTIONS":
                draft.transactions = action.payload.data
                break
            case "TRANSACTIONS__SET_LOAD":
                draft.load_transactions = action.payload.mode
                break
            case "TRANSACTIONS__SET_FILTER":
                draft.transactions_filter = {...draft.transactions_filter, ...action.payload.data}
                break
            case "TRANSACTIONS__SET_FILTER_MODE":
                draft.transactions_filter_mode = action.payload.mode
                break
            case "TRANSACTIONS__ADD_FILTER_COUNT":
                if (typeof action.payload.count !== 'number') return state
                draft.transactions_count_filter = draft.transactions_count_filter + action.payload.count
                break
            case "TRANSACTIONS__DELETE_FILTER_COUNT":
                if (typeof action.payload.count !== 'number') return state
                const number = draft.transactions_count_filter - action.payload.count
                draft.transactions_count_filter = number < 0 ? 0 : number
                break
            case "TRANSACTIONS__SET_BASE_QUERY":
                draft.transactions_base_query = {...draft.transactions_base_query, ...action.payload.data}
                break
            case "TRANSACTIONS__SET_FILTER_COUNT":
                draft.transactions_count_filter = action.payload.count
                break
            // Def
            default:
                const allAction:never = action // check use all action
                return state
        }
    })
}
