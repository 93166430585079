// Core
import {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux'
// Actions
import { settingsCabinetAction } from 'actions/cabinet'
// Selectors
import {settingsCabinetSelect} from 'selectors/cabinet'
// Types
import { TSettings, TUpdateSettings} from 'types/cabinet/settings-type'


export const useNotificationsEf = () => {
    const dispatch = useDispatch()
    const settings: TSettings = useSelector(settingsCabinetSelect.settings)
    const loadSettings: Boolean = useSelector(settingsCabinetSelect.load_settings)

    const [cryptoSelected, setCryptoSelected] = useState<boolean | null>()
    const [infoSelected, setInfoSelected] = useState<boolean | null>()
    const [emailSelected, setEmailSelected] = useState<boolean | null>()
    const [pushSelected, setPushSelected] = useState<boolean | null>()

    useEffect(() => {
        settings && settings.notif_currency !== null &&
            setCryptoSelected(settings.notif_currency.toString() === '1' ? true : false)

        settings && settings.notif_info !== null &&
            setInfoSelected(settings.notif_info.toString() === '1' ? true : false)

        settings && settings.notification_email !== null &&
            setEmailSelected(settings.notification_email.toString() === '1' ? true : false)

        settings && settings.notification_push !== null &&
            setPushSelected(settings?.notification_push.toString() === '1' ? true : false)
    },[settings])

    // update settings
    type TUpdateData = {
        crypto?: boolean,
        info?: boolean,
        email?: boolean,
        push?: boolean,
    }
    function updateSettings(updateData:TUpdateData):void{
        const { crypto, info, email, push } = updateData

        const data: TUpdateSettings = {
            currency_id: settings.currency_id,
            lang: settings.lang,
            show_alert: false
        }

        crypto !== undefined && (crypto === true ? data['notif_currency'] = '1' : data['notif_currency'] = '0')
        info !== undefined && (info === true ? data['notif_info'] = '1' : data['notif_info'] = '0')
        email !== undefined && (email === true ? data['notification_email'] = '1' : data['notification_email'] = '0')
        push !== undefined && (push === true ? data['notification_push'] = '1' : data['notification_push'] = '0')

        dispatch(settingsCabinetAction.update_settings({...data}))
    }

    function onChangeCrypto(value: boolean):void {
        setCryptoSelected(value)
        updateSettings({crypto:value})
    }

    function onChangeInfo(value: boolean):void {
        setInfoSelected(value)
        updateSettings({info:value})
    }

    function onChangeEmail(value: boolean):void {
        setEmailSelected(value)
        updateSettings({email: value})
    }

    function onChangePush(value: boolean):void {
        setPushSelected(value)
        updateSettings({push: value})
    }

    function handleRequestData(): void {
        dispatch(settingsCabinetAction.watch_settings_base())
    }


    return {
        cryptoSelected, infoSelected, loadSettings, emailSelected, pushSelected,
        onChangeCrypto, onChangeInfo, onChangeEmail, onChangePush, handleRequestData
    }
}
