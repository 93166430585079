// Core
import {useSelector, useDispatch} from 'react-redux'
import {cabinetMainSelect, settingsCabinetSelect} from "selectors/cabinet"
// Action
import {cabinetMainActions} from "actions/cabinet"
import {mainSelect} from "../../../../selectors";
import {useEffect, useState} from "react";
import {mainActions} from "../../../../actions";


export const useHeaderHelpEf = () => {
    const dispatch = useDispatch()


    // Selectors

    const tabs = useSelector(mainSelect.faq_categories)
    const tab = useSelector(cabinetMainSelect.help_tab)


    // Effects

    useEffect(() => {
        dispatch(mainActions.watch_faq())
    },[dispatch])

    useEffect(() => {
        tabs && dispatch(cabinetMainActions.set_help_tab(tabs[0]))
    },[tabs])


    // Handlers

    const handlerTab = (value: string) => {
        dispatch(cabinetMainActions.set_help_tab(value))
    }

    return {
        tab, tabs, handlerTab
    }
}
