// Core
import {useSelector} from 'react-redux'
import {useEffect} from "react"
// Selector
import {appSelect, authSelect} from 'selectors'


export const useCheckEmailEf = () => {
    const successSignup = useSelector(authSelect.success_signup)
    const isAuth = useSelector(authSelect.is_auth)
    const notFoundRedirect = useSelector(appSelect.not_found_redirect)
    const lang = useSelector(appSelect.language)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [window])

    return {
        successSignup, isAuth, notFoundRedirect, lang,
    }
}
