// store
import {ActionsCreatorType} from "store";
// Types
import {
    TTransactionData,
    TTransactionQueryBase,
    TTransactionQueryFilter,
    TWatchTransactions
} from "types/cabinet/transactions";


// Async
export const TRANSACTIONS__WATCH_TRANSACTIONS = 'TRANSACTIONS__WATCH_TRANSACTIONS'
// Sync
export const TRANSACTIONS__SET_TRANSACTIONS = 'TRANSACTIONS__SET_TRANSACTIONS'
export const TRANSACTIONS__SET_FILTER = 'TRANSACTIONS__SET_FILTER'
export const TRANSACTIONS__SET_FILTER_MODE = 'TRANSACTIONS__SET_FILTER_MODE'
export const TRANSACTIONS__ADD_FILTER_COUNT = 'TRANSACTIONS__ADD_FILTER_COUNT'
export const TRANSACTIONS__DELETE_FILTER_COUNT = 'TRANSACTIONS__DELETE_FILTER_COUNT'
export const TRANSACTIONS__SET_FILTER_COUNT = 'TRANSACTIONS__SET_FILTER_COUNT'
export const TRANSACTIONS__SET_BASE_QUERY = 'TRANSACTIONS__SET_BASE_QUERY'
export const TRANSACTIONS__SET_LOAD = 'TRANSACTIONS__SET_LOAD'



const transactionsActions = {
    // Async
    watch_transactions: ({id, query, filter}:TWatchTransactions) => ({ type: TRANSACTIONS__WATCH_TRANSACTIONS, payload: {id, query, filter}} as const),
    // Sync
    set_transactions:(data: TTransactionData) => ({type: TRANSACTIONS__SET_TRANSACTIONS, payload: {data}} as const),
    set_transactions_filter_mode:(mode: boolean) => ({type: TRANSACTIONS__SET_FILTER_MODE, payload: {mode}} as const),
    set_transactions_base_query:(data: TTransactionQueryBase) => ({type: TRANSACTIONS__SET_BASE_QUERY, payload: {data}} as const),
    set_transactions_filter:(data: TTransactionQueryFilter) => ({type: TRANSACTIONS__SET_FILTER, payload: {data}} as const),
    set_load_transactions:(mode: boolean) => ({type: TRANSACTIONS__SET_LOAD, payload: {mode}} as const),
    add_transactions_count_filter:(count: number) => ({type: TRANSACTIONS__ADD_FILTER_COUNT, payload: {count}} as const),
    delete_transactions_count_filter:(count: number) => ({type: TRANSACTIONS__DELETE_FILTER_COUNT, payload: {count}} as const),
    set_transactions_filter_count: (count: number) => ({type: TRANSACTIONS__SET_FILTER_COUNT, payload: {count}} as const)
}


export type transactionsReducerType = ActionsCreatorType<typeof transactionsActions>

export default transactionsActions
