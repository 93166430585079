const filters = {
    toUrlFriendly: function (text: string):string {
        return text && text.replace(/\s+/g,"-").toLowerCase();
    },
    hideMiddleString: function (text: string, separator: string = '...') {
        const deltaCut = 10
        const middleText = text.substr( (text.length / 2) - deltaCut, deltaCut * 2)

        return text.replace(middleText, separator)
    },
    cutWithSeparator: function (text: string, length: number = 30, separator: string = '...'){

        return text ? text.substr(0, length) + separator : ''
    }
}

export default filters
