// Core
import React, {FC, memo} from 'react'
import clsx from 'clsx'
// Ant Components
import {Skeleton} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
// Style
import './HeaderPortfolioInfo.scss'
// Hook
import {useHeaderPortfolioInfoEf} from './useHeaderPortfolioInfoEf'
import {useParseToCurrency} from 'hooks'
// Element
import {Typography} from 'elements'


type PropsType = {
	children?: never
}

const HeaderPortfolioInfo: FC<PropsType> = memo(() => {
	const {
		loadCurrencyDetail, currencyDetailsData
	} = useHeaderPortfolioInfoEf()

	const parseValue = useParseToCurrency(currencyDetailsData?.base_fiat_sign)

	return (
		<div className={'header-profile-info'}>
			<div className={'header-profile-info_item'}>
				<Typography className={'header-profile-info_item_title'} type={"text-16"} color={"dark-gray"}>
					Balance
				</Typography>
				<Typography className={'overview__value'} type={"text-22"} color={"primary"} weight={"regular"}>
					{
						currencyDetailsData && !loadCurrencyDetail
							? `${currencyDetailsData.balance} ${currencyDetailsData.name}`
							: <Skeleton.Button active={true} size={'small'} shape={'square'} />
					}
				</Typography>
			</div>
			<div className={'header-profile-info_item'}>
				<Typography className={'header-profile-info_item_title'} type={"text-16"} color={"dark-gray"}>
					Value
				</Typography>
				<Typography
					type={"text-22"}
					color={"primary"}
					weight={"regular"}
					className={'overview__value'}
				>
					{
						currencyDetailsData && !loadCurrencyDetail
							? (<>
								{ parseValue(currencyDetailsData?.value).integer()}
								<span>{ parseValue(currencyDetailsData?.value).fractional()}</span>
							</>)
							: <Skeleton.Button active={true} size={'small'} shape={'square'} />
					}
				</Typography>
			</div>
			<div className={'header-profile-info_item'}>
				<Typography className={'header-profile-info_item_title'} type={"text-16"} color={"dark-gray"} >
					Portfolio, %
				</Typography>
				<Typography className={'overview__value'} type={"text-22"} color={"primary"} weight={"regular"}>
					{
						currencyDetailsData && !loadCurrencyDetail
							? parseValue(currencyDetailsData?.percent).full() + '%'
							: <Skeleton.Button active={true} size={'small'} shape={'square'} />
					}
				</Typography>
			</div>
		</div>
	)
})

export default HeaderPortfolioInfo
