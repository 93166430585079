// Core
import React, {FC, memo} from 'react'
import clsx from 'clsx'
// Ant Components
import {} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'



type PropsType = {
    children?: never
}

const TransactionsType: FC<PropsType> = memo((props) => {
    return (
        <svg className={clsx('transactions-type')} width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.44187 8.1528L8.95841 0.555582C8.96311 0.48687 8.95299 0.417865 8.92872 0.353242C8.90445 0.288619 8.86661 0.229888 8.81775 0.181029C8.76889 0.13217 8.71016 0.0943241 8.64553 0.0700558C8.58091 0.0457878 8.51191 0.0356641 8.44319 0.040371L0.84704 0.557971C0.824117 0.559504 0.802155 0.567597 0.783771 0.581284C0.765386 0.594973 0.751354 0.613679 0.743346 0.635176C0.735337 0.656673 0.73369 0.680054 0.738599 0.702535C0.74351 0.725015 0.75477 0.745648 0.77104 0.761974L1.63506 1.62599C1.68413 1.67506 1.75227 1.70078 1.82246 1.69673L6.83339 1.35576L0.861035 7.32812C0.814368 7.37479 0.814586 7.45137 0.86152 7.49831L1.50153 8.13832C1.54847 8.18526 1.62505 8.18548 1.67172 8.13881L7.64408 2.16645L7.30312 7.17738C7.29799 7.2465 7.32372 7.31465 7.37385 7.36479L8.23787 8.2288C8.31041 8.30134 8.43472 8.25596 8.44187 8.1528Z" fill="#7A7790"/>
        </svg>
    )
})

export default TransactionsType
