import {SagaIterator} from "redux-saga";
import {all, fork, takeEvery} from "redux-saga/effects";
import {workerTransactions} from "../transactions/worker-saga";
// Actions
import {TRANSACTIONS__WATCH_TRANSACTIONS} from "actions/cabinet/transactions-actions";

function* watchTransactions(): SagaIterator {
    yield takeEvery(TRANSACTIONS__WATCH_TRANSACTIONS, workerTransactions)
}

export default function* transactionsSagaWatcher(): SagaIterator {
    yield all([
        fork(watchTransactions),
    ])
}
