// Core
import React, {FC, memo, useEffect} from 'react'
import {Formik, useFormikContext} from "formik";
import * as Yup from "yup";
// Ant Components
import {Button as ButtonAnt, Image, Modal, Select, Radio, Tooltip} from 'antd'
import {Form, Input} from "formik-antd"
// Ant Icon
import {LoadingOutlined, QrcodeOutlined, BookOutlined, InfoCircleOutlined} from '@ant-design/icons'
import closeImage from "assets/img/close_action.png";
// Style
import './SendAssetsModal.scss'
// Hook
import {useSendAssetsModalEf} from './useSendAssetsModalEf'
// Components
import SelectRecipient from './components/select-recipient/SelectRecipient'
// Elements
import {ComingSoon, Typography, Button} from "elements"
// Utils
import {config} from "utils";
import {FormikHandler} from "../../../../components";
import errorImage from "../../../../assets/img/illustration_delete.png";
import SendAssetsConfirm from "#/components/send-assets-modal/components/send-assets-confirm/SendAssetsConfirm";
import SuccessTransactions from "#/components/send-assets-modal/components/success-transactions/SuccessTransactions";
import SavedAddressesModalForm from "#/components/saved-addresses-modal-form/SavedAddressesModalForm";


type PropsType = {
    children?: never
}

const SendAssetsScheme = Yup.object().shape({
    amount: Yup.string()
        .nullable()
        .required('Required'),
    address_to: Yup.string()
        .required('Required'),
    notes: Yup.string()
})

const SendAssetsModal: FC<PropsType> = memo(() => {
    const {
        sendAssetsMode, selected, wallets, wallet, closerModal, transactionsType, resetForm, formErrors, loadSendAssets,
        transactionsFee, transactionsInfo, transactionsAmount, transactionsErrors, walletAddressRecipient, loadRecipients,
        recipientModal, handlerOpenRecipientModal, handlerCloseRecipientModal,
        handlerClose, handlerSelectChange, handlerSubmitSendAssets, handlerAmountChange,
        handlerCloseCloserModal, handlerFullCloseAssets, handlerSendAll, handlerChangeTransactionType
    } = useSendAssetsModalEf()

    const { Option } = Select;

    const init = {
        amount: 0,
        amount_fiat: 0.00,
        address_to: '',
        notes: '',
    }


    const CalculateAmount = () => {

        const formik = useFormikContext()


        // Effects

        // watch amount fields and update in formik
        useEffect(() => {
            // set formik values
            formik.setFieldValue('amount', transactionsAmount.amount || 0)
            formik.setFieldValue('amount_fiat', transactionsAmount.amount_fiat || 0)

        },[transactionsAmount])

        // update address_to field in formik
        useEffect(() => {
            walletAddressRecipient && formik.setFieldValue('address_to', walletAddressRecipient)
        }, [walletAddressRecipient])


        useEffect(() => {
            resetForm && formik.resetForm()
        },[resetForm])

        useEffect(() => {
            // set formik errors
            transactionsErrors.amount && formik.setFieldError('amount', transactionsErrors.amount.toString())
            transactionsErrors.amount_fiat && formik.setFieldError('amount_fiat', transactionsErrors.amount_fiat.toString())
        },[transactionsErrors])

        return <></>
    }

    return (
        <>
            <Modal
                title={'Send Assets'}
                centered
                visible={sendAssetsMode}
                onCancel={handlerClose}
                width={450}
                footer={false}
            >
                <div className={'receive-assets__wrap'}>
                    <span className={'receive-assets__text'}>From</span>
                    <Select
                        placeholder="Select assets"
                        optionFilterProp="children"
                        onChange={handlerSelectChange}
                        value={selected}
                        className={'receive-assets__select'}
                        id={'select-currency'}
                    >
                        {
                            wallets && wallets.items.map((item, key) =>
                                <Option value={item.id} key={item.id}>
                                    <Image src={config.app.apiURL+item.icon} preview={false} className={'base-table__icon price-chart__icon'} />
                                    {item.name}
                                </Option>
                            )
                        }
                    </Select>
                    {
                        wallet &&
                        <div className={'send-assets__balance'}>
                            <p className={'send-assets__text'}>{wallet.full_name} wallet balance: <span className={'send-assets__text-value'}>{wallet.balance + ' ' + wallet.name}</span></p>
                            <ButtonAnt type="link" className={'ant-btn-default'} onClick={handlerSendAll}>Send all</ButtonAnt>
                        </div>
                    }
                    <Formik onSubmit={handlerSubmitSendAssets} initialValues={init} validationSchema={SendAssetsScheme} autoComplete={'off'}>
                        {({errors, values, touched,setFieldValue}) => (
                            <Form>
                                <div className={'send-assets__amount-row'}>
                                    <Form.Item
                                        name={'amount'}
                                        label={'Amount'}
                                        labelCol={{span: 24}}
                                        wrapperCol={{span: 24}}
                                    >
                                        <Input
                                            type={'number'}
                                            className={'input-item medium send-assets__input-amount'}
                                            size={'middle'}
                                            name='amount'
                                            placeholder={'Amount'}
                                            disabled={!wallet}
                                            step=".00000001"
                                            min={0}
                                            onChange={handlerAmountChange}
                                        />
                                        {
                                            wallet &&
                                                <span className={'send-assets__label'}>{wallet.name}</span>
                                        }
                                    </Form.Item>
                                    <span className={'send-assets__equal'}>=</span>
                                    <Form.Item
                                        name={'amount_fiat'}
                                        label={''}
                                        labelCol={{span: 24}}
                                        wrapperCol={{span: 24}}
                                        className={'send-assets__amount-fiat'}
                                    >
                                        <Input
                                            type={'number'}
                                            className={'input-item medium send-assets__input-amount'}
                                            size={'middle'}
                                            name='amount_fiat'
                                            placeholder={''}
                                            disabled={!wallet}
                                            step=".00000001"
                                            min={0}
                                            onChange={handlerAmountChange}
                                        />
                                        {
                                            wallets &&
                                            <span className={'send-assets__label'}>{wallets.base_fiat_name}</span>
                                        }
                                    </Form.Item>
                                </div>
                                <div className={'send-assets__address-row'}>
                                    <Form.Item
                                        name={'address_to'}
                                        label={'To'}
                                        labelCol={{span: 24}}
                                        wrapperCol={{span: 24}}
                                    >
                                        <Input
                                            className={'input-item medium'}
                                            size={'middle'}
                                            name='address_to'
                                            placeholder={'Paste or scan wallet address '}
                                        />
                                    </Form.Item>
                                    <ButtonAnt
                                        onClick={handlerOpenRecipientModal}
                                        loading={loadRecipients}
                                        type={'default'}
                                        className={'header-profile-action__action__currency__wallet__address__qr send-assets__button'}
                                    >
                                        <BookOutlined />
                                    </ButtonAnt>
                                    {/*<ButtonAnt*/}
                                    {/*    onClick={() => {} }*/}
                                    {/*    type={'default'}*/}
                                    {/*    disabled*/}
                                    {/*    className={'header-profile-action__action__currency__wallet__address__qr send-assets__button'}*/}
                                    {/*>*/}
                                    {/*    <QrcodeOutlined />*/}
                                    {/*</ButtonAnt>*/}
                                </div>
                                <div className={'send-assets__type'}>
                                    <div className={'send-assets__fee'}>
                                        <span className={'send-assets__fee-text'}>Commission payer</span>
                                    </div>
                                    <Radio.Group onChange={handlerChangeTransactionType} value={transactionsType}>
                                        <Radio value={'receiver'}>Recipient
                                            {/*<Tooltip placement="right" title={'Estimated confirmation time 1+ hour'}>*/}
                                            {/*    <button className={'cab-settings__icon'}>*/}
                                            {/*        <InfoCircleOutlined />*/}
                                            {/*    </button>*/}
                                            {/*</Tooltip>*/}
                                        </Radio>
                                        <Radio value={'sender'}>Sender
                                            {/*<Tooltip placement="right" title={'Estimated confirmation time 0-60 minutes'}>*/}
                                            {/*    <button className={'cab-settings__icon'}>*/}
                                            {/*        <InfoCircleOutlined />*/}
                                            {/*    </button>*/}
                                            {/*</Tooltip>*/}
                                        </Radio>
                                    </Radio.Group>
                                </div>
                                <Form.Item
                                    name={'notes'}
                                    label={'Notes'}
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                >
                                    <Input.TextArea
                                        className={'input-item in-field'}
                                        size={'large'}
                                        name='notes'
                                        showCount
                                        maxLength={100}
                                        placeholder={'What’s this transaction for? (optional)'}
                                    />
                                </Form.Item>
                                <Button
                                    className={'send-assets__submit'}
                                    type="submit"
                                    size={"full"}
                                    color={"secondary"}
                                    variant={"contained"}
                                    disabled={wallet?.balance === '0' || values.amount === 0}
                                    loaderPosition={"center"}
                                    loader={
                                        loadSendAssets
                                            ? () => <LoadingOutlined className={'sign__loader-icon'} spin/>
                                            : null
                                    }
                                >
                                    Continue
                                </Button>
                                <CalculateAmount />
                                <FormikHandler data={formErrors} />
                            </Form>
                        )}
                    </Formik>
                </div>
            </Modal>
            <Modal
                title={false}
                centered
                visible={closerModal}
                onCancel={handlerCloseCloserModal}
                className={'cab-settings__closeacc-modal'}
                width={450}
                footer={false}
            >
                <Image width={108} height={108} src={closeImage} preview={false} className={'cab-settings__error-img'}/>
                <Typography type={'title-18'} weight={'semibold'} color={'primary'} className={'cab-settings__closeacc-title'}>Close action?</Typography>
                <Typography type={'text-16'} color={'primary'}>Do you really want to close? The entered data will not be saved </Typography>
                <div className={'cab-settings__closeacc-btns'}>
                    <ButtonAnt type="default" onClick={handlerCloseCloserModal} className={'cab-settings__cancel cab-settings__closeacc-button default'}>
                        Cancel
                    </ButtonAnt>
                    <ButtonAnt type="primary" onClick={handlerFullCloseAssets} danger className={'cab-settings__closeacc-button danger'}>
                        Close
                    </ButtonAnt>
                </div>
            </Modal>
            <SelectRecipient />
            <SendAssetsConfirm/>
            <SuccessTransactions handlerFullClose={handlerFullCloseAssets}/>
        </>
    )
})

export default SendAssetsModal
