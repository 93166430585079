// Type
import {Language} from 'types/app-type'
import {instance, ResponseApiType} from 'api/api'
import {TTransactionQueryBase, TTransactionQueryFilter } from 'types/cabinet/transactions'
// Utils
import {getQueryParams} from 'utils'

const transactionApi = {
	// Load
	list(
		lang: Language,
		token: string,
		queryData = {} as TTransactionQueryBase | object ,
		filterData = {} as TTransactionQueryFilter | object ,
		id?: string,
	) {
		const query = getQueryParams({...queryData, ...filterData}, {val: 'WalletTransactionSearch', ignore: ['sort', 'page', 'pageSize']})

		return instance.get<ResponseApiType>(`transaction/list${id ? `/${id}` : ''}${query}`,{
			headers: {
				'Site-language': lang,
				'accept': 'application/json',
				'Authorization': token,
			}
		}).then(data => data.data)
	},

	// Create

	// Update

	// Delete

}

export default transactionApi