// Core
import React, {CSSProperties, FC, memo} from 'react'
// Style
import './Instagram.scss'


type PropsType = {
    children?: never
    style?: CSSProperties
    className?: string | Object
}

const Instagram: FC<PropsType> = memo(({style, className}) => {

    return (
        <svg style={style} className={`instagram ${className}`} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 0.5H7.5C5.64413 0.502102 3.86487 1.24027 2.55257 2.55257C1.24027 3.86487 0.502102 5.64413 0.5 7.5V18.5C0.502102 20.3559 1.24027 22.1351 2.55257 23.4474C3.86487 24.7597 5.64413 25.4979 7.5 25.5H18.5C20.3559 25.4979 22.1351 24.7597 23.4474 23.4474C24.7597 22.1351 25.4979 20.3559 25.5 18.5V7.5C25.4979 5.64413 24.7597 3.86487 23.4474 2.55257C22.1351 1.24027 20.3559 0.502102 18.5 0.5ZM13 19C11.8133 19 10.6533 18.6481 9.66658 17.9888C8.67988 17.3295 7.91085 16.3925 7.45672 15.2961C7.0026 14.1997 6.88378 12.9933 7.11529 11.8295C7.3468 10.6656 7.91824 9.59647 8.75736 8.75736C9.59647 7.91824 10.6656 7.3468 11.8295 7.11529C12.9933 6.88378 14.1997 7.0026 15.2961 7.45672C16.3925 7.91085 17.3295 8.67988 17.9888 9.66658C18.6481 10.6533 19 11.8133 19 13C18.9982 14.5907 18.3655 16.1158 17.2407 17.2407C16.1158 18.3655 14.5907 18.9982 13 19ZM19.5 8C19.2033 8 18.9133 7.91203 18.6666 7.7472C18.42 7.58238 18.2277 7.34811 18.1142 7.07403C18.0007 6.79994 17.9709 6.49834 18.0288 6.20736C18.0867 5.91639 18.2296 5.64912 18.4393 5.43934C18.6491 5.22956 18.9164 5.0867 19.2074 5.02882C19.4983 4.97094 19.7999 5.00065 20.074 5.11418C20.3481 5.22771 20.5824 5.41997 20.7472 5.66665C20.912 5.91332 21 6.20333 21 6.5C21 6.89782 20.842 7.27936 20.5607 7.56066C20.2794 7.84196 19.8978 8 19.5 8Z" fill="#332E54"/>
        </svg>

    )
})

export default Instagram
