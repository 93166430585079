// Core
import React, {FC, memo} from 'react'
// Ant Components
import {} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
// Style
import './Transactions.scss'
// Hook
import {useTransactionsEf} from './useTransactionsEf'
// Element
import {ComingSoon} from "elements"
import {PageWrapper} from "#/components"
// Components
import {TransactionTable} from "#/components"


type PropsType = {
    children?: never
}

const Transactions: FC<PropsType> = memo(() => {
    const { 
        transactions, loadTransactions, transactionsFilter, 
        transactionsBaseQuery, transactionsCountFilter, mode,
        emptyTransactions, handlerTableChange, handlerFilter, 
        handlerCloseFilter, handlerSubmitFilter, handlerSearchTransactions,
        handlerSetFilterCount, handlerChangeFilterCount
    } = useTransactionsEf()

    return (
        <PageWrapper className="transactions">
            <TransactionTable
                loadTransactions={loadTransactions}
                emptyTransactions={emptyTransactions}
                transactions={transactions}
                transactionsFilter={transactionsFilter}
                transactionsBaseQuery={transactionsBaseQuery}
                transactionsCountFilter={transactionsCountFilter}
                handlerTableChange={handlerTableChange}
                handlerFilter={handlerFilter}
                mode={mode}
                handlerCloseFilter={handlerCloseFilter}
                handlerSubmitFilter={handlerSubmitFilter}
                searchTransactions={handlerSearchTransactions}
                handlerSetFilterCount={handlerSetFilterCount}
                handlerChangeFilterCount={handlerChangeFilterCount}
            />
        </PageWrapper>
    )
})

export default Transactions
