// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button, Input, Spin, Table} from 'antd'
// Ant Icon
import {
	ArrowDownOutlined,
	FilterOutlined,
	LoadingOutlined,
	SearchOutlined,
} from '@ant-design/icons'
// Style
import './Transactions.scss'
// Element
import {Typography} from 'elements'
// Components
import {TransactionTable} from "#/components"
// Hook
import {useTransactionsEf} from './useTransactionsEf'
// Utils
import {config} from 'utils'
// Type
import { TTransactionItem, TTransactionItemStatus } from 'types/cabinet/transactions'


type PropsType = {
	children?: never
}

const Transactions: FC<PropsType> = memo(() => {
	const {
		transactions, loadTransactions, transactionsFilter,
        transactionsBaseQuery, transactionsCountFilter, mode,
        emptyTransactions, handlerTableChange, handlerFilter,
        handlerCloseFilter, handlerSubmitFilter, handlerSearchTransactions,
        handlerSetFilterCount, handlerChangeFilterCount
	} = useTransactionsEf()

	return (<>
		<TransactionTable
                loadTransactions={loadTransactions}
                emptyTransactions={emptyTransactions}
                transactions={transactions}
                transactionsFilter={transactionsFilter}
                transactionsBaseQuery={transactionsBaseQuery}
                transactionsCountFilter={transactionsCountFilter}
				enableSearch={false}
				enableCurrencyFilter={false}
                handlerTableChange={handlerTableChange}
                handlerFilter={handlerFilter}
                mode={mode}
                handlerCloseFilter={handlerCloseFilter}
                handlerSubmitFilter={handlerSubmitFilter}
                searchTransactions={handlerSearchTransactions}
                handlerSetFilterCount={handlerSetFilterCount}
                handlerChangeFilterCount={handlerChangeFilterCount}
            />
	</>)
})

export default Transactions
