// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Collapse} from 'antd'
// Style
import './Help.scss'
// Hook
import {useHelpEf} from './useHelpEf'
import {PageWrapper} from "#/components";
import {Typography} from "../../../../elements";

type PropsType = {
    children?: never
}

const Help: FC<PropsType> = memo(() => {
    const {tab, currentItems} = useHelpEf()

    const { Panel } = Collapse;

    return (
        <PageWrapper>
            <section className={'help'}>
                <div className={'cab-settings__title-wrap'}>
                    <Typography type={'text-16'} weight={'medium'} color={'primary'}>{tab}</Typography>
                </div>
                {
                    currentItems &&
                    <Collapse onChange={() => {}} className={'help__panels'}>
                        {
                            currentItems.map(item  =>  <Panel header={item.title} key={item.id}>
                                <div dangerouslySetInnerHTML={{ __html: item.text }} className={'help__text'}></div>
                            </Panel>
                            )
                        }

                    </Collapse>
                }
            </section>
        </PageWrapper>
    )
})

export default Help