// Core
import React, {FC, memo} from 'react'
// Ant Components
import {} from 'antd'


type PropsType = {
    children?: never
}

const NotNotification: FC<PropsType> = memo(() => {

    return (
        <svg width="114" height="92" viewBox="0 0 114 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="70.9219" cy="43.5234" rx="38.5718" ry="38.5292" fill="#F8F5FD"/>
            <path d="M64.3728 29.0996L64.8059 29.3493L65.0561 28.9165L66.1199 27.076C67.4378 24.7959 70.3555 24.0141 72.6369 25.3298C74.9183 26.6455 75.6994 29.5605 74.3815 31.8406L73.3177 33.681L73.0675 34.1139L73.5007 34.3637C81.6277 39.0507 84.4101 49.4348 79.7154 57.5573L69.1815 75.7825C67.8041 78.1655 68.6204 81.2122 71.0048 82.5873C71.1946 82.6967 71.2596 82.9393 71.15 83.129L68.4509 87.7989C68.0363 88.5161 67.1185 88.762 66.4008 88.3482L18.9828 61.0016C18.2652 60.5877 18.0195 59.6708 18.434 58.9535L21.0771 54.3807C21.2177 54.1374 21.5291 54.054 21.7725 54.1944L22.1776 54.428C24.6064 55.8287 27.7128 54.9964 29.1159 52.5689L38.7058 35.977C43.8962 26.9969 55.3877 23.9177 64.3728 29.0996Z" fill="white" stroke="#554CC2"/>
            <path opacity="0.9" d="M24.7785 0.72125C24.9331 0.632093 25.1235 0.632093 25.2781 0.72125L27.7422 2.14233C27.897 2.23161 27.9924 2.39674 27.9924 2.57546V5.41571C27.9924 5.59443 27.897 5.75955 27.7422 5.84884L25.2781 7.26992C25.1235 7.35908 24.9331 7.35908 24.7785 7.26992L22.3144 5.84884C22.1596 5.75956 22.0642 5.59443 22.0642 5.41571V2.57546C22.0642 2.39674 22.1596 2.23161 22.3144 2.14233L24.7785 0.72125Z" stroke="#665EC8"/>
            <path opacity="0.9" d="M109.75 82.6304C109.905 82.5413 110.095 82.5413 110.25 82.6304L112.714 84.0515C112.869 84.1408 112.964 84.3059 112.964 84.4846V87.3249C112.964 87.5036 112.869 87.6687 112.714 87.758L110.25 89.1791C110.095 89.2683 109.905 89.2683 109.75 89.1791L107.286 87.758C107.131 87.6687 107.036 87.5036 107.036 87.3249V84.4846C107.036 84.3059 107.131 84.1408 107.286 84.0515L109.75 82.6304Z" stroke="#665EC8"/>
            <g opacity="0.78" clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M103.455 2.46311L105.453 3.61534C105.544 3.66794 105.62 3.74359 105.672 3.8347C105.725 3.92581 105.753 4.02916 105.753 4.13436V6.43911C105.753 6.54432 105.725 6.64767 105.672 6.73877C105.62 6.82988 105.544 6.90554 105.453 6.95814L103.455 8.11037C103.363 8.16297 103.26 8.19066 103.155 8.19066C103.049 8.19066 102.946 8.16297 102.855 8.11037L100.857 6.95814C100.765 6.90554 100.69 6.82988 100.637 6.73877C100.584 6.64767 100.557 6.54432 100.557 6.43911V4.13436C100.557 4.02916 100.584 3.92581 100.637 3.8347C100.69 3.74359 100.765 3.66794 100.857 3.61534L102.855 2.46311C102.946 2.41051 103.049 2.38281 103.155 2.38281C103.26 2.38281 103.363 2.41051 103.455 2.46311Z" fill="#665EC8"/>
            </g>
            <g opacity="0.8">
                <rect width="9.52202" height="1.78538" rx="0.89269" transform="matrix(0.707499 -0.706714 0.707499 0.706714 0 42.4648)" fill="#665EC8"/>
                <rect width="9.52202" height="1.78538" rx="0.89269" transform="matrix(-0.707499 -0.706714 0.707499 -0.706714 6.73682 43.7266)" fill="#665EC8"/>
            </g>
            <mask id="path-8-inside-1" fill="white">
                <path d="M45.847 78.6239C44.8954 80.2703 44.6375 82.2269 45.1301 84.0633C45.6227 85.8996 46.8254 87.4653 48.4736 88.4159C50.1219 89.3664 52.0807 89.624 53.919 89.132C55.7574 88.6399 57.3248 87.4385 58.2764 85.7921L52.0617 82.208L45.847 78.6239Z"/>
            </mask>
            <path d="M45.847 78.6239C44.8954 80.2703 44.6375 82.2269 45.1301 84.0633C45.6227 85.8996 46.8254 87.4653 48.4736 88.4159C50.1219 89.3664 52.0807 89.624 53.919 89.132C55.7574 88.6399 57.3248 87.4385 58.2764 85.7921L52.0617 82.208L45.847 78.6239Z" fill="white" stroke="#554CC2" strokeWidth="2" mask="url(#path-8-inside-1)"/>
            <defs>
                <clipPath id="clip0">
                    <rect width="6" height="5.99338" fill="white" transform="translate(100.155 2.29004)"/>
                </clipPath>
            </defs>
        </svg>

    )
})

export default NotNotification
