// Type
import { RootState, store } from 'store'

const transactionsSelect = {
    transactions: (store: RootState) => store.transactions.transactions,
    transactions_load: (store: RootState) => store.transactions.load_transactions,
    transactions_filter_mode: (store: RootState) => store.transactions.transactions_filter_mode,
    transactions_base_query: (store: RootState) => store.transactions.transactions_base_query,
    transactions_filter: (store: RootState) => store.transactions.transactions_filter,
    transactions_count_filter: (store: RootState) => store.transactions.transactions_count_filter,
}

export default transactionsSelect
