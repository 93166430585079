// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Selector
import {homeSelector} from "selectors/cabinet";
import {appSelect} from "selectors";
// Types
import {TPortfolioTableFormatted} from "types/cabinet/cabinet-main-type";
// Actions
import {cabinetMainActions} from "actions/cabinet";
// Hooks
import {useParseToCurrency} from "hooks";
import {range} from "utils";




export const useYourPortfolioEf = () => {

    const dispatch = useDispatch()
    const loadPortfolio = useSelector(homeSelector.home_load_chart)
    const portfolioData = useSelector(homeSelector.portfolio)
    const baseFiatSign = useSelector(homeSelector.base_sign_name)

    const parseCurrency = useParseToCurrency(baseFiatSign || '')

    const getMockPortfolioData = (id: string):TPortfolioTableFormatted  => ({
        id,
        name: '',
        balance: '',
        value: '',
        icon: '',
        percent: 0
    })

    const emptyPortfolioData = range(0,4).map(elem => getMockPortfolioData(`${elem}`))


    const handlerAllAssets = () => {
        dispatch(cabinetMainActions.set_menu_mode('portfolio'));
    }


    return { loadPortfolio, portfolioData, baseFiatSign, parseCurrency, emptyPortfolioData, handlerAllAssets  }
}
