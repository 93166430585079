export default (val: string, symbols: number): string => {
	const length = val.length

	if (length > ((symbols * 2) + 1)) {
		const valArray = val.split('')
		const valStart = valArray.filter((elem, key) => key < symbols).join('')
		const valFinish = valArray.reverse().filter((elem, key) => key < symbols).reverse().join('')

		return `${valStart}...${valFinish}`
	} else {
		return val
	}
}