// Core
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {cabinetMainSelect} from "../../../../selectors/cabinet";
import {mainSelect} from "../../../../selectors";
import {FaqType} from "../../../../types/main-types";



export const useHelpEf = () => {


    // Selectors

    const tab = useSelector(cabinetMainSelect.help_tab)
    const items = useSelector(mainSelect.faq_items)


    // State

    const [currentItems, setCurrentItems] = useState<null | Array<FaqType>>(null)

    // Effects

    useEffect(() => {

        const currentItems = items.filter(item => item.category.toLowerCase() === tab?.toLowerCase())

        setCurrentItems(currentItems)
    },[items, tab])


    return {tab, currentItems}
}