// Core
import {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Hook
import {useSizeComponent} from 'hooks'
// Selector
import {portfolioSelect} from 'selectors/cabinet'
// Actions
import {cabinetMainActions, portfolioActions} from 'actions/cabinet'
// Utils
import {range} from 'utils'
// Type
import {TCurrencyDetail, TWalletItem} from 'types/cabinet/portfoli-type'


const getMockColumnItem = (id: string): TWalletItem  => ({
    id,
    cur_id: '',
    name: '',
    icon: '',
    full_name: '',
    balance: '0',
    value: '0',
    price: 0,
    percent_change: 0,
    in_favorites: 0,
    percent: '0',
    address: ''
})

export const useWalletsEf = () => {
    const dispatch = useDispatch()

    // Selectors

    const emptyBalanceMode = useSelector(portfolioSelect.empty_balance_mode)
    const searchWallet = useSelector(portfolioSelect.search_wallet)
    const loadWallet = useSelector(portfolioSelect.load_wallet)
    const loadFirst = useSelector(portfolioSelect.load_first)
    const walletsPrepare = useSelector(portfolioSelect.wallets)

    // State

    const [refWrapper, dataWrapper] = useSizeComponent()
    const [refHeader, dataHeader] = useSizeComponent()
    const [tableHeight, setTableHeight] = useState((dataWrapper.height - dataHeader.height) - 60)
    const [emptyBalanceCount, setEmptyBalanceCount] = useState<number | null>(0)

    const fiatSymbol = walletsPrepare ? walletsPrepare.base_fiat_sign : ''
    const wallets = !walletsPrepare || !walletsPrepare.items
        ? !loadFirst.includes('wallets')
            ? range(0, 3)
                .map(elem => getMockColumnItem(`${elem}`))
                .map(elem => ({...elem, key: elem.id}))
            : null
        : walletsPrepare.items
            .filter(elem => emptyBalanceMode ? elem.balance !== '0' : elem.balance !== null)
            .filter(elem => elem.full_name.toLocaleLowerCase().match(searchWallet.toLocaleLowerCase()))
            .map(elem => ({...elem, key: elem.id}))

   // const getCountEmptyBalance = () => wallets ? wallets.filter(elem => elem.balance === '0').length : 0


    // Effects

    useEffect(() => {
        const emptyCount = wallets?.filter(elem => elem.balance === '0').length || null

        emptyCount && setEmptyBalanceCount(emptyCount)
    },[wallets])

    useEffect(() => {
        setTableHeight((dataWrapper.height - dataHeader.height) - 60)
    }, [dataWrapper, dataHeader])


    // Handlers

    const handlerEmptyBalance = () => {
        dispatch(portfolioActions.set_empty_balance_mode(!emptyBalanceMode))
    }

    const handlerModalAddWallet = () => {
        dispatch(portfolioActions.set_add_wallet__mode(true))
    }

    const handlerSearchWallet = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(portfolioActions.set_search_wallet(e.target.value))
    }

    const handlerFavorite = (record: TWalletItem, add: boolean) => {

        const transformRecord = {...record, id: record.cur_id}

        add
            ? dispatch(cabinetMainActions.watch_add_favorites(transformRecord))
            : dispatch(cabinetMainActions.watch_remove_favorites(transformRecord))

    }

    const handlerCurrency = (data: TCurrencyDetail) => {
        dispatch(portfolioActions.set_currency_detail(data))
    }

    const handlerWalletResent = () => {
        dispatch(portfolioActions.watch_wallet())
    }


    return {
        emptyBalanceMode, emptyBalanceCount, searchWallet, loadWallet, wallets, fiatSymbol,
        refWrapper, refHeader, tableHeight, loadFirst, walletsPrepare,
        handlerEmptyBalance, handlerModalAddWallet, handlerSearchWallet, handlerFavorite,
        handlerCurrency, handlerWalletResent,
    }
}
