// Core
import React, {CSSProperties, FC, memo} from 'react'
// Style
import './Facebook.scss'



type PropsType = {
    children?: never
    style?: CSSProperties
    className?: string | Object
}

const Facebook: FC<PropsType> = memo(({style, className}) => {

    return (
        <svg style={style} className={`facebook ${className}`} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 0C9.6388 6.13649e-05 6.40826 1.30196 3.98625 3.63252C1.56423 5.96308 0.139013 9.14115 0.00964237 12.4999C-0.119728 15.8586 1.05681 19.1368 3.29237 21.6468C5.52794 24.1568 8.64876 25.7033 12 25.9619V16.0001H9C8.73478 16.0001 8.48043 15.8948 8.29289 15.7072C8.10536 15.5197 8 15.2653 8 15.0001C8 14.7349 8.10536 14.4805 8.29289 14.293C8.48043 14.1055 8.73478 14.0001 9 14.0001H12V11.0001C12.0012 9.93962 12.423 8.92291 13.1729 8.17303C13.9228 7.42315 14.9395 7.00133 16 7.00012H18C18.2652 7.00012 18.5196 7.10547 18.7071 7.29301C18.8946 7.48055 19 7.7349 19 8.00012C19 8.26533 18.8946 8.51969 18.7071 8.70722C18.5196 8.89476 18.2652 9.00012 18 9.00012H16C15.4698 9.00072 14.9614 9.21163 14.5865 9.58657C14.2115 9.96152 14.0006 10.4699 14 11.0001V14.0001H17C17.2652 14.0001 17.5196 14.1055 17.7071 14.293C17.8946 14.4805 18 14.7349 18 15.0001C18 15.2653 17.8946 15.5197 17.7071 15.7072C17.5196 15.8948 17.2652 16.0001 17 16.0001H14V25.9619C17.3512 25.7033 20.472 24.1567 22.7076 21.6468C24.9431 19.1368 26.1197 15.8586 25.9903 12.4999C25.8609 9.14115 24.4357 5.9631 22.0137 3.63254C19.5917 1.30198 16.3612 7.39253e-05 13 0Z" fill="#332E54"/>
        </svg>
    )
})

export default Facebook
