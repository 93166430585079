// Core
import {ChangeEvent, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Selector
import {portfolioSelect, cabinetMainSelect} from 'selectors/cabinet'
// Action
import {portfolioActions} from 'actions/cabinet'
// Type
import {TTransactionQueryFilter} from 'types/cabinet/transactions'
// Utils
import config from 'utils/config'
import moment, { duration } from 'moment'
// Types
import {RadioChangeEvent} from "antd/lib/radio"
import { filters } from 'utils'

type TTransactionsFilterEf = {
	mode: boolean,
	filter: TTransactionQueryFilter,
	handlerSubmitFilter: (val: Omit<TTransactionQueryFilter, 'name'>) => void,
	handlerSetFilterCount: (count: number) => void
}
export const useTransactionFilterEf = ({mode, filter, handlerSubmitFilter, handlerSetFilterCount}: TTransactionsFilterEf) => {

	const currencyList = useSelector(cabinetMainSelect.currency_list_crypto)

	const prepareType = filter.type ? filter.type : null
	const prepareStatus = filter.status ? filter.status : null
	const prepareCurrencies = filter.currency ? filter.currency : []

	const prepareDataRange = filter.dateFrom
		? 'custom'
		:  filter.duration
			? filter.duration
			: config.cabinet.transaction.filter.date_range[0].val

	const prepareDates = filter.dateFrom && filter.dateTo
		? [
			moment(filter.dateFrom, config.cabinet.transaction.filter.date_format),
			moment(filter.dateTo, config.cabinet.transaction.filter.date_format),
		]
		: []

	const [submit, setSubmit] = useState(false)
	const [dataRange, setDataRange] = useState(prepareDataRange)
	const [showPiker, setShowPiker] = useState(prepareDataRange === 'custom')
	const [hackValue, setHackValue] = useState<any>()
	const [valueDates, setValueDates] = useState<any>()
	const [dates, setDates] = useState<any>(prepareDates)
	const [disabledClearBtn, setDisabledClearBtn] = useState(true)
	const [type, setType] = useState<null | string>(prepareType)
	const [status, setStatus] = useState<null | string>(prepareStatus)
	const [currencies, setCurrencies] = useState(prepareCurrencies)
	// Filters count
	const [dateRangeCount, setDateRangeCount] = useState<number>(0)

	const [resetFilterForm, setResetFilterForm] = useState<boolean>(false)

	// effects

	useEffect(() => {
		submit && setSubmit(false)
	}, [submit])

	useEffect(() => {
		if (currencies.length || status || type || dataRange.toLowerCase() !== 'all'){
			setDisabledClearBtn(false)
		} else {
			setDisabledClearBtn(true)
		}
	}, [dataRange, valueDates, type, status, currencies])

	// handlers

	const handlerClear = () => {

		handlerSubmitFilter({
			duration: null,
			dateFrom: null,
			dateTo: null,
			type: null,
			status: null,
			currency: []
		})

		handlerResetFilterData()

		setResetFilterForm(true)

		handlerSetFilterCount(0)
	}

	const handlerResetFilterData = () => {
		setDataRange('all')
		setShowPiker(prepareDataRange === 'custom')
		setDates(prepareDates)
		setValueDates(null)
		setType(null)
		setStatus(null)
		setCurrencies([])
	}
	const handlerStatus = (e: RadioChangeEvent) => {
		setStatus(e.target.value)
	}
	const handlerType = (e: RadioChangeEvent) => {
		setType(e.target.value)
	}
	const handlerCurrencies = (val: string[]) => {
		setCurrencies(val)
	}

	const handlerClickSubmit = () => setSubmit(true)

	const handlerSubmit = (val: Omit<TTransactionQueryFilter, 'name'>, resetForm: () => void) => {

		if (dates.length === 2 && dataRange === 'custom') {
			val.dateFrom = moment(dates[0]).format(config.cabinet.transaction.filter.date_format)
			val.dateTo =  moment(dates[1]).format(config.cabinet.transaction.filter.date_format)
		} else {
			val.dateFrom = null
			val.dateTo = null
		}

		const typeLength: number = type?.length ? 1 : 0
		const statusLength: number = status?.length ? 1 : 0

		handlerSetFilterCount(currencies.length + dateRangeCount + typeLength + statusLength)

		handlerSubmitFilter(val)
		resetForm()
	}

	const handlerDataRange = (val: string) => {
		setDateRangeCount(1)
		setDataRange(val)
		setShowPiker(val === 'custom')
	}

	const disabledDate = (current: any) => {
		if (!dates || dates.length === 0) {
			return false;
		}
		const tooLate = dates[0] && current.diff(dates[0], 'days') > 31;
		const tooEarly = dates[1] && dates[1].diff(current, 'days') > 31;
		return tooEarly || tooLate;
	}

	const onOpenChange = (open: any) => {
		if (open) {
			setHackValue([]);
			setDates([]);
		} else {
			setHackValue(undefined);
		}
	}


	return {
		currencyList, submit, showPiker, dataRange, hackValue, valueDates, setValueDates, setDates,
		disabledClearBtn, status, type, currencies, resetFilterForm,
		handlerClickSubmit, handlerDataRange, disabledDate, onOpenChange,
		handlerClear, handlerStatus, handlerType, handlerCurrencies, handlerSubmit
	}
}
