// Core
import React, {FC, memo} from 'react'
import useScreen from "use-screen";
import {CopyToClipboard} from 'react-copy-to-clipboard'
// Ant Components
import {Button, Skeleton, Table} from 'antd'
// Hook
import {useAddedAddressesEf} from './useAddedAddressesEf'
// Elements
import {Typography} from "elements";
import {IAddressTableItem} from "../../../../../../types/cabinet/profile";
import {
    QrcodeOutlined,
    CopyOutlined,
    ExportOutlined,
    StarFilled,
    StarOutlined,
    ImportOutlined
} from "@ant-design/icons";



type PropsType = {
    children?: never
}


type TWalletAddress = {
    id: string,
    address: string,
    description: string,
    created_at?: string
}

type TWalletAddressColumns = {
    handlerCopy: () => void,
    handlerQr: (address: string) => void,
    handlerExportAddress: (address: string) => void
}

const columns = ({handlerCopy, handlerQr, handlerExportAddress}: TWalletAddressColumns) => [
    {
        title: 'Wallet Address',
        dataIndex: 'address',
        key: 'address',
        fixed: 'left'
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        fixed: 'left'
    },
    {
        title: 'Label',
        dataIndex: 'description',
        key: 'description',
        fixed: 'left',
        render: (text: string, record: TWalletAddress) => (record.description && record.description.length) ? record.description : '-'
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (text: string, record: TWalletAddress ) => (<>
            <button className={'portfolio__export-button profile__addresses__table__action_btn'} onClick={() => handlerExportAddress(record.address)}>
                <ExportOutlined  className={'portfolio__export-icon'}/>
                Export address
            </button>
            <CopyToClipboard text={record.address}>
                    <CopyOutlined className={'base-table profile__addresses__table__action_btn profile__addresses__table__action_icony'} onClick={() => handlerCopy()} />
            </CopyToClipboard>
            <QrcodeOutlined className={'base-table profile__addresses__table__action_btn profile__addresses__table__action_icon'} onClick={() => handlerQr(record.address)}/>
        </>)
    },
]

const emptyColomns = [
    {
        title: 'Wallet Address',
        dataIndex: 'address',
        key: 'address',
        fixed: 'left',
        render: () => <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        fixed: 'left',
        render: () => <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
    }
]

const AddedAddresses: FC<PropsType> = memo(() => {
    const {
        currencyDetails, canAddAddress, loadAddAddress, emptyAddressData,
        handlerAddAddress, handlerCopy, handlerQr, handlerImportAddress, handlerExportAddress
    } = useAddedAddressesEf()

    const {screenHeight} = useScreen()

    return <>
        <div className={'portfolio-transactions'}>
            <div className="transactions-table__header">
                <div className={'portfolio-transactions__header_subheader'}>
                    <Typography type={'title-18'} color={'primary'}>
                        Added Addresses
                    </Typography>
                    <div className="portfolio-transactions__header__action">
                        <Button onClick={handlerImportAddress} type={'default'} className={'header-profile-action__action__btn__favorite'}>
                            <ImportOutlined />
                            Import address
                        </Button>
                        <Button
                            className={'portfolio-wallet__header__action_item'}
                            onClick={handlerAddAddress}
                            type="primary"
                            loading={loadAddAddress}
                            disabled={canAddAddress}
                        >
                            Add Address
                        </Button>
                    </div>
                </div>
            </div>
            <div>
                {
                    !loadAddAddress ?
                        <Table
                            className={'base-table profile__addresses__table base-table__addresses'}
                            dataSource={currencyDetails?.addresses}
                            rowKey="id"
                            pagination={false}
                            //@ts-ignore
                            columns={columns({handlerCopy, handlerQr, handlerExportAddress})}
                        />
                    :
                        <Table
                            className={'base-table profile__addresses__table base-table__addresses'}
                            dataSource={emptyAddressData}
                            rowKey="id"
                            pagination={false}
                            //@ts-ignore
                            columns={emptyColomns}
                        />
                }
            </div>
        </div>
    </>
})

export default AddedAddresses
