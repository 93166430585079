// Core
import {useSelector, useDispatch} from 'react-redux'
// Actions
import {portfolioActions} from 'actions/cabinet'
// Selector
import {portfolioSelect} from 'selectors/cabinet'


export const useBalanceInfoEf = () => {
    const dispatch = useDispatch()
    const balance = useSelector(portfolioSelect.balance)

    const handlerOpenGenerateReport = () => {
        dispatch(portfolioActions.set_generate_report_mode(true))
    }

    return {
        balance,
        handlerOpenGenerateReport
    }
}
