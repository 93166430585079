// Core
import React, {FC, memo} from 'react'
// Ant Component
import {Button, Col, Row, Skeleton} from 'antd'
// Element
import {Typography} from 'elements'
// Hook
import {useBalanceInfoEf} from './useBalanceInfoEf'
// Hook
import {useParseToCurrency} from 'hooks'


type PropsType = {
    children?: never
}

const BalanceInfo: FC<PropsType> = memo(() => {
    const {
        balance,
        handlerOpenGenerateReport
    } = useBalanceInfoEf()

    const parseFunction = useParseToCurrency(balance?.base_fiat_sign)

    return (<>
        <div className={'portfolio__balance__info'}>
            <Typography className={'portfolio__balance__info_title'} type={"title-38"} color={"primary"} weight={"regular"}>
                {
                    balance ?
                        <>
                            { parseFunction(balance.balance).full_with_currency()}
                        </>
                        :
                        <Skeleton.Button active={true} size={'small'} shape={'square'} />
                }
            </Typography>
            <Row>
                <Col xs={12}>
                    <div>
                        <Typography type={"text-16"} color={"dark-gray"}>
                            Income (24H)
                        </Typography>
                        <Typography className={'portfolio__balance__info_count'} type={"text-22"} color={"primary"}>
                            {
                                balance ?
                                    <>
                                        { parseFunction(balance.income).integer() }
                                        <span>{ parseFunction(balance.income).fractional() }</span>
                                    </>
                                    :
                                    <Skeleton.Button active={true} size={'small'} shape={'square'} />
                            }
                        </Typography>
                    </div>
                    <div className={'portfolio__balance__info_item'}>
                        <Typography type={"text-16"} color={"dark-gray"}>
                            Profit (24H)
                        </Typography>
                        <Typography className={'portfolio__balance__info_count'} type={"text-22"} color={"primary"}>
                            {
                                balance ?
                                    <>
                                        { parseFunction(balance.profit).integer() }
                                        <span>{ parseFunction(balance.profit).fractional() }</span>
                                    </>
                                    :
                                    <Skeleton.Button active={true} size={'small'} shape={'square'} />
                            }
                        </Typography>
                    </div>
                </Col>
                <Col xs={12}>
                    <div>
                        <Typography type={"text-16"} color={"dark-gray"}>
                            Outcome (24H)
                        </Typography>
                        <Typography className={'portfolio__balance__info_count'} type={"text-22"} color={"primary"}>
                            {
                                balance ?
                                    <>
                                        { parseFunction(balance.outcome).integer() }
                                        <span>{ parseFunction(balance.outcome).fractional() }</span>
                                    </>
                                    :
                                    <Skeleton.Button active={true} size={'small'} shape={'square'} />
                            }
                        </Typography>
                    </div>
                    <div className={'portfolio__balance__info_item'}>
                        <Typography type={"text-16"} color={"dark-gray"}>
                            % Profit (24H)
                        </Typography>
                        <Typography className={'portfolio__balance__info_count'} type={"text-22"} color={"primary"}>
                            {
                                balance ?
                                    <>
                                        {
                                            +balance.profit_percent > 0 ?
                                                '+' + parseFunction(balance.profit_percent).full() + '%'
                                                :
                                                parseFunction(balance.profit_percent).full() + '%'
                                        }
                                    </>
                                    :
                                    <Skeleton.Button active={true} size={'small'} shape={'square'} />
                            }
                        </Typography>
                    </div>
                </Col>
            </Row>
            <Button type="default" className={'portfolio__balance__info_btn'} onClick={handlerOpenGenerateReport}>
                Generate Report
            </Button>
        </div>
    </>)
})

export default BalanceInfo
