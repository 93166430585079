// Core
import React, {FC, memo} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
// Ant Components
import {Button, Skeleton, Tooltip} from 'antd'
// Ant Icon
import {CopyOutlined, QrcodeOutlined, StarFilled, StarOutlined} from '@ant-design/icons'
// Style
import './HeaderPortfolioAction.scss'
// Hook
import {useHeaderPortfolioActionEf} from './useHeaderPortfolioActionEf'
// Utils
import {config, prepareWalletAddress} from 'utils'
// Element
import {Typography} from 'elements'


type PropsType = {
	children?: never
}

const HeaderPortfolioAction: FC<PropsType> = memo(() => {
	const {
		currency_detailsData, loadCurrencyDetail,
		handlerAsset, handlerFavorite, handlerCopy, handlerQr,
	} = useHeaderPortfolioActionEf()

	return (
		<div className={'header-profile-action__action'}>
			<div className={'header-profile-action__action__currency'}>
				{
					currency_detailsData && !loadCurrencyDetail
						? (
							<img
								className={'header-profile-action__action__currency__icon'}
								src={`${config.app.apiURL}${currency_detailsData.icon}`}
								alt={currency_detailsData.full_name}
							/>
						)
						: <Skeleton.Image className={'header-profile-action__action__currency__icon'} />
				}
				<div className={'header-profile-action__action__currency__wallet'}>
					{
						currency_detailsData && !loadCurrencyDetail
							? (
								<Typography className={'header-profile-action__action__currency__wallet__title'} type={'title-22'} color={'primary'} weight={'medium'}>
									{currency_detailsData.full_name}
								</Typography>
							)
							: <Skeleton.Input style={{width: 100, marginBottom: 12}} className={'header-profile-action__action__currency__wallet__title'} active />
					}

					<div className={'header-profile-action__action__currency__wallet__address'}>
						{
							currency_detailsData && !loadCurrencyDetail
								? (
									<Typography className={'header-profile-action__action__currency__wallet__address__text'} type={'text-16'} color={'gray'}>
										Wallet Address:
										<Tooltip placement="top" title={currency_detailsData.address}>
											<span> {prepareWalletAddress( currency_detailsData.address, 8)}</span>
										</Tooltip>
									</Typography>
								)
								: <Skeleton.Input style={{width: 200}} className={'header-profile-action__action__currency__wallet__address__text'} active />
						}
						{
							currency_detailsData && !loadCurrencyDetail
								? (
									<CopyToClipboard text={currency_detailsData.address}>
										<Button
											onClick={handlerCopy}
											type={'default'}
											className={'portfolio__copy-btn portfolio__copy-btn_margin'}
										>
											<CopyOutlined />
										</Button>
									</CopyToClipboard>

								)
								:  <Skeleton.Button />
						}
						{
							currency_detailsData && !loadCurrencyDetail
								? (
									<Button
										onClick={handlerQr}
										type={'default'}
										className={'portfolio__copy-btn'}
									>
										<QrcodeOutlined />
									</Button>
								)
								: <Skeleton.Button />
						}
					</div>
				</div>
			</div>
			<div className={'header-profile-action__action__btn'}>
				{
					currency_detailsData && !loadCurrencyDetail
						? (
							<Button onClick={() => handlerFavorite(currency_detailsData.currency_id, !!currency_detailsData.in_favorites)} type={'default'} className={'header-profile-action__action__btn__favorite'}>
								{
									currency_detailsData.in_favorites
										? currency_detailsData.in_favorites
										? <StarFilled />
										: <StarOutlined />
										: <StarOutlined />
								}
								Favorite
							</Button>
						)
						: <Skeleton.Button className={'header-profile-action__action__btn__favorite'} />
				}
				{
					currency_detailsData && !loadCurrencyDetail
						? (
							<Button onClick={handlerAsset} type={'primary'} className={'header-profile-action__action__btn__assets'}>
								Send Asset
							</Button>
						)
						: <Skeleton.Button className={'header-profile-action__action__btn__assets'} />
				}
			</div>
		</div>
	)
})

export default HeaderPortfolioAction
