// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Modal} from 'antd'
// Ant Icon
// Style
import './ExportAddress.scss'
// Hook
import {useExportAddressEf} from './useExportAddressEf'
import ExportDefault from "#/components/export-address/elements/export-default/ExportDefault";
import ExportFinal from "#/components/export-address/elements/export-final/ExportFinal";
import {EExportType} from "../../../../types/cabinet/cabinet-main-type";
import ExportViaMail from "#/components/export-address/elements/export-via-mail/ExportViaMail";
import ExportViaGoogle from "#/components/export-address/elements/export-via-google/ExportViaGoogle";


type PropsType = {
    children?: never
}

const ExportAddress: FC<PropsType> = memo(() => {
    const {
        mode, config, loadExport, errors,
        handlerClose, handlerSubmitDefault, handlerSubmitEmail,
        handlerSubmitGoogle, handlerQr, handlerCopy
    } = useExportAddressEf()

    const keys = [
        {
            title: 'Hex',
            value: 'sf3g3gfdgdgdfg'
        },
        {
            title: 'WIF',
            value: '023453g45g454'
        },
        {
            title: 'WIF-compressed',
            value: '0343e34'
        }
    ]

    return <Modal
        title={'Export Address'}
        centered
        visible={mode}
        onCancel={handlerClose}
        width={450}
        footer={false}
        >
        {
            config?.type == EExportType.DEFAULT && <ExportDefault address={config.address} handlerSubmit={handlerSubmitDefault} loading={loadExport} formErrors={errors}/>
        }
        {
            config?.type == EExportType.EMAIL && <ExportViaMail address={config.address} handlerSubmit={handlerSubmitEmail} loading={loadExport} formErrors={errors}/>
        }
        {
            config?.type == EExportType.GOOGLE && <ExportViaGoogle address={config.address} handlerSubmit={handlerSubmitGoogle} loading={loadExport} formErrors={errors}/>
        }
        {
            config?.type == EExportType.FINAL && <ExportFinal
                address={config.finalData?.address || ''}
                private_key={config.finalData?.private_key || ''}
                handlerCopy={handlerCopy}
                handlerQr={handlerQr}
                handlerClose={handlerClose}
            />
        }
    </Modal>
})

export default ExportAddress
