// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Modal} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
// Hook
import {useExportDefaultEf} from './useExportDefaultEf'
import {filters} from "utils";
import * as Yup from "yup";
import {Form, Input} from "formik-antd";
import {Formik} from "formik";
import {Button} from "../../../../../../elements";
import {LoadingOutlined} from '@ant-design/icons'
import {FormikHandler} from "../../../../../../components";

type TSubmitValues = {
    password: string
}

type PropsType = {
    children?: never,
    address: string,
    handlerSubmit: (values: TSubmitValues) => void ,
    loading: boolean,
    formErrors: any
}

const PasswordScheme = Yup.object().shape({
    password: Yup.string()
        .required('Required')
})

const ExportDefault: FC<PropsType> = memo(({address, handlerSubmit, loading, formErrors}) => {
    const {} = useExportDefaultEf()

    const init = {
       password: ''
    }

    return <>
        <p className={'export-address__text'}>Enter your password to confirm the export address <span className={'export-address__text-address'}>{filters.hideMiddleString(address)}</span></p>
        <Formik onSubmit={handlerSubmit} initialValues={init} validationSchema={PasswordScheme} autoComplete={'off'}>
            {({errors, values, touched,setFieldValue}) => (
                <Form>
                    <Form.Item
                        name={'password'}
                        label={'Your password'}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        style={{marginBottom: 40}}
                    >
                        <Input.Password
                            // disabled={loadForm}
                            className={'input-item medium'}
                            size={'middle'}
                            name='password'
                            placeholder={'Enter password'}
                        />
                    </Form.Item>
                    <Button
                        className={'send-assets__submit'}
                        type="submit"
                        size={"full"}
                        color={"secondary"}
                        variant={"contained"}
                        loaderPosition={"center"}
                        // disabled={canSubmit}
                        loader={
                            loading
                                ? () => <LoadingOutlined className={'sign__loader-icon'} spin/>
                                : null
                        }
                    >
                        Confirm
                    </Button>
                    <FormikHandler data={formErrors}/>
                </Form>
            )}
        </Formik>

    </>
})

export default ExportDefault
