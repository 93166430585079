const getQueryParams = (params: object = {}, prefix?: {val: string, ignore: string[]}) => {
	const prepareQuery = Object.entries(params)

	const getPrefix = (val: string) => prefix && !prefix.ignore.includes(val)
		? `${prefix.val}[${val}]`
		: val === 'pageSize'
			? 'per-page'
			: val

	const queryData = prepareQuery
		.filter(elem => elem[1] !== null)
		.map((elem, key) => `${key ? '&' : '?'}${getPrefix(elem[0])}=${elem[1]}`)

	return queryData.join('')
}

export default getQueryParams