// Core
import React, {FC, memo} from 'react'
// Style
import './SkeletonChartMiddle.scss'


type PropsType = {
    children?: never
    className?: string
}

const SkeletonChartMiddle: FC<PropsType> = memo(({className}) => (
    <svg className={`skeleton-chart-middle ${className}`} width="1135" height="254" viewBox="0 0 1135 254" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M111.142 80.8754L60 106.29V228H1134V111.5L1062.5 44L1013 59L972.5 115.758L933.5 152L881 49.5L825.5 126L762 80.8754L705.068 106.29L654.891 80.8754L594.581 137.185L553.089 115.758H493.744L436.812 80.8754L384.705 97.8182L332.598 160.108L275.184 192L222.594 44L159.872 106.29L111.142 80.8754Z" fill="#F2F2F2"/>
        <path d="M60 106.29L111.142 80.8754L159.872 106.29L222.594 44L275.184 192L332.598 160.108L384.705 97.8182L436.812 80.8754L493.744 115.758H553.089L594.581 137.185L654.891 80.8754L705.068 106.29L762 80.8754L825.5 126L881 49.5L933.5 152L972.5 115.758L1013 59L1062.5 44L1134 111.5" stroke="#BFBFBF"/>
        <path d="M177 232V228" stroke="#BFBFBF"/>
        <path d="M60 232V228" stroke="#BFBFBF"/>
        <path d="M60 228H56" stroke="#BFBFBF"/>
        <path d="M60 184H56" stroke="#BFBFBF"/>
        <path d="M60 140H56" stroke="#BFBFBF"/>
        <path d="M60 96H56" stroke="#BFBFBF"/>
        <path d="M60 52H56" stroke="#BFBFBF"/>
        <path d="M60.5 8H56" stroke="#BFBFBF"/>
        <path d="M411 232V228" stroke="#BFBFBF"/>
        <path d="M528 232V228" stroke="#BFBFBF"/>
        <path d="M294 232V228" stroke="#BFBFBF"/>
        <path d="M645 232V228" stroke="#BFBFBF"/>
        <rect width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect y="44" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect y="88" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect y="131" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect y="176" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect y="220" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="60" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="155" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="272" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="389" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="506" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="623" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="740" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="857" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="974" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <rect x="1090" y="238" width="44" height="16" rx="4" fill="#F2F2F2"/>
        <path d="M60 228.5L60 7.99991" stroke="#BFBFBF"/>
        <path d="M59 228.5L1134 228.5V227.5L59 227.5V228.5Z" fill="#BFBFBF"/>
        <path d="M762 232V228" stroke="#BFBFBF"/>
        <path d="M879 232V228" stroke="#BFBFBF"/>
        <path d="M996 232V228" stroke="#BFBFBF"/>
        <path d="M1113 232V228" stroke="#BFBFBF"/>
    </svg>
))

export default SkeletonChartMiddle
