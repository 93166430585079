// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button, Input, Skeleton, Table} from 'antd'
// Ant Icon
import {SearchOutlined} from '@ant-design/icons'
// Style
import './Wallets.scss'
// Hook
import {useWalletsEf} from './useWalletsEf'
// Component
import {WalletChart} from '..'
// Element
import {Typography} from "elements"
import {NodeError} from '#/elements'
// Utils
import {config} from 'utils'
// Icons
import {FavoritesStar} from 'icons'
// Type
import {TCurrencyDetail, TWalletItem} from 'types/cabinet/portfoli-type'


type PropsType = {
    children?: never
}

type TColumns = {
    handlerFavorite: (record: TWalletItem, state: boolean) => void
    handlerCurrency: (data: TCurrencyDetail) => void
    loading?: boolean
    symbol: string
}

const sort = (A: string | number, B: string | number) => {
    if (A < B) return -1
    if (A > B) return 1
    return 0
}

const columns = ({handlerFavorite, handlerCurrency, loading = false, symbol}: TColumns) => ([
    {
        title: 'Assets Name',
        dataIndex: 'name',
        key: 'name',
        align: 'left',
        disable: true,
        sorter: {
            compare: (a: TWalletItem, b: TWalletItem) => sort(a.full_name, b.full_name),
        },
        render: (text: string, record: TWalletItem) => (<>
            {
                loading
                    ? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
                    : (
                        <div className={'portfolio-wallet__table__currency'} onClick={() => handlerCurrency({
                            id: record.id,
                            cur_id: record.cur_id,
                            name: record.name,
                            full_name: record.full_name
                        })}>
                            <img width={24} src={config.app.apiURL + record.icon} alt={`${record.full_name} icon`} />
                            {record.full_name}
                        </div>
                    )
            }
        </>)
    },
    {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        align: 'right',
        sorter: {
            compare: (a: TWalletItem, b: TWalletItem) => sort(a.balance, b.balance),
        },
        render: (text: string, record: TWalletItem) => loading
            ? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
            : record.balance
    },
    {
        title: 'Value, USD',
        dataIndex: 'value',
        key: 'value',
        align: 'right',
        sorter: {
            compare: (a: TWalletItem, b: TWalletItem) => sort(a.value, b.value),
        },
        render: (text: string, record: TWalletItem) => loading
            ? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
            : `${symbol} ${record.value}`
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        align: 'right',
        sorter: {
            compare: (a: TWalletItem, b: TWalletItem) => sort(a.price, b.price),
        },
        render: (text: string, record: TWalletItem) => loading
            ? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
            : `${symbol} ${record.price}`
    },
    {
        title: 'Change (24H)',
        dataIndex: 'percent_change',
        key: 'percent_change',
        align: 'right',
        sorter: {
            compare: (a: TWalletItem, b: TWalletItem) => sort(a.percent_change, b.percent_change),
        },
        render: (text: string, record: TWalletItem) => loading
            ? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
            : `${record.percent_change}%`
    },
    {
        title: 'Portfolio, %',
        dataIndex: 'percent',
        key: 'percent',
        align: 'right',
        sorter: {
            compare: (a: TWalletItem, b: TWalletItem) => sort(a.percent, b.percent),
        },
        render: (text: string, record: TWalletItem) => loading
            ? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
            : `${record.percent}%`
    },
    {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        render: (text: string, record: TWalletItem) => (<>
            { loading
                ? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
                : record.in_favorites
                    ? <FavoritesStar active={true}  onClick={() => handlerFavorite(record, false)} />
                    : <FavoritesStar onClick={() => handlerFavorite(record, true)} />
            }
        </>)
    },
])

const Wallets: FC<PropsType> = memo(() => {
    const {
        emptyBalanceMode, emptyBalanceCount, searchWallet, loadWallet, wallets, fiatSymbol,
        refWrapper, refHeader, tableHeight, loadFirst, walletsPrepare,
        handlerEmptyBalance, handlerModalAddWallet, handlerSearchWallet, handlerFavorite,
        handlerCurrency, handlerWalletResent,
    } = useWalletsEf()

    return (
        <div className={'portfolio-wallet'} ref={refWrapper}>
        {
            wallets
                ? (<>
                    <div className={'portfolio-wallet__header'} ref={refHeader}>
                        <Typography type={"text-18"} color={"primary"} weight={"medium"}>
                            My Wallets
                        </Typography>
                        <div className={'portfolio-wallet__header__action'}>
                            <Input
                                placeholder="Search Assets name"
                                prefix={<SearchOutlined />}
                                style={{width: 230}}
                                className={'input-item portfolio-wallet__header__action_item'}
                                size={'middle'}
                                onChange={handlerSearchWallet}
                                value={searchWallet}
                                disabled={loadWallet}
                                allowClear={true}
                            />
                            <Button
                                className={'portfolio-wallet__header__action_item'}
                                onClick={handlerEmptyBalance}
                                type={'default'}
                                disabled={loadWallet}
                            >
                                {
                                    `${emptyBalanceMode ? 'Show' : 'Hide'} empty balance (${emptyBalanceCount} item)`
                                }
                            </Button>
                            <Button
                                className={'portfolio-wallet__header__action_item'}
                                onClick={handlerModalAddWallet}
                                type="primary"
                                disabled={loadWallet}
                            >
                                Add Wallets
                            </Button>
                        </div>
                    </div>
                    <Table
                        className={'base-table portfolio-wallet__table base-table__portfolio-wallet'}
                        loading={loadWallet && loadFirst.includes('wallets')}
                        scroll={{ y: tableHeight}}
                        pagination={false}
                        expandable={!loadFirst.includes('wallets') ? {} : {expandedRowRender: (data) => <WalletChart fiatSign={fiatSymbol} data={data}/>}}
                        //@ts-ignore
                        columns={columns({
                            handlerFavorite, handlerCurrency, symbol: fiatSymbol,
                            loading: !loadFirst.includes('wallets'),
                        })}
                        dataSource={wallets}
                    />
                </>)
                : <NodeError withHandler={true} handler={handlerWalletResent} />
        }
        </div>
    )
})

export default Wallets
