// Core
import {useSelector} from 'react-redux'
import {useEffect} from "react"
// Selector
import {appSelect} from "selectors"


export const useNotFoundEf = () => {
    const lang = useSelector(appSelect.language)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [window])

    return {
        lang,
    }
}
