// Core
import {useSelector} from 'react-redux'
import {LegacyRef, useEffect, useRef} from 'react'
// Select
import {cabinetMainSelect} from 'selectors/cabinet'


export const usePageWrapperEf = (handlerRef?: (ref: LegacyRef<any>) => void) => {
    const siderMode = useSelector(cabinetMainSelect.sider_mode)

    const $container = useRef(null)


    useEffect(() => {
        $container  && handlerRef && handlerRef($container.current)
    }, [$container])

    return {
        siderMode, $container,
    }
}
