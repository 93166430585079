// Core
import {SagaIterator}  from 'redux-saga'
import {all, fork, takeLeading, takeEvery} from 'redux-saga/effects'
// Actions
import {
    PORTFOLIO__WATCH_BALANCE, PORTFOLIO__WATCH_WALLET, PORTFOLIO__WATCH_CURRENCY, PORTFOLIO__WATCH_CURRENCY_DETAIL,
    PORTFOLIO__WATCH_CURRENCY_DETAIL_CHART, PORTFOLIO__WATCH_TRANSACTIONS, PORTFOLIO__WATCH_CREATE_WALLET, PORTFOLIO__WATCH_CREATE_WALLET_ADDRESS,
    PORTFOLIO__WATCH__GENERATE_REPORT
} from 'actions/cabinet/portfolio-action'
// Worker
import {
    workerBalance, workerWallet, workerCurrency, workerCurrencyDetail, workerCurrencyDetailChart,
    workerCurrencyTransactions, workerCreateWallet, workerCreateWalletAddress, workerGenerateReport
} from './worker-saga'


function* watchBalance(): SagaIterator {
    yield takeLeading(PORTFOLIO__WATCH_BALANCE, workerBalance)
}

function* watchWallet(): SagaIterator {
    yield takeLeading(PORTFOLIO__WATCH_WALLET, workerWallet)
}

function* watchCurrency(): SagaIterator {
    yield takeEvery(PORTFOLIO__WATCH_CURRENCY, workerCurrency)
}

function* watchCurrencyDetail(): SagaIterator {
    yield takeEvery(PORTFOLIO__WATCH_CURRENCY_DETAIL, workerCurrencyDetail)
}

function* watchCurrencyDetailChart(): SagaIterator {
    yield takeEvery(PORTFOLIO__WATCH_CURRENCY_DETAIL_CHART, workerCurrencyDetailChart)
}

function* watchCurrencyTransactions(): SagaIterator {
    yield takeEvery(PORTFOLIO__WATCH_TRANSACTIONS, workerCurrencyTransactions)
}

function* watchCreateWallet(): SagaIterator {
    yield takeEvery(PORTFOLIO__WATCH_CREATE_WALLET, workerCreateWallet)
}

function* watchCreateWalletAddress(): SagaIterator {
    yield takeEvery(PORTFOLIO__WATCH_CREATE_WALLET_ADDRESS, workerCreateWalletAddress)
}

function* watchGenerateReport(): SagaIterator {
    yield takeEvery(PORTFOLIO__WATCH__GENERATE_REPORT, workerGenerateReport)
}

export default function* portfolioSagaWatcher(): SagaIterator {
    yield all([
        fork(watchBalance),
        fork(watchWallet),
        fork(watchCurrency),
        fork(watchCurrencyDetail),
        fork(watchCurrencyDetailChart),
        fork(watchCurrencyTransactions),
        fork(watchCreateWallet),
        fork(watchCreateWalletAddress),
        fork(watchGenerateReport)
    ])
}
