// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Tag} from 'antd'
// Ant Icon
import {CalendarOutlined} from '@ant-design/icons'
// Style
import './TransactionFilterTag.scss'
// Hook
import {useTransactionFilterTagEf} from './useTransactionFilterTagEf'
// Utils
import {config, selectCaseString} from 'utils'
// Type
import {
    TTransactionQueryFilter
} from 'types/cabinet/transactions'


type PropsType = {
	children?: never,
	transactionsFilter: TTransactionQueryFilter | null,
    transactionsCountFilter: number,
	handlerChangeFilterCount: (type: 'inc' | 'dec') => void,
	handlerSubmitFilter: (val: Omit<TTransactionQueryFilter, 'name'>) => void,
}

const TransactionFilterTag: FC<PropsType> = memo(({transactionsFilter, transactionsCountFilter, handlerChangeFilterCount, handlerSubmitFilter}) => {
	const {
		 currencyList, handlerUpdeteFilter
	} = useTransactionFilterTagEf({transactionsFilter, handlerSubmitFilter, handlerChangeFilterCount})


	return (
		<div className="portfolio-transactions__header_filter" style={{display: transactionsCountFilter ? 'block' : 'none'}}>
		{/*<div className="portfolio-transactions__header_filter">*/}
			{
				transactionsFilter?.currency?.length
					? transactionsFilter.currency.map (elemCur => {
						const currency = currencyList?.find(elem => +elem.id === +elemCur)

						return <>
							{ currency
								? (
									<Tag
										icon={<img src={config.app.apiURL + currency.icon} width={20} style={{marginRight: 6}} alt={'icon coin'} />}
										className={'portfolio-transactions__header_filter_item'}
										closable onClose={() => handlerUpdeteFilter('currency', currency.id)}
									>
										{currency.name}
									</Tag>
								) : ''
							}
						</>
					}) : ''
			}
			{
				transactionsFilter?.duration &&
					<Tag
						className={'portfolio-transactions__header_filter_item'}
						closable onClose={() => handlerUpdeteFilter('duration', '')}
					>
						{selectCaseString('lower', transactionsFilter?.duration, [0])}
					</Tag>
			}
			{
				transactionsFilter?.dateFrom && transactionsFilter.dateTo
					? (
						<Tag
							icon={<CalendarOutlined />}
							className={'portfolio-transactions__header_filter_item'}
							closable onClose={() =>handlerUpdeteFilter('date', '')}
						>
							{transactionsFilter.dateFrom} - {transactionsFilter.dateTo}
						</Tag>
					): ''
			}
			{
				transactionsFilter?.status
					? (
						<Tag
							className={'portfolio-transactions__header_filter_item'}
							closable onClose={() => handlerUpdeteFilter('status', '')}
						>
							{selectCaseString('lower', transactionsFilter.status, [0])}
						</Tag>
					) : ''
			}
			{
				transactionsFilter?.type
					? (
						<Tag
							className={'portfolio-transactions__header_filter_item'}
							closable onClose={() => handlerUpdeteFilter('type', '')}
						>
							{selectCaseString('lower', transactionsFilter.type, [0])}
						</Tag>
					) : ''
			}
		</div>
	)
})

export default TransactionFilterTag
