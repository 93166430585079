// Core
import React, {FC, memo} from 'react'
// Style
import './FavoritesStar.scss'


type PropsType = {
    children?: never,
    active?: boolean
    className?: string
    onClick?: () => void
}

const FavoritesStar: FC<PropsType> = memo(({active = false, className, onClick}) => ( <>
    {
        active
            ? (
                <svg width="20" className={`favorites-star ${className}`} onClick={onClick} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7365 6.89649L12.7775 6.17579L10.5607 1.68165C10.5001 1.5586 10.4005 1.45899 10.2775 1.39844C9.96888 1.2461 9.59388 1.37305 9.43958 1.68165L7.22278 6.17579L2.2638 6.89649C2.12708 6.91602 2.00208 6.98048 1.90638 7.07813C1.79068 7.19705 1.72692 7.35704 1.72912 7.52294C1.73132 7.68884 1.79929 7.84708 1.9181 7.9629L5.50599 11.4609L4.65833 16.4004C4.63845 16.5153 4.65117 16.6335 4.69503 16.7415C4.7389 16.8496 4.81216 16.9432 4.90651 17.0117C5.00086 17.0802 5.11252 17.1209 5.22884 17.1292C5.34515 17.1375 5.46146 17.113 5.56458 17.0586L10.0001 14.7266L14.4357 17.0586C14.5568 17.1231 14.6974 17.1445 14.8322 17.1211C15.172 17.0625 15.4005 16.7402 15.3419 16.4004L14.4943 11.4609L18.0822 7.9629C18.1798 7.86719 18.2443 7.74219 18.2638 7.60548C18.3165 7.26368 18.0783 6.94727 17.7365 6.89649Z" fill="#554CC2"/>
                </svg>
            )
            : (
                <svg width="20" className={`favorites-star ${className}`} onClick={onClick} height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.8416 6.45481L13.1742 5.63115L10.6408 0.494986C10.5716 0.354361 10.4577 0.240522 10.3171 0.171325C9.96442 -0.00278192 9.53584 0.142307 9.3595 0.494986L6.82602 5.63115L1.15861 6.45481C1.00236 6.47713 0.859504 6.55079 0.750129 6.6624C0.617901 6.7983 0.545037 6.98115 0.547548 7.17075C0.550059 7.36035 0.62774 7.5412 0.763522 7.67356L4.86397 11.6713L3.89522 17.3164C3.8725 17.4477 3.88703 17.5828 3.93717 17.7063C3.9873 17.8297 4.07103 17.9367 4.17885 18.015C4.28668 18.0933 4.4143 18.1399 4.54723 18.1494C4.68016 18.1588 4.81308 18.1309 4.93093 18.0686L10.0001 15.4035L15.0693 18.0686C15.2077 18.1423 15.3684 18.1669 15.5225 18.1401C15.9108 18.0731 16.172 17.7048 16.105 17.3164L15.1363 11.6713L19.2367 7.67356C19.3483 7.56418 19.422 7.42133 19.4443 7.26508C19.5046 6.87445 19.2323 6.51284 18.8416 6.45481ZM13.4108 11.1088L14.2166 15.803L10.0001 13.5887L5.78361 15.8053L6.58942 11.1111L3.1787 7.78517L7.89299 7.0999L10.0001 2.82981L12.1073 7.0999L16.8216 7.78517L13.4108 11.1088Z" fill="#A3A1B2"/>
                </svg>
            )

    }
</>))

export default FavoritesStar
