// Core
import React, {FC, memo} from 'react'
// Ant Components
import {} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'


type PropsType = {
    children?: never
}

const Income: FC<PropsType> = memo(() => {
  return <>
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16" cy="16" r="16" fill="#E2FBEB"/>
          <path d="M12.5581 11.8472L12.0416 19.4444C12.0369 19.5131 12.047 19.5821 12.0713 19.6468C12.0955 19.7114 12.1334 19.7701 12.1823 19.819C12.2311 19.8678 12.2898 19.9057 12.3545 19.9299C12.4191 19.9542 12.4881 19.9643 12.5568 19.9596L20.153 19.442C20.1759 19.4405 20.1978 19.4324 20.2162 19.4187C20.2346 19.405 20.2486 19.3863 20.2567 19.3648C20.2647 19.3433 20.2663 19.3199 20.2614 19.2975C20.2565 19.275 20.2452 19.2544 20.229 19.238L19.3649 18.374C19.3159 18.3249 19.2477 18.2992 19.1775 18.3033L14.1666 18.6442L20.139 12.6719C20.1856 12.6252 20.1854 12.5486 20.1385 12.5017L19.4985 11.8617C19.4515 11.8147 19.3749 11.8145 19.3283 11.8612L13.3559 17.8336L13.6969 12.8226C13.702 12.7535 13.6763 12.6854 13.6261 12.6352L12.7621 11.7712C12.6896 11.6987 12.5653 11.744 12.5581 11.8472Z" fill="#358F54"/>
      </svg>
  </>
})

export default Income
