// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button as ButtonAnt, Modal, Table} from 'antd'
// Ant Icon
import {LoadingOutlined} from '@ant-design/icons'
// Style
// Hook
import {useSendAssetsConfirmEf} from './useSendAssetsConfirmEf'
import * as Yup from "yup";
import {Formik} from "formik";
import {Form, Input} from "formik-antd";
import {FormikHandler} from "../../../../../../components";
// Elements
import {Button} from "elements"


type PropsType = {
    children?: never,
}

const PasswordSchema = Yup.object().shape({
    password: Yup.string()
        .required('Required')
})

const SendAssetsConfirm: FC<PropsType> = memo(() => {
    const {
        mode, sendAssetsData, password, loadSendAssets, formErrors,
        handlerClose, handlerSubmitPassword, handlerChangePassword
    } = useSendAssetsConfirmEf()

    const { Column } = Table;

    const init = {
        password: ''
    }

    return <>
        <Modal
            title={'Send Assets'}
            centered
            visible={mode}
            onCancel={handlerClose}
            width={450}
            footer={false}
        >
            <span className={'send-assets__modal-title'}>Transaction details</span>
            {
                sendAssetsData &&
                <Table
                    dataSource={[sendAssetsData]}
                    pagination={false}
                    rowKey="wallet_id"
                    className={'base-table base-table__vertical base-table__send-assets transactions'}
                >
                    <Column
                        title={'From'}
                        key={'address'}
                        fixed={'left'}
                        width={200}
                        render={(value) => <p className={'send-assets__table-desc'}>Bitcoin wallet address: <br/><span className={'send-assets__table-val'}>{value.address.toLowerCase()}</span></p>}
                    />
                    <Column
                        title={'To'}
                        key={'address_to'}
                        width={200}
                        render={(value) => <p className={'send-assets__table-desc'}>Bitcoin wallet address: <br/><span className={'send-assets__table-val'}>{value.address_to.toLowerCase()}</span></p>}
                    />
                    <Column
                        title={'Amount'}
                        key={'amount'}
                        width={200}
                        render={
                            (value) => <p className={'send-assets__table-amount'}>{value.amount} {value.crypto_name}<br/>
                                            <span className={'send-assets__table-fiat'}>= {value.amount_fiat} {value.fiat_sign}</span>
                                        </p>
                        }
                    />
                    <Column
                        title={'Commission payer'}
                        key={'tax_from'}
                        width={200}
                        render={(value) => `${value.tax_from}`}
                    />
                </Table>
            }
            <Formik onSubmit={handlerSubmitPassword} initialValues={init} validationSchema={PasswordSchema}>
                {({errors, values, touched}) => (
                    <Form className={'sign__form'}>
                        <div className={'cab-settings__item'}>
                            <Form.Item
                                name={'password'}
                                label={'Enter password for confirmation'}
                                labelCol={{span: 24}}
                                wrapperCol={{span: 24}}
                                // validateStatus={touched && touched.password ? 'error' : ''}
                                hasFeedback={false}
                            >
                                <Input.Password
                                    // onFocus={handlerFocus}
                                    // onBlur={handlerBlur}
                                    //disabled={loadForm}
                                    className={'input-item medium'}
                                    size={'middle'}
                                    name='password'
                                    onChange={(e) => handlerChangePassword(e)}
                                    placeholder={'Enter current password'}
                                />
                            </Form.Item>
                        </div>
                        <FormikHandler data={formErrors} />
                        <div className={'send-assets__submits'}>
                            <ButtonAnt className={'default secondary select-recipient__button'} onClick={handlerClose}>
                                Go back
                            </ButtonAnt>
                            <Button
                                className={'send-assets__submit'}
                                type="submit"
                                size={"full"}
                                color={"secondary"}
                                variant={"contained"}
                                disabled={!password}
                                loaderPosition={"center"}
                                loader={
                                    loadSendAssets
                                        ? () => <LoadingOutlined className={'sign__loader-icon'} spin/>
                                        : null
                                }
                            >
                                Confirm
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    </>
})

export default SendAssetsConfirm
