// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Empty, Skeleton, Table} from 'antd'
// Ant Icon
import {FileOutlined, GlobalOutlined} from '@ant-design/icons'
// Element
import {Typography} from 'elements'
import {NodeError} from '#/elements'
import {Overview as OverviewElement} from "#/elements"
// Style
import './Overview.scss'
// Hook
import {useOverviewEf} from './useOverviewEf'
// Util
import {config} from 'utils'
// Type
import {TPriceCorrelation} from 'types/cabinet/portfoli-type'
import {Chart} from '#/components'
import {useParseToCurrency} from 'hooks'
import {EmptyStatus} from "../../../../../../icons";


type PropsType = {
	children?: never
}

type TColumns = {
	load: boolean
	symbol: string
}

const columns = ({load, symbol,}: TColumns) => ([
	{
		title: 'Assets Name',
		dataIndex: 'name',
		width: 150,
		key: 'name',
		align: 'left',
		render: (text: string, record: TPriceCorrelation) => load
			? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
			: (<>
				<img width={24} src={config.app.apiURL + record.icon} alt={`${record.full_name} icon`} />
				{record.full_name}
			</>)
	},
	{
		title: 'Price',
		dataIndex: 'price',
		key: 'price',
		align: 'right',
		render: (text: string, record: TPriceCorrelation) => load
			? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
			: `${symbol} ${record.value}`
	},
	{
		title: '%',
		dataIndex: 'percent',
		key: 'percent',
		align: 'right',
		render: (text: string, record: TPriceCorrelation) => load
			? <Skeleton.Input active={true} style={{width: 100}} size={'small'} />
			: `${record.percent} %`
	},
])

const Overview: FC<PropsType> = memo(() => {
	const {
		loadFirst, loadCurrencyDetail, currencyDetailsData, currencies, currencyDetails,
		loadCurrencyDetailChart, currencyDetailsChart, currencyDetailsChartDuration,
		handlerWalletResent, handlerResend, handlerChange, handlerWalletResentChart,
	} = useOverviewEf()

	const parseValue = useParseToCurrency(currencyDetailsChart ? currencyDetailsChart.base_fiat_sign : '$')

	return (
		<div className={'portfolio-overview'}>
			<div className={'portfolio-overview__info'}>
				<div className={'portfolio-overview__info__about'}>
					<div className={'portfolio-overview__info__about__title'}>
						{
							currencyDetailsData && !loadCurrencyDetail
								? (
									<Typography type={'text-18'} weight={'medium'} color={'primary'}>
										About {currencyDetailsData.full_name}
									</Typography>
								)
								: <Skeleton.Input active style={{width: 150}} />
						}
					</div>
					<div className={'portfolio-overview__info__about__body'}>
						{
							currencyDetailsData && !loadCurrencyDetail
								? (
									<Typography type={'text-16'} color={'primary'}>
										{currencyDetailsData.about}
									</Typography>
								)
								: (
									<div style={{display: 'flex', flexDirection: 'column'}}>
										<Skeleton.Input active />
										<Skeleton.Input active style={{marginTop: 20}}/>
									</div>
								)
						}
					</div>
					<div className={'portfolio-overview__info__about__footer'}>
						<div className={'portfolio-overview__info__about__footer__item'}>
							{
								currencyDetailsData && !loadCurrencyDetail
									? (<>
										<GlobalOutlined className={'portfolio-overview__info__about__footer__item__icon'} />
										<Typography className={'portfolio-overview__info__about__footer__item__title'} type={'link-16'} color={'secondary'}>
											<a href={currencyDetailsData.website} target={'_blank'}>Official website</a>
										</Typography>
									</>)
									: <Skeleton.Input active className={'portfolio-overview__info__about__footer__item__title'} />
							}
						</div>
						<div className={'portfolio-overview__info__about__footer__item'}>
							{
								currencyDetailsData && !loadCurrencyDetail
									? (<>
										<FileOutlined className={'portfolio-overview__info__about__footer__item__icon'} />
										<Typography className={'portfolio-overview__info__about__footer__item__title'} type={'link-16'} color={'secondary'}>
											<a href={currencyDetailsData.white_paper} target={'_blank'}>WhitePaper</a>
										</Typography>
									</>)
									: <Skeleton.Input active className={'portfolio-overview__info__about__footer__item__title'} />
							}
						</div>
					</div>
				</div>
				<div className={'portfolio-overview__info__price'}>
					{
						currencyDetailsData || !loadFirst.includes('currency-detail')
							? (<>
								<Typography className={'portfolio-overview__info__price__header'} type={'title-18'} weight={'medium'} color={'primary'}>
									Price correlation with
								</Typography>
								<div className={'portfolio-overview__correlation-body'}>
								{
									currencies?.length ?
										<Table
											className={'base-table portfolio-wallet__table base-table__portfolio base-table__portfolio-correlation'}
											loading={loadCurrencyDetail && loadFirst.includes('currency-detail')}
											scroll={{ y: 140}}
											pagination={false}
											//@ts-ignore
											columns={columns({
												load: !loadFirst.includes('currency-detail'),
												symbol: currencyDetailsData ? currencyDetailsData.base_fiat_sign : '',
											})}
											dataSource={currencies}
										/>
									:
										<NodeError
											errorImage={<Empty/>}
											description={''}
										/>
								}
								</div>
							</>)
							: <NodeError withHandler={true} handler={handlerWalletResent} />
					}
				</div>
			</div>
			<div className={'portfolio-overview__chart'}>
				{
					currencyDetailsChart || (loadCurrencyDetailChart && !currencyDetailsChart)
						? (
							<div className={'price-chart__body'}>
								<div className={'price-chart__chart'}>
									<Chart
										data={currencyDetailsChart ? currencyDetailsChart.chart : []}
										title={'Price Chart'}
										currentDuration={currencyDetailsChartDuration}
										parseFunction={parseValue}
										handlerChangeDuration={(e) => handlerChange(e.target.value)}
										handlerRequestChartData={handlerResend}
										chartLoading={!loadFirst.includes('currency-detail')}
										updatingChart={loadCurrencyDetailChart}
									/>
								</div>
								<div className={'price-chart__overview'}>
									{
										currencyDetails && (
											<OverviewElement
												data={currencyDetailsChart && currencyDetailsChart.overview ? currencyDetailsChart.overview : null}
												parseFunction={parseValue}
												currency={{
													name: currencyDetailsChart && currencyDetailsChart.base_fiat_name || '',
													sign: currencyDetailsChart && currencyDetailsChart.base_fiat_sign || ''
												}}
												crypto={{
													name: currencyDetails.name,
													full_name: currencyDetails.full_name,
													id: currencyDetails.cur_id,
													icon: '',
													sign: currencyDetailsChart ? currencyDetailsChart.base_fiat_sign : '$',
												}}
												loadOverview={loadCurrencyDetailChart}
											/>
										)
									}
								</div>
							</div>
						)
						: <NodeError withHandler={true} handler={handlerWalletResentChart} />
				}
			</div>
		</div>
	)
})

export default Overview
