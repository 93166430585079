// Core
import React, {FC, memo} from 'react'
import clsx from "clsx"
import useScreen from "use-screen"
// Ant Component
import {Col, Row} from "antd"
// Style
import './Auth.scss'
// Elements
import {Container} from 'elements/index'
// Components
import {Logo} from 'components'
// Assets
import leptopSrc from 'assets/img/leptop.svg'
// Hooks
import {useCheckPage} from "hooks";


type PropsTYpe =  {
    className?: string
}

const Auth: FC<PropsTYpe> = memo(({children, className = ''}) => {
    const {screenWidth } = useScreen()
    const {shadow} = useCheckPage()

    return (<>
        <header className={clsx('header', {'shadow': shadow})}>
            <Container className={'auth__header-content header__container'}>
                <Row align={'middle'}>
                    <Col xs={8} sm={12} xl={14} xxl={12}>
                        <Row align={'middle'}>
                            <Col className={'header__logo-wrapper'}>
                                <Logo hide={(screenWidth < 450)} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </header>

        <section className={'auth'}>
            <Container type={"div"} className={'auth__content'}>
                <div className={clsx("auth__wrapper", className)}>
                    {children}
                </div>
            </Container>
            <img className={clsx('auth_leptop')} src={leptopSrc} alt={''}/>
            <div className={'auth__backing'}/>
        </section>
    </>)
})

export default Auth
