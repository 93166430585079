// Core
import produce, {Draft} from 'immer'
// Utils
import {config} from 'utils'
// Type
import {CabinetActionReducerType} from 'actions/cabinet/cab-main-action'
import {
    ICurrencyItem, IExportAddressData,
    IFavoritesItem, ITransactionsSendAssets, ITransactionsSendData,
    MenuList,
    TCurrencyList, TExportAddressData,
    TFavoritesItem, TNotification, TReceiveAssetsConfig,
    TWalletAddresses
} from 'types/cabinet/cabinet-main-type'
import {TWalletAddress} from "../../types/cabinet/portfoli-type";


const initialState = {
    form_errors: null as any,
    menu_mode: null as MenuList | null,
    sider_mode: false,
    auto_logout: null as null | number,
    currency_list_crypto: null as null | IFavoritesItem[],
    currency_list_fiat: null as null | ICurrencyItem[],
    currency_items: null as null | IFavoritesItem[],
    currency_favorites: null as null | IFavoritesItem[],
    send_asset_mode: false,
    receive_asset_mode: false,
    receive_asset_only_qr: false,
    receive_assets_config: null as null | TReceiveAssetsConfig,
    receive_assets_title: null as null | string,
    notifications: null as null | TNotification[],
    notification_mode: false,
    send_assets_data: null as null | ITransactionsSendAssets,
    wallet_addresses: null as null | TWalletAddresses,
    wallet_addresses_mode: false as boolean,
    wallet_address_recipient: null as null | string,
    send_assets_errors: null as any,
    search_recipient: '' as string,
    import_address: false as boolean,
    import_address_errors: null as null | any,
    export_address: null as null | IExportAddressData,
    export_address_errors: null as null | any,
    help_tab: undefined as undefined | string,
    // Loader
    load_form: false as boolean,
    load_currency_list_crypto: false as boolean,
    load_currency_list_fiat: false as boolean,
    load_wallet_addresses: false as boolean,
    load_send_assets: false as boolean,
    load_import_address: false as boolean,
    load_export_address: false as boolean,
    favoritesLocal: JSON.parse(localStorage.getItem('favorites') || "null")
}

type InitialStateType = typeof initialState

export const cabMainReducer = (state: InitialStateType = initialState, action: CabinetActionReducerType): InitialStateType => {
    return produce(state, (draft: Draft<InitialStateType>) => {
        switch (action.type) {
            // Setting
            case "CABINET__SET__ERROR_FORM":
                draft.form_errors = action.payload.data
                break
            case "CABINET__SET__MENU_MODE":
                draft.menu_mode = action.payload.mode
                break
            case "CABINET__SET__SIDER_MODE":
                draft.sider_mode = action.payload.mode
                break
            case "CABINET__SET__AUTO_LOGOUT":
                draft.auto_logout = action.payload.second
                break
            case "CABINET__SET__CURRENCY_LIST_CRYPTO":
                draft.currency_list_crypto = action.payload.data
                break
            case "CABINET__SET__CURRENCY_LIST_FIAT":
                draft.currency_list_fiat = action.payload.data
                break
            case "CABINET__SET__FAVORITES":
                draft.currency_favorites = action.payload.data
                break;
            case "CABINET__SET__FAVORITES_LOCAL":
                localStorage.setItem('favorites', JSON.stringify(action.payload.data))
                break
            case "CABINET__REMOVE_FAVORITES_LOCAL":
                localStorage.removeItem('favorites')
                break
            case "CABINET__SET__CURRENCY_ITEMS":
                draft.currency_items = action.payload.data
                break
            case "CABINET__SET__SEND_ASSET_MODE":
                draft.send_asset_mode = action.payload.mode
                break
            case "CABINET__SET__RECEIVE_ASSET_MODE":
                draft.receive_asset_mode = action.payload.mode
                draft.receive_asset_only_qr = action.payload.onlyQrCode || false
                draft.receive_assets_config = action.payload.config || null
                draft.receive_assets_title = action.payload.title || null
                break
            case "CABINET__SET__NOTIFICATION_MODE":
                draft.notification_mode = action.payload.mode
                break
            case "CABINET__SET__WALLET_ADDRESSES":
                draft.wallet_addresses = action.payload.data
                break
            case "CABINET__SET__WALLET_ADDRESSES_MODE":
                draft.wallet_addresses_mode = action.payload.mode
                break
            case "CABINET__SET__WALLET_ADDRESS_RECIPIENT":
                draft.wallet_address_recipient = action.payload.address
                break
            case "CABINET__SET__SEND_ASSETS_DATA":
                draft.send_assets_data = action.payload.data
                break
            case "CABINET__SET__SEND_ASSETS_ERRORS":
                draft.send_assets_errors = action.payload.data
                break
            case "CABINET__SET__SEARCH_RECIPIENT":
                draft.search_recipient = action.payload.value
                break
            case "CABINET__SET__IMPORT_ASSETS":
                draft.import_address = action.payload.mode
                break
            case "CABINET__SET__EXPORT_ASSETS":
                draft.export_address = {mode: action.payload.data.mode, config: action.payload.data.config}
                break
            case "CABINET__SET__HELP_TAB":
                draft.help_tab = action.payload.mode
                break
            case "CABINET__SET__IMPORT_ADDRESS_ERRORS":
                draft.import_address_errors = action.payload.data
                break
            case "CABINET__SET__EXPORT_ADDRESS_ERRORS":
                draft.export_address_errors = action.payload.data
                break
            case "CABINET__SET__NOTIFICATIONS":
                draft.notifications = action.payload.data
                break
            // Create

            // Update
            case "CABINET__TICK__AUTO_LOGOUT":
                if (draft.auto_logout) {
                    draft.auto_logout = draft.auto_logout - 1
                } else {
                    return state
                }
                break
            case "CABINET__ADD__FAVORITES":
                draft.currency_favorites ?  draft.currency_favorites.push(action.payload.data) : draft.currency_favorites = [action.payload.data]
                // get removed currency item
                const removedCurrencyItem = draft.currency_items?.filter(item => item.id !== action.payload.data.id)
                draft.currency_items = removedCurrencyItem ? removedCurrencyItem : draft.currency_items
                // save all changes to favorites local
                localStorage.setItem('favorites', JSON.stringify(draft.currency_favorites))
                break
            case "CABINET__UPDATE__FAVORITES":
                draft.currency_favorites = action.payload.data
                break
            case "CABINET__UPDATE__WALLET_ADDRESSES":
                draft.wallet_addresses &&  draft.wallet_addresses.items.push(...action.payload.data.items)
                draft.wallet_addresses && (draft.wallet_addresses.pagination = action.payload.data.pagination)
                break
            case "CABINET__REMOVE__FAVORITES":
                draft.currency_items ? draft.currency_items.push(action.payload.data) : draft.currency_items = [action.payload.data]
                // remove favorites item
                const removedFavorites = draft.currency_favorites?.filter(item => item.id !== action.payload.data.id)
                draft.currency_favorites = removedFavorites ? removedFavorites : draft.currency_favorites
                // save all changes to favorites local
                localStorage.setItem('favorites', JSON.stringify(draft.currency_favorites))
                break
            // Delete
            case "CABINET__REMOVE__CURRENCY_LIST_CRYPTO":
                let cryptoList = draft.currency_list_crypto

                cryptoList = cryptoList && cryptoList?.filter(item => item.id !== action.payload.id)

                draft.currency_list_crypto = cryptoList
                break
            // Load
            case "CABINET__LOAD__FORM":
                draft.load_form = action.payload.mode
                break
            case "CABINET__LOAD__WALLET_ADDRESSES":
                draft.load_wallet_addresses = action.payload.mode
                break
            case "CABINET__LOAD__SEND_ASSETS":
                draft.load_send_assets = action.payload.mode
                break
            case "CABINET__LOAD__IMPORT_ADDRESS":
                draft.load_import_address = action.payload.mode
                break
            case "CABINET__LOAD__EXPORT_ADDRESS":
                draft.load_export_address = action.payload.mode
                break

            // Clear
            case "CABINET__CLEAR__MENU_MODE":
                draft.menu_mode = 'home'
                break
            case "CABINET__LOAD__CURRENCY_LIST_CRYPTO":
                draft.load_currency_list_crypto = action.payload.mode
                break
            case "CABINET__LOAD__CURRENCY_LIST_FIAT":
                draft.load_currency_list_fiat = action.payload.mode
                break
            // Async
            case "CABINET__WATCH__CURRENCY_LIST": return state
            case "CABINET__WATCH__FAVORITES": return state
            case "CABINET__WATCH__ADD_FAVORITES": return state
            case "CABINET__WATCH__REMOVE_FAVORITES": return state
            case "CABINET__WATCH__ADDRESSES": return state
            case "CABINET__WATCH__SEND_ASSETS": return state
            case "CABINET__WATCH__SAVE_ADDRESS": return state
            case "CABINET__WATCH__IMPORT_ADDRESS": return state
            case "CABINET__WATCH__EXPORT_ADDRESS": return state
            case "CABINET__WATCH__NOTIFICATIONS": return state
            case "CABINET__WATCH__READ_NOTIFICATIONS": return state
            // Def
            default:
                const allAction:never = action // check use all action
                return state
        }
    })
}
