// Core
import {ChangeEvent, useEffect, useRef, useState} from 'react'
// Type
import {
    TTransactionQueryFilter
} from 'types/cabinet/transactions'

type TTransactionsTableEf = {
    searchTransactions: (search: string | null) => void,
    transactionsFilter: TTransactionQueryFilter | null
}

export const useTransactionsTableEf = ({searchTransactions, transactionsFilter}: TTransactionsTableEf) => {
    const timer = useRef<any>(null)

    const [searchAction, setSearchAction] = useState(false)
    const [search, setSearch] = useState<string | null>(transactionsFilter?.name || '')

    // effects

    useEffect(() => {
        searchAction && handlerTableSearch()
    }, [searchAction])

    // handlers

    const handlerTableSearch = () => {
        clearTimeout(timer.current)
        setSearchAction(false)

        timer.current = setTimeout(() => {
            searchTransactions(search)
        }, 500)
    }

    const handlerSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value.length ? e.target.value : null)
        setSearchAction(true)
    }


    return {
        search, handlerSearch
    }
}
