// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {cabinetMainSelect} from "../../../../selectors/cabinet";
import {cabinetMainActions} from "../../../../actions/cabinet";
import {appActions} from "../../../../actions";
import {watch} from "fs";


export const useExportAddressEf = () => {
    const dispatch = useDispatch()


    // Selectors

    const exportAddress = useSelector(cabinetMainSelect.export_address)
    const loadExport = useSelector(cabinetMainSelect.load_export_address)
    const errors = useSelector(cabinetMainSelect.export_address_errors)

    // State

    const mode = exportAddress?.mode
    const config = exportAddress?.config


    // Handlers

    const handlerClose = () => {
        dispatch(cabinetMainActions.set_export_assets({mode: false, config: null}))
    }

    const handlerSubmitDefault = (values: { password: string }) => {
        config && dispatch(cabinetMainActions.watch_export_address({password: values.password, address: config?.address}))
    }

    const handlerSubmitEmail = (values: {code: string}) => {
        config?.confirmData
            && dispatch(cabinetMainActions.watch_export_address({
                password: config?.password || '',
                address: config?.address,
                code: values.code,
                hash: config.confirmData.hash
            }))
    }

    const handlerSubmitGoogle = (values: {code: string}) => {
        config?.confirmData
            && dispatch(cabinetMainActions.watch_export_address({
                password: config?.password || '',
                address: config?.address,
                code: values.code,
                hash: config.confirmData.hash
            }))
    }

    const handlerFullClose = () => {
        (exportAddress?.config &&  exportAddress?.config.finalData)
        && dispatch(cabinetMainActions.set_export_assets({
            mode: true,
            config: {
                type: exportAddress?.config?.type,
                address: exportAddress?.config?.address,
                finalData: {
                    address: exportAddress?.config.finalData?.address,
                    private_key: exportAddress?.config.finalData?.private_key
                }
        }}))
        dispatch(cabinetMainActions.set_receive_asset_mode(false, true))
    }

    const handlerQr = (address: string, title: string) => {
        exportAddress?.config
            ? dispatch(cabinetMainActions.set_receive_asset_mode(true, true,
            {showSelector: false, address,
                handlerClose:  handlerFullClose}, title))
            : dispatch(cabinetMainActions.set_receive_asset_mode(true, true, {showSelector: false, address}, title))
        handlerClose()
    }

    const handlerCopy = () => {
        dispatch(appActions.set_push({
            title: 'Success',
            content: 'Wallet address has been successfully copied'
        }))
    }

    return {
        mode, config, loadExport, errors,
        handlerClose, handlerSubmitDefault, handlerSubmitEmail,
        handlerSubmitGoogle, handlerQr, handlerCopy
    }
}
