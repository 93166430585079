// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button as ButtonAnt, Image, Modal} from 'antd'
// Hook
import {useSuccessTransactionsEf} from './useSuccessTransactionsEf'
// Elements
import {Button, Typography} from "../../../../../../elements";
// Assets
import transactionsImage from "assets/img/transactions-image.svg";
import SavedAddressesModalForm from "#/components/saved-addresses-modal-form/SavedAddressesModalForm";


type PropsType = {
    children?: never,
    handlerFullClose: () => void
}

const SuccessTransactions: FC<PropsType> = memo(({handlerFullClose}) => {
    const {
        mode, sendAssetsData, save,
        handlerCloseModal, handlerSaveAddress, handlerCloseSaveAddressModal
    } = useSuccessTransactionsEf({handlerFullClose})

    return <>
        <Modal
            title={'Transaction in progress'}
            centered
            visible={mode}
            onCancel={handlerCloseModal}
            className={'cab-settings__closeacc-modal'}
            width={450}
            footer={false}
        >
            <Image width={108} height={108} src={transactionsImage} preview={false} className={'cab-settings__error-img'}/>
            <Typography type={'title-18'} weight={'semibold'} color={'primary'} className={'cab-settings__closeacc-title'}>Your thansaction is on the way!</Typography>
            <p className={'send-assets__modal-text'}>You send {sendAssetsData?.amount + ' ' + sendAssetsData?.crypto_name} ({sendAssetsData?.fiat_sign + ' ' + sendAssetsData?.amount_fiat}) to address {sendAssetsData?.address_to}. You can save this address for next transactions</p>
            <div className={'send-assets__submits'}>
                <ButtonAnt className={'default secondary select-recipient__button'} onClick={handlerSaveAddress}>
                    Save address
                </ButtonAnt>
                <Button
                    className={'send-assets__submit'}
                    size={"full"}
                    color={"secondary"}
                    variant={"contained"}
                    onClick={handlerCloseModal}
                >
                    Done
                </Button>
            </div>
        </Modal>
        {
            sendAssetsData &&
                <SavedAddressesModalForm
                    id={sendAssetsData?.wallet_currency_id}
                    address_to={sendAssetsData?.address_to}
                    mode={save}
                    handlerClose={handlerCloseSaveAddressModal}
                />
        }
    </>
})

export default SuccessTransactions
