// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Popover, List, Button} from 'antd'
// Ant Icon
import {InfoCircleFilled, InfoCircleOutlined, InfoCircleTwoTone, InfoOutlined} from '@ant-design/icons'
// Style
import './Notification.scss'
// Hook
import {useNotificationEf} from './useNotificationEf'
// Element
import {Typography} from "elements"
// Icon
import {IncomeIcon, NotNotification, OutcomeIcon} from 'icons'
import PerfectScrollbar from 'react-perfect-scrollbar'



const Notification: FC = memo(({children}) => {
    const {
        notificationMode, notifications,
        handlerNotification, handlerReadAll
    } = useNotificationEf()

    function NotificationsIcon(props: {type: string}) {

        return (
            <>
                {
                    props.type.toLowerCase() === 'income' && <IncomeIcon/>
                }
                {
                    props.type.toLowerCase() === 'outcome' && <OutcomeIcon/>
                }
                {
                    props.type.toLowerCase() === 'info' && <span className={'header-notification__icon header-notification__icon_info'}><InfoOutlined/></span>
                }
            </>
        )
    }

    return (
        <Popover
            className={'header-notification'}
            overlayClassName={'header-notification_wrapper'}
            placement="bottomRight"
            trigger="click"
            visible={notificationMode}
            onVisibleChange={handlerNotification}
            title={(
                <div className={'header-notification__title'}>
                    <Typography type={"title-18"} color={"primary"}>
                        Notification
                    </Typography>
                    <Button type="link" className={'ant-btn-default'} onClick={handlerReadAll} disabled={!notifications?.length}>Mark all as read</Button>
                </div>
            )}
            content={(<>
                {
                    notifications?.length ?
                        <>
                            <PerfectScrollbar>
                                <List
                                    itemLayout="horizontal"
                                    dataSource={notifications}
                                    style={{height: 300}}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<NotificationsIcon type={item.type}/>}
                                                title={item.type}
                                                description={<div dangerouslySetInnerHTML={{ __html: item.description }}></div>}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </PerfectScrollbar>
                        </>
                        :
                        <div className={'header-notification__empty'}>
                            <NotNotification/>
                            <Typography style={{marginTop: 20}} type={"text-16"} color={"primary"}>
                                No notification
                            </Typography>
                        </div>
                }
            </>)}
        >
            {children}
        </Popover>
    )
})

export default Notification
