// Core
import produce, {Draft} from 'immer'
// Utils
import {config} from 'utils'
// Type
import { PortfolioActionReducerType } from 'actions/cabinet/portfolio-action'
import {TCurrencyDuration, TWallet, TCurrency, IBalance, TLoadFirst, TCurrencyDetail, TCurrencyDetailData, TCurrencyData} from "types/cabinet/portfoli-type"
import {EWalletDuration, TChart} from "types/cabinet/cabinet-main-type"
import {TTransactionData, TTransactionQueryFilter, TTransactionQueryBase,} from 'types/cabinet/transactions'
import {log} from "util";


const initialState = {
    tab: config.cabinet.portfolio.tab[0] as string,
    currency_details: null as null | TCurrencyDetail,
    balance_duration: EWalletDuration.DAY as EWalletDuration,
    currency_duration: [] as TCurrencyDuration[],
    currency_details_chart_duration: EWalletDuration.HOUR as EWalletDuration,
    generate_report_mode: false,
    add_wallet__mode: false,
    wallet_address_qr_mode: false,
    balance: null as null | IBalance,
    chart: null as null | TChart[],
    wallets: null as null | TWallet,
    currencies: [] as TCurrency[],
    empty_balance_mode: false,
    search_wallet: '',
    add_search_wallet: '',
    currency_details_data: null as null | TCurrencyDetailData,
    currency_details_chart: null as null | TCurrencyData,
    transactions_filter_mode: false,
    transactions: null as TTransactionData | null,
    transactions_count_filter: 0,
    transactions_base_query: {
        page: 1,
        pageSize: '10',
        sort: null,
    } as TTransactionQueryBase,
    transactions_filter: {
        name: null,
        duration: null,
        dateFrom: null,
        dateTo: null,
        type: null,
        status: null,
        currency: [],
    } as TTransactionQueryFilter,
    // Error

    // Load
    load_first: [] as TLoadFirst[],
    load_first_currency: [] as string[],
    load_balance_chart: false,
    load_wallet: false,
    load_currency: [] as string[],
    load_currency_detail: false,
    load_currency_detail_chart: false,
    load_transactions: false,
    load_wallet_create: false,
    load_add_address: false
}

type InitialStateType = typeof initialState

export const portfolioReducer = (state: InitialStateType = initialState, action: PortfolioActionReducerType): InitialStateType => {
    return produce(state, (draft: Draft<InitialStateType>) => {
        switch (action.type) {
            // Setter
            case "PORTFOLIO__SET__TAB":
                draft.tab = action.payload.mode
                break
            case 'PORTFOLIO__SET__CURRENCY_DETAIL':
                draft.currency_details = action.payload.currency
                break
            case 'PORTFOLIO__SET__CURRENCY_DETAIL_CHART_DURATION':
                draft.currency_details_chart_duration = action.payload.duration
                break
            case 'PORTFOLIO__SET__CURRENCY_DETAIL_CHART':
                draft.currency_details_chart = action.payload.data
                break
            case "PORTFOLIO__SET__BALANCE_DURATION":
                draft.balance_duration = action.payload.duration
                break
            case "PORTFOLIO__SET__GENERATE_REPORT_MODE":
                draft.generate_report_mode = action.payload.mode
                break
            case "PORTFOLIO__SET__ADD_WALLET_MODE":
                draft.add_wallet__mode = action.payload.mode
                break
            case 'PORTFOLIO__SET__ADD_WALLET_ADDRESS_QR_MODE':
                draft.wallet_address_qr_mode = action.payload.mode
                break
            case "PORTFOLIO__SET__BALANCE_CHART":
                const {chart, overview, ...rest} = action.payload.data

                draft.chart = chart
                draft.balance = {
                    ...rest,
                    ...overview,
                }
                break
            case "PORTFOLIO__SET__EMPTY_BALANCE_MODE":
                draft.empty_balance_mode = action.payload.mode
                break
            case "PORTFOLIO__SET__SEARCH_WALLET":
                draft.search_wallet = action.payload.val
                break
            case "PORTFOLIO__SET__ADD_SEARCH_WALLET":
                draft.add_search_wallet = action.payload.val
                break
            case "PORTFOLIO__SET__WALLETS":
                draft.wallets = action.payload.data
                break
            case 'PORTFOLIO__SET__TRANSACTIONS_FILTER_MODE':
                draft.transactions_filter_mode = action.payload.mode
                break
            case 'PORTFOLIO__SET__TRANSACTIONS':
                draft.transactions = action.payload.data
                break
            case 'PORTFOLIO__SET__TRANSACTIONS_BASE_QUERY':
                draft.transactions_base_query = {...draft.transactions_base_query, ...action.payload.data}
                break
            case 'PORTFOLIO__SET__TRANSACTIONS_FILTER':
                draft.transactions_filter = {...draft.transactions_filter, ...action.payload.data}
                break
            case 'PORTFOLIO__ADD__COUNT_FILTER':
                if (typeof action.payload.count !== 'number') return state
                draft.transactions_count_filter = draft.transactions_count_filter + action.payload.count
                break
            case "PORTFOLIO__SET_FILTER_COUNT":
                    draft.transactions_count_filter = action.payload.count
                    break
            // Load
            case "PORTFOLIO__LOAD__BALANCE_CHART":
                draft.load_balance_chart = action.payload.mode
                break
            case "PORTFOLIO__LOAD__FIRST":
                if (!draft.load_first.includes(action.payload.load)) {
                    draft.load_first.push(action.payload.load)
                } else {
                    return state
                }
                break
            case "PORTFOLIO__LOAD__WALLET":
                draft.load_wallet = action.payload.mode
                break
            case "PORTFOLIO__LOAD__CURRENCY":
                draft.load_currency.includes(action.payload.id)
                    ? (draft.load_currency = draft.load_currency.filter(elem => elem !== action.payload.id))
                    : draft.load_currency.push(action.payload.id)
                break
            case "PORTFOLIO__LOAD__FIRST_CURRENCY":
                draft.load_first_currency.includes(action.payload.id)
                    ? (draft.load_first_currency = draft.load_first_currency.filter(elem => elem !== action.payload.id))
                    : draft.load_first_currency.push(action.payload.id)
                break
            case 'PORTFOLIO__LOAD__CURRENCY_DETAIL':
                draft.load_currency_detail = action.payload.mode
                break
            case 'PORTFOLIO__LOAD__CURRENCY_DETAIL_CHART':
                draft.load_currency_detail_chart = action.payload.mode
                break
            case 'PORTFOLIO__LOAD__TRANSACTIONS':
                draft.load_transactions = action.payload.mode
                break
            // Create, Add
            case "PORTFOLIO__ADD__CURRENCY":
                draft.currencies.push(action.payload.data)
                break
            case "PORTFOLIO__ADD__CURRENCY_DURATION":
                draft.currency_duration.push(action.payload.data)
                break
            case "PORTFOLIO__ADD_WALLET_ADDRESS":
                draft.currency_details_data?.addresses.push(action.payload.data)
                break
            case 'PORTFOLIO__SET__CURRENCY_DETAIL_DATA':
                draft.currency_details_data = action.payload.data
                break
            case "PORTFOLIO__LOAD__CREATE_WALLET":
                draft.load_wallet_create = action.payload.mode
                break
            case "PORTFOLIO__LOAD__CREATE_WALLET_ADDRESS":
                draft.load_add_address = action.payload.mode
                break
            // Update
            case "PORTFOLIO__UPDATE__WALLETS_FAVORITES":
                if (!draft.wallets) return state

                const indexWallets = draft.wallets ? draft.wallets.items.findIndex(elem => elem.cur_id === action.payload.id) : -1

                if (indexWallets === -1) return state

                draft.wallets.items[indexWallets].in_favorites = action.payload.state ? 1 : 0
                break
            case "PORTFOLIO__UPDATE__CURRENCY":
                const indexCurrency = draft.currencies.findIndex(elem => elem.id === action.payload.data.id)

                if (indexCurrency === -1) return state

                draft.currencies[indexCurrency] = action.payload.data
                break
            case "PORTFOLIO__UPDATE__CURRENCY_DURATION":
                const indexCurrencyDuration = draft.currency_duration.findIndex(elem => elem.id === action.payload.data.id)

                if (indexCurrencyDuration === -1) return state

                draft.currency_duration[indexCurrencyDuration].duration = action.payload.data.duration
                break
            case "PORTFOLIO__UPDATE__CURRENCY_DETAILS_DATA":
                if (!draft.currency_details_data) return state

                if (draft.currency_details_data.currency_id === action.payload.id) {
                    draft.currency_details_data.in_favorites =  action.payload.state ? 1 : 0
                } else {
                    return state
                }

                break
            // Delete
            case "PORTFOLIO__LOAD__DELETE__FIRST":
                draft.load_first = draft.load_first.filter(elem => elem !== action.payload.load)
                break
            case 'PORTFOLIO__DELETE__COUNT_FILTER':
                if (typeof action.payload.count !== 'number') return state
                const number = draft.transactions_count_filter - action.payload.count
                draft.transactions_count_filter = number < 0 ? 0 : number
                break
            // Error

            //Close

            // Async
            case "PORTFOLIO__WATCH_BALANCE": return state
            case "PORTFOLIO__WATCH_WALLET": return state
            case "PORTFOLIO__WATCH_CURRENCY": return state
            case 'PORTFOLIO__WATCH_CURRENCY_DETAIL': return state
            case 'PORTFOLIO__WATCH_CURRENCY_DETAIL_CHART': return state
            case 'PORTFOLIO__WATCH_TRANSACTIONS': return state
            case 'PORTFOLIO__WATCH_CREATE_WALLET': return state
            case 'PORTFOLIO__WATCH_CREATE_WALLET_ADDRESS': return state
            case "PORTFOLIO__WATCH__GENERATE_REPORT": return state
            // Def
            default:
                const allAction:never = action // check use all action
                return state
        }
    })
}
