// Core
import {useSelector} from 'react-redux'
// Selector
import {authSelect, appSelect} from 'selectors'
import {useEffect} from "react";


export const useAboutEf = () => {
    const isAuth = useSelector(authSelect.is_auth)
    const lang = useSelector(appSelect.language)

    useEffect(() => {
        window.scrollTo(0,0)
    }, [window])

    return {
        isAuth, lang,
    }
}
