// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Breadcrumb, Skeleton, Tabs} from 'antd'
// Style
import './HeaderPortfolio.scss'
// Hook
import {useHeaderPortfolioEf} from './useHeaderPortfolioEf'
// Utils
import {config} from 'utils'
// Component
import {HeaderPortfolioInfo, HeaderPortfolioAction} from '#/components'
// Element
import {Typography} from 'elements'


type PropsType = {
	children?: never
}

const HeaderPortfolio: FC<PropsType> = memo(() => {
	const {
		tab, currencyDetails, loadCurrencyDetail,
		handlerTab, handlerBack,
	} = useHeaderPortfolioEf()

	return (<>
		<Breadcrumb className={'header-profile__breadcrumb'}>
			<Breadcrumb.Item onClick={handlerBack}>
				{
					loadCurrencyDetail
						? <Skeleton.Input active={true} style={{width: 100, marginTop: -10}} size={'small'} />
						: <span className={'header-profile__breadcrumb__item'}>Portfolio</span>
				}
			</Breadcrumb.Item>
			<Breadcrumb.Item>
				{
					loadCurrencyDetail
						? <Skeleton.Input active={true} style={{width: 100, marginTop: -10}} size={'small'} />
						: <span className={'header-profile__breadcrumb__item breadcrumb-item-last'}>{currencyDetails?.full_name}</span>
				}
			</Breadcrumb.Item>
		</Breadcrumb>
		<HeaderPortfolioAction/>
		<HeaderPortfolioInfo/>
		{
			tab && (
				<Tabs activeKey={tab} tabPosition={'top'} onChange={handlerTab}>
					{
						config.cabinet.portfolio.tab.map(elem => (
							<Tabs.TabPane
								disabled={loadCurrencyDetail}
								tab={(
									<Typography type={"text-16"} transform={"capitalize"} color={loadCurrencyDetail ?  "gray" : 'secondary'}>
										{elem.split('-').join(' ')}
									</Typography>
								)}
								key={elem}
							/>
						))
					}
				</Tabs>
			)
		}
	</>)
})

export default HeaderPortfolio
