// Core
// Api
import {cabMainApi, profileApi} from 'api/cabinet'
import {makeRequest} from 'sagas/template'
// Action
import {cabinetMainActions, homeActions, portfolioActions, profileActions} from 'actions/cabinet'
// Type
import {WorkerProps} from "sagas/root-saga"
import {
    EExportType,
    IFavoritesItem,
    ITransactionsSend,
    TCurrencyListType,
    TExportAddress,
    TFavoritesItem,
    TFavoritesRequestData,
    TGetWalletAddresses,
    TImportAddress
} from "types/cabinet/cabinet-main-type"
import {appActions} from "../../../actions";
import {IAddressTableItemBase} from "../../../types/cabinet/profile";
import {AlertType, TPushMessage} from "../../../types/app-type";


export function* workerCurrencyList({payload}: WorkerProps<TCurrencyListType>): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: cabMainApi.currency_list,
        fetcherParam: [
            payload,
        ],
        start: [
            {action: payload === "crypto" ? cabinetMainActions.load_currency_list_crypto : cabinetMainActions.load_currency_list_fiat, handler: () => true}
        ],
        finish: [
            {action: payload === "crypto" ? cabinetMainActions.load_currency_list_crypto : cabinetMainActions.load_currency_list_fiat, handler: () => false}
        ],
        success: [
            {action: payload === "crypto" ? cabinetMainActions.set_currency_list_crypto : cabinetMainActions.set_currency_list_fiat}
        ],
    })
}

const favoritesActions = [
    {action: cabinetMainActions.set_favorites, handler: (data: TFavoritesRequestData) => data.favorites},
    {action: cabinetMainActions.set_currency_items, handler: (data: TFavoritesRequestData) => data.items},
]

export function* workerFavorites({payload}: WorkerProps<{ mode: boolean, favoritesLocal: IFavoritesItem[] }>): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: cabMainApi.favorites_list,
        success: payload.mode ?
            [
                {action: cabinetMainActions.set_favorites, handler: (data: TFavoritesRequestData) => {
                    return data.favorites?.length ? payload.favoritesLocal : data.favorites
                }
                },
                {action: cabinetMainActions.set_currency_items, handler: (data: TFavoritesRequestData) => data.items},
            ]
            :
            [favoritesActions[0],favoritesActions[1]]
    })
}

export function* workerAddFavorites({payload}: WorkerProps<{data: TFavoritesItem}>): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: cabMainApi.favorites_list_add,
        fetcherParam: [
            payload
        ],
        start: [
            {action: homeActions.home_set_update_favorites, handler: () => true}
        ],
        finish: [
            {action: homeActions.home_set_update_favorites, handler: () => false}
        ],
        success: [
            {action: cabinetMainActions.add_to_favorites, handler: () => payload.data},
            {action: portfolioActions.update_wallets_favorite, handler: () => ({id : payload.data.id, state: true})},
            {action: portfolioActions.update_currency_details_data, handler: () => ({id : payload.data.id, state: true})},
            {action: homeActions.home_set_changed_favorites, handler: () => true}
        ],
    })
}

export function* workerRemoveFavorites({payload}: WorkerProps<{data: TFavoritesItem}>): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: cabMainApi.favorites_list_remove,
        fetcherParam: [
            payload
        ],
        start: [
            {action: homeActions.home_set_update_favorites, handler: () => true}
        ],
        finish: [
            {action: homeActions.home_set_update_favorites, handler: () => false}
        ],
        success: [
            {action: cabinetMainActions.remove_from_favorites, handler: () => payload.data},
            {action: portfolioActions.update_wallets_favorite, handler: () => ({id : payload.data.id, state: false})},
            {action: portfolioActions.update_currency_details_data, handler: () => ({id : payload.data.id, state: false})},
            {action: homeActions.home_set_changed_favorites, handler: () => true}
        ],
    })
}

export function* workerWalletAddresses({payload}: WorkerProps<{data: TGetWalletAddresses}>): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: cabMainApi.get_wallet_addresses,
        fetcherParam: [
            payload.data
        ],
        start: [
            {action: cabinetMainActions.load_wallet_addresses, handler: () => true}
        ],
        finish: [
            {action: cabinetMainActions.load_wallet_addresses, handler: () => false}
        ],
        success: [
            {action: payload.data.page ? cabinetMainActions.update_wallet_addresses : cabinetMainActions.set_wallet_addresses, handler: (data) => data},
            {action: cabinetMainActions.set_send_asset_mode, handler: () => false},
            {action: cabinetMainActions.set_wallet_addresses_mode, handler: () => true}
        ],
    })
}

export function* workerSendAssets({payload}: WorkerProps<{data: ITransactionsSend}>): Generator {

    yield makeRequest({
        // @ts-ignore
        fetcher: cabMainApi.send_assets,
        fetcherParam: [
            payload
        ],
        start: [
            {action: cabinetMainActions.load_send_assets, handler: () => true}
        ],
        finish: [
            {action: cabinetMainActions.load_send_assets, handler: () => false}
        ],
        success: [
            {action: cabinetMainActions.set_send_assets_data, handler: (data) => ({...data,...payload.data})},
            {action: cabinetMainActions.set_send_asset_mode, handler: () => false}
        ],
        // with_message: true,
        validation: [
            {action: cabinetMainActions.set_send_assets_errors},
            { action: appActions.set_alert,
                handler: (data) => {
                    let messageError = ''

                    for (let key in data) {
                        if( key.toLowerCase() !== 'password'){
                            messageError += data[key] + ' '
                        }
                    }
                    return messageError.length ? {
                        type: "error",
                        header: 'Errror',
                        text: messageError,
                } as AlertType : null}
            }
        ],
    })
}

export function* workerSaveAddress({payload}: WorkerProps<{data: IAddressTableItemBase}>): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: profileApi.addresses_list_add,
        fetcherParam: [payload.data],
        start: [
            {action: profileActions.load_address_table_modal_form, handler: () => true}
        ],
        finish: [
            {action: profileActions.load_address_table_modal_form, handler: () => false}
        ],
        success:  [
            {action: appActions.set_push, handler: () => ({title: 'Success', content: 'Address been successfully create'} as TPushMessage)},
            {action: cabinetMainActions.set_send_assets_data, handler: () => null }
        ],
        validation: [
            {action: profileActions.error_address_table_modal_form},
            {action: profileActions.error_address_table_modal_form, handler: () => null}
        ]
    })
}

export function* workerImportAddress({payload}: WorkerProps<{data: TImportAddress}>): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: profileApi.import_address,
        fetcherParam: [payload.data],
        start: [
            {action: cabinetMainActions.load_import_address, handler: () => true}
        ],
        finish: [
            {action: cabinetMainActions.load_import_address, handler: () => false}
        ],
        success:  [
            {action: portfolioActions.watch_currency_detail, handler: () => payload.data.wallet_id},
            {action: cabinetMainActions.set_import_assets, handler: () => false}
        ],
        validation: [
            {action: cabinetMainActions.set_import_address_errors, handler: (data) => data},
        ]
    })
}

export function* workerExportAddress({payload}: WorkerProps<{data: TExportAddress}>): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: profileApi.export_address,
        fetcherParam: [payload.data],
        start: [
            {action: cabinetMainActions.load_export_address, handler: () => true}
        ],
        finish: [
            {action: cabinetMainActions.load_export_address, handler: () => false}
        ],
        action: [
            {action: cabinetMainActions.set_export_assets, handler: data => (
                {
                    mode: true,
                    config: {
                        type: data.method.toLowerCase() === 'method_email' ? EExportType.EMAIL : EExportType.GOOGLE,
                        address: payload.data.address,
                        password: payload.data.password,
                        confirmData: data
                    }
                })}
        ],
        success:  [
            {action: cabinetMainActions.set_export_assets, handler: data => ({
                mode: true,
                config: {
                    type: EExportType.FINAL,
                    address: payload.data.address,
                    finalData: data
                }
            })}
        ],
        validation: [
            {action: cabinetMainActions.set_export_address_errors, handler: (data) => data},
        ]
    })
}

export function* workerNotifications(): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: cabMainApi.notifications,
        success:  [
            {action: cabinetMainActions.set_notifications, handler: data => data.items}
        ],
    })
}

export function* workerReadNotifications(): Generator {
    yield makeRequest({
        // @ts-ignore
        fetcher: cabMainApi.notifications_read,
        success:  [
            {action: cabinetMainActions.set_notifications, handler: data => data.items},
        ],
        validation: [
            {action: appActions.set_alert, handler: () => ({type: 'error', header: 'error', text: 'Error with reading notifications'} as AlertType)},
        ]
    })
}
