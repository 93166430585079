// Api
import qs from 'qs'
import { instance, ResponseApiType } from "../api"
// Type
import {Language} from 'types/app-type'
import {EWalletDuration} from 'types/cabinet/cabinet-main-type'
import {TGenerateReport} from "../../types/cabinet/portfoli-type";


const settingsApi = {
    // Load
    balance(lang: Language, token: string, duration: EWalletDuration) {
        return instance.get<ResponseApiType>(`wallet/chart?duration=${duration}`,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    wallet(lang: Language, token: string) {
        return instance.get<ResponseApiType>(`wallet/list`,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    currency(lang: Language, token: string, id: string, duration: EWalletDuration) {
        return instance.get<ResponseApiType>(`currency/chart/${id}?duration=${duration}`,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    currency_detail(lang: Language, token: string, id: string) {
        return instance.get<ResponseApiType>(`wallet/detail/${id}`,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    generate_report(lang: Language, token: string, data: TGenerateReport) {
        return instance.get(`/wallet/report-transactions/${data.walletId}`,{
            responseType: 'blob',
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            },
            params: {
                type: data.type,
                duration: data.duration
            }
        }).then(data => {
            // generate name for downloading file
            const nameLength = data.headers['content-type'].length
            const nameIndex = data.headers['content-type'].indexOf('name="')
            const name = data.headers['content-type'].slice(nameIndex + 6, nameLength - 1)

            const url = window.URL.createObjectURL(data.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();

            return data
        })
    },

    // Create
    wallet_create(lang: Language, token: string, id: string) {
        return instance.post<ResponseApiType>(`/wallet/create/${id}`,{},{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    wallet_create_address(lang: Language, token: string, id: string) {
        return instance.post<ResponseApiType>(`/wallet/create-address/${id}`,{},{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    // Update

    // Delete

}

export default settingsApi
