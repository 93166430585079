// Core
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
// Action
import {portfolioActions} from 'actions/cabinet'
// Selector
import {portfolioSelect} from 'selectors/cabinet'
// Util
import {range} from 'utils'
// Type
import {TPriceCorrelation} from 'types/cabinet/portfoli-type'
import {EWalletDuration} from 'types/cabinet/cabinet-main-type'


const getMockColumnItem = (id: string): TPriceCorrelation  => ({
	id,
	full_name: '',
	icon: '',
	percent: 0,
	value: 0,
})

export const useOverviewEf = () => {
	const dispatch = useDispatch()
	const loadFirst = useSelector(portfolioSelect.load_first)
	const loadCurrencyDetail = useSelector(portfolioSelect.load_currency_detail)
	const loadCurrencyDetailChart = useSelector(portfolioSelect.load_currency_detail_chart)
	const currencyDetails = useSelector(portfolioSelect.currency_details)
	const currencyDetailsData = useSelector(portfolioSelect.currency_details_data)
	const currencyDetailsChart = useSelector(portfolioSelect.currency_details_chart)
	const currencyDetailsChartDuration = useSelector(portfolioSelect.currency_details_chart_duration)

	const getCurrencies = (): TPriceCorrelation[] | null => !currencyDetailsData || !currencyDetailsData.correlation
		? !loadFirst.includes('currency-detail')
			? range(0, 3)
				.map(elem => getMockColumnItem(`${elem}`))
				.map(elem => ({...elem, key: elem.id}))
			: []
		: currencyDetailsData.correlation
			.map(elem => ({...elem, key: elem.id}))

	const [currencies, setCurrencies] = useState<TPriceCorrelation[] | null>(getCurrencies())

	const handlerWalletResent = () => {
		currencyDetails && dispatch(portfolioActions.watch_currency_detail(currencyDetails.id))
	}

	const handlerWalletResentChart = () => {
		if (currencyDetails) {
			dispatch(portfolioActions.watch_currency_detail_chart(currencyDetails.cur_id, EWalletDuration.HOUR))
		}
	}

	const handlerResend = () => {
		if (currencyDetails) {
			dispatch(portfolioActions.watch_currency_detail_chart(currencyDetails.cur_id, EWalletDuration.HOUR))
		}
	}

	const handlerChange = (val: EWalletDuration) => {
		if (currencyDetails) {
			dispatch(portfolioActions.watch_currency_detail_chart(currencyDetails.cur_id, val))
		}
	}

	useEffect(() => {
		setCurrencies(getCurrencies())
	}, [currencyDetailsData, loadFirst, loadCurrencyDetail])

	return {
		loadFirst, loadCurrencyDetail, currencyDetailsData, currencies, currencyDetails,
		loadCurrencyDetailChart, currencyDetailsChart, currencyDetailsChartDuration,
		handlerWalletResent, handlerResend, handlerChange, handlerWalletResentChart,
	}
}
