// Core
import React, {FC, memo, useState} from 'react'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Select, Form, Radio} from 'formik-antd'
import { DatePicker } from 'antd'
import moment from 'moment'
// Ant Components
import {Drawer, Button} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
// Style
import './TransactionFilter.scss'
// Hook
import {useTransactionFilterEf} from './useTransactionFilterEf'
// Util
import {config, selectCaseString} from 'utils'
// Component
import {FormikHandler} from 'components'
import {Typography} from 'elements'
// Types
import {
    TTransactionQueryFilter
} from 'types/cabinet/transactions'


type PropsType = {
	children?: never,
	mode: boolean,
	filter: TTransactionQueryFilter,
	loadTransactions: boolean,
	enableCurrencyFilter?: boolean,
	handlerCloseFilter: () => void,
	handlerSubmitFilter: (val: Omit<TTransactionQueryFilter, 'name'>) => void,
	handlerSetFilterCount: (count: number) => void
}

const SignupSchema = Yup.object().shape({})

const TransactionFilter: FC<PropsType> = memo(({
	mode, filter, loadTransactions, enableCurrencyFilter = true,
	handlerCloseFilter, handlerSubmitFilter, handlerSetFilterCount
}) => {
	const {
		currencyList, submit, showPiker, dataRange, hackValue, valueDates, setValueDates, setDates,
		disabledClearBtn, status, type, currencies, handlerClickSubmit, resetFilterForm,
		handlerDataRange, disabledDate, onOpenChange,handlerClear, handlerStatus, handlerType, handlerCurrencies, handlerSubmit
	} = useTransactionFilterEf({mode, filter, handlerSubmitFilter, handlerSetFilterCount})

	const {name, ...rest} = filter

	const init = {
		...rest,
	}
	
	return (
		<Drawer
			title="Filters"
			placement="right"
			closable={true}
			onClose={handlerCloseFilter}
			visible={mode}
			width={400}
			className={'transaction-filter'}
			footer={(
				<div className={'transaction-filter__footer'}>
					<Button type={'default'} disabled={disabledClearBtn} onClick={handlerClear}>
						Clear filters
					</Button>
					<Button
						type={'primary'}
						onClick={handlerClickSubmit}
						loading={loadTransactions}
					>
						Apply
					</Button>
				</div>
			)}
		>
			<div className={'transaction-filter__body'}>
				<Formik 
				onSubmit={(values,{resetForm}) => {
					handlerSubmit(values, resetForm)
				}}
				initialValues={init} 
				validationSchema={SignupSchema}
				enableReinitialize={true}
				>
					{(formik) => (
						<Form>
							{
								enableCurrencyFilter && 
								<Form.Item
									name={'currency'}
									label={'Assets name'}
									labelCol={{span: 24}}
									wrapperCol={{span: 24}}
								>
									<Select
										mode="multiple"
										allowClear
										style={{ width: 'calc(100% - 48px)' }}
										className={'input-select rotate-false'}
										placeholder="Select assets"
										name='currency'
										value={currencies}
										onChange={(e) => handlerCurrencies(e)}
									>
										{
											currencyList?.map(elem => (
												<Select.Option key={elem.name} value={elem.id}>
													<div className={`transaction-filter__option`}>
														<img src={`${config.app.apiURL}${elem.icon}`} alt={elem.name}/>
														{elem.name}
													</div>
												</Select.Option>
											))
										}
									</Select>
								</Form.Item>
							}
							<Form.Item
								name={'duration'}
								label={'Date range'}
								labelCol={{span: 24}}
								wrapperCol={{span: 24}}
							>
								<Select
									style={{ width: 160}}
									className={'input-select'}
									placeholder="Select assets"
									name='duration'
									value={dataRange}
									onChange={handlerDataRange}
								>
									{
										config.cabinet.transaction.filter.date_range.map(elem => (
											<Select.Option key={elem.val} value={elem.val}>
												{elem.title}
											</Select.Option>
										))
									}
								</Select>
							</Form.Item>
							{
								showPiker && (
									<div style={{marginBottom: 24}}>
										<div style={{display: 'flex', marginBottom: 10}}>
											<Typography type={'text-16'} color={'primary'}>
												From
											</Typography>
											<Typography style={{marginLeft: 105}} type={'text-16'} color={'primary'}>
												To
											</Typography>
										</div>
										<DatePicker.RangePicker
											clearIcon={false}
											value={hackValue || valueDates}
											disabledDate={disabledDate}
											onCalendarChange={setDates}
											onChange={setValueDates}
											onOpenChange={onOpenChange}
										/>
									</div>
								)
							}
							<Form.Item
								name={'type'}
								label={'Transaction type'}
								labelCol={{span: 24}}
								wrapperCol={{span: 24}}
							>
								<Radio.Group
									name={'type'}
									value={type}
									onChange={handlerType}
								>
									{
										config.cabinet.transaction.filter.type.map(elem => <Radio.Button value={elem}>
											{selectCaseString('lower', elem, [0])}
										</Radio.Button>)
									}
								</Radio.Group>
							</Form.Item>

							<Form.Item
								name={'status'}
								label={'Transaction status'}
								labelCol={{span: 24}}
								wrapperCol={{span: 24}}
							>
								<Radio.Group
									name={'status'}
									value={status}
									onChange={handlerStatus}
								>
									{
										config.cabinet.transaction.filter.status.map(elem => <Radio.Button value={elem}>
											{selectCaseString('lower', elem, [0])}
										</Radio.Button>)
									}
								</Radio.Group>
							</Form.Item>
							<FormikHandler data={{}} submit={submit}/>
						</Form>
					)}
				</Formik>
			</div>
		</Drawer>
	)
})

export default TransactionFilter
