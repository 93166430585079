// Core
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
// Selectors
import {portfolioSelect, settingsCabinetSelect} from "selectors/cabinet";
// Actions
import {cabinetMainActions, portfolioActions, settingsCabinetAction} from "actions/cabinet";
import {appActions} from "actions";
// Types
import {EExportType} from "types/cabinet/cabinet-main-type";
// Utils
import {range} from "utils";




export const useAddedAddressesEf = () => {
    const dispatch = useDispatch()

    // Selectors
    const currencyDetails = useSelector(portfolioSelect.currency_details_data)
    const loadAddAddress = useSelector(portfolioSelect.load_add_address)
    const settings = useSelector(settingsCabinetSelect.settings)
    const settingsLoading = useSelector(settingsCabinetSelect.load_settings)

    // State
    const [canAddAddress, setCanAddAddress] = useState<boolean>(false)

    // get mock data for skeleton
    const getMockAddressData = (id: string)  => ({
        id,
        created_at: '',
        address: ''
    })

    const emptyAddressData = range(0,9).map(elem => getMockAddressData(`${elem}`))

    // Effects

    useEffect(() => {
        // check if addresses array includes more than 10 elements and disable "add address" button
        (currencyDetails && currencyDetails?.addresses.length > 10 ) && setCanAddAddress(true)
    },[currencyDetails])

    useEffect(() => {
        !settings.notif_currency && dispatch(settingsCabinetAction.watch_settings_base())
    },[dispatch])


    // Handlers

    const handlerAddAddress = () => {
        (currencyDetails && currencyDetails?.addresses.length <= 10 )
            && dispatch(portfolioActions.watch_create_wallet_address(currencyDetails?.currency_id))
    }

    const handlerCopy = () => {
        dispatch(appActions.set_push({
            title: 'Success',
            content: 'Wallet address has been successfully copied'
        }))
    }

    const handlerQr = (address: string) => {
        dispatch(cabinetMainActions.set_receive_asset_mode(true, true, {showSelector: false, address }, 'Copy Wallet Address'))
    }

    const handlerImportAddress = () => {
        dispatch(cabinetMainActions.set_import_assets(true))
    }

    const handlerExportAddress = (address: string) => {
       dispatch(cabinetMainActions.set_export_assets({mode: true, config: {type: EExportType.DEFAULT, address}}))
    }

    return {
        currencyDetails, canAddAddress, loadAddAddress, emptyAddressData,
        handlerAddAddress, handlerCopy, handlerQr, handlerImportAddress, handlerExportAddress
    }
}
