// Core
import React, {FC, memo} from 'react'
// Style
import './LoadCoinMiddle.scss'



type PropsType = {
    children?: never
    className?: string
}

const LoadCoinMiddle: FC<PropsType> = memo(({className = ''}) => (
    <svg className={`load-coin-middle ${className}`} viewBox="0 0 70 76" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.2862 0L0.00012207 15.0603L9.0959 35.2133L43.9693 40.6627L70 25.5L60.1597 5.44938L25.2862 0Z" fill="#F9DF64"/>
        <path d="M44 76V39.4556L70 25.5V62.5444L44 76Z" fill="#F6D35D"/>
        <path d="M44 76V39.5608L9 34V71.067L44 76Z" fill="url(#load-coin-middle)"/>
        <path d="M9 71V33.979L0 15V52.7299L9 71Z" fill="#F6D35D"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M45.5479 17.9558C45.5273 19.774 44.7468 21.1778 44.4103 21.8057C44.2451 22.1154 43.9364 22.628 43.5933 23.1696L46.8833 25.5024L45.4376 27.5145L42.2004 25.2199L41.0997 26.7516L44.337 29.0462L42.8913 31.0583L39.654 28.7637L36.8095 32.7214L34.6732 30.583L35.539 29.3801C35.829 28.9755 35.9121 28.7797 35.8734 28.4967C35.8332 28.2132 35.6144 28.0015 35.4157 27.8609L27.0337 21.9201C26.5086 21.5464 26.3365 21.4887 25.8947 21.5371C25.4514 21.5851 25.0106 22.1246 24.7831 22.4422L23.9466 23.6047L21.8446 22.114L24.6878 18.1558L21.4405 15.8544L22.8863 13.8423L26.1335 16.1437L27.2341 14.612L23.9874 12.3092L25.4331 10.2972L28.7196 12.6271C29.8912 11.1932 30.9808 10.28 32.4059 9.82062C34.2484 9.22444 35.8289 10.4437 36.1972 10.7483C36.5646 11.051 37.1909 11.7495 37.4301 12.4299C37.6707 13.1109 37.5585 14.2802 37.5585 14.2802C37.5585 14.2802 38.4694 13.4512 39.4836 13.1325C40.4967 12.8166 41.7798 12.9581 43.1027 13.9403C44.4279 14.9203 45.5661 16.1398 45.5479 17.9558ZM35.9838 19.6927C35.4025 20.4241 34.8573 21.1831 34.3502 21.9671L38.6951 25.0475C38.6951 25.0475 39.6827 23.7331 40.2591 22.7922C40.8336 21.8521 41.2692 20.7704 41.3441 20.1275C41.418 19.4873 41.4194 18.6495 40.4265 17.9451C39.4321 17.2402 38.5438 17.6025 38.0832 17.8459C37.6237 18.0865 36.7667 18.7431 35.9852 19.6932L35.9838 19.6927ZM33.5365 18.8654C34.0348 18.1113 34.4258 17.2102 34.5184 16.4248C34.6111 15.6395 34.3474 14.9701 33.5963 14.4659C32.8453 13.9616 31.8685 14.0069 31.0184 14.6536C30.1683 15.3003 29.7506 15.8406 29.2891 16.4641C28.8276 17.0875 28.4234 17.7663 28.4234 17.7663L32.3339 20.5393C32.3339 20.5393 33.0374 19.6177 33.5365 18.8654Z" fill="white"/>
        <defs>
            <linearGradient id="load-coin-middle" x1="26.5" y1="35" x2="26.5" y2="76" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F8D563"/>
                <stop offset="1" stopColor="#F9DF64"/>
            </linearGradient>
        </defs>
    </svg>
))

export default LoadCoinMiddle
