// Core
import React, {FC, memo} from 'react'


type PropsType = {
    children?: never
}

const AlertSuccess: FC<PropsType> = memo(() => {
    return (
        <svg width="28" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47768 4.47768 0 10 0C15.5223 0 20 4.47768 20 10C20 15.5223 15.5223 20 10 20C4.47768 20 0 15.5223 0 10ZM1.69643 10C1.69643 14.5848 5.41518 18.3036 10 18.3036C14.5848 18.3036 18.3036 14.5848 18.3036 10C18.3036 5.41518 14.5848 1.69643 10 1.69643C5.41518 1.69643 1.69643 5.41518 1.69643 10ZM13.1272 6.4502H14.174C14.3191 6.4502 14.404 6.61537 14.3169 6.73368L9.61601 13.2515C9.5503 13.3432 9.46369 13.418 9.36334 13.4695C9.263 13.5211 9.15181 13.5479 9.039 13.5479C8.92619 13.5479 8.815 13.5211 8.71465 13.4695C8.61431 13.418 8.52769 13.3432 8.46199 13.2515L5.68074 9.39439C5.59592 9.27609 5.68074 9.11091 5.82583 9.11091H6.8727C7.10261 9.11091 7.3169 9.22252 7.45083 9.40778L9.04011 11.6131L12.549 6.74707C12.683 6.55957 12.8995 6.4502 13.1272 6.4502Z" fill="#52C41A"/>
        </svg>
    )
})

export default AlertSuccess
