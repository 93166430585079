// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Actions
import {portfolioActions} from 'actions/cabinet'
// Selector
import {cabinetMainSelect, portfolioSelect} from 'selectors/cabinet'
import {RadioChangeEvent} from "antd/lib/radio";
import {TGenerateReport, TGenerateReportValues} from "../../../../../../types/cabinet/portfoli-type";
import moment from "moment";
import config from "../../../../../../utils/config";


export const useGenerateReportEf = () => {
    const dispatch = useDispatch()

    // State

    const [showRange, setShowRange] = useState<boolean>(false)
    const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null)
    const [type, setType] = useState<null | string>(null)
    const [duration, setDuration] = useState<null | string>(null)
    const [dateRange, setDateRange] = useState<string[] | null>(null)


    // Selectors

    const generateReportMode = useSelector(portfolioSelect.generate_report_mode)
    const wallets = useSelector(portfolioSelect.wallets)
    const loadGeneratePdf = false


    // Handlers

    const resetValues = () => {
        setSelectedCurrency(null)
        setType(null)
        setDuration(null)
        setDateRange(null)
    }

    const handlerClose = () => {
        dispatch(portfolioActions.set_generate_report_mode(false))
        resetValues()
    }

    const handlerSubmit = (values: TGenerateReportValues) => {
        let reportValues:TGenerateReport  = {
            walletId: values.walletId
        }
        // check if add `type` field to report values
        reportValues =  values?.type !== 'all' ? {...reportValues, type: values?.type} : {...reportValues}
        // check if add `duration` field to report values
        reportValues =  values?.duration?.value ? {...reportValues, duration: values?.duration.value} : {...reportValues}
        // check if not empty date range values
        reportValues = dateRange ? {...reportValues, dateFrom: dateRange[0], dateTo: dateRange[1]} : {...reportValues}

         dispatch(portfolioActions.watch_generate_report(reportValues))
    }

    const handlerSelectCurrencyChange = (value: string) => {
        setSelectedCurrency(value)
    }

    const handlerType = (e: RadioChangeEvent) => {
        setType(e.target.value)
    }

    const handlerDuration = (e: RadioChangeEvent) => {
        setDuration(e.target.value)
        e.target.value.value === 'custom' ? setShowRange(true) :setShowRange(false)
    }

    const handlerDateRange = (values: any) => {
        setDateRange([
            moment(values[0]).format(config.cabinet.transaction.filter.date_format),
            moment(values[1]).format(config.cabinet.transaction.filter.date_format)
        ])
    }


    return {
        generateReportMode, showRange, selectedCurrency, wallets, type, duration, loadGeneratePdf,
        handlerClose, handlerSubmit, handlerSelectCurrencyChange, handlerType, handlerDuration, handlerDateRange
    }
}
