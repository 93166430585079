// Api
import qs from 'qs'
import { instance, ResponseApiType } from "../api"
// Type
import {Language} from 'types/app-type'
import {
    TUpdateFavorites,
    TCurrencyList,
    TCurrencyListType,
    TFavoritesItem,
    ICurrencyItem, TGetWalletAddresses, ITransactionsSend
} from 'types/cabinet/cabinet-main-type'
import {TGetWalletHome} from "../../types/cabinet/home-type";


const cabMainApi = {
    currency_list(lang: Language, token: string, type: TCurrencyListType) {
        return instance.get<ResponseApiType<ICurrencyItem>>(`currency/list/${type}`,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    notifications(lang: Language, token: string) {
        return instance.get<ResponseApiType>(`user/get-notifications`,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    notifications_read(lang: Language, token: string) {
        return instance.get<ResponseApiType>(`user/read-notifications`,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    favorites_list(lang: Language, token: string) {
        return instance.get<ResponseApiType<ICurrencyItem>>(`/currency/favorites-list`,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    favorites_list_add(lang: Language, token: string, payload: { data: TFavoritesItem }) {
        return instance.post<ResponseApiType<ICurrencyItem>>(`/currency/favorites-add/${payload.data.id}`,{},{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    favorites_list_remove(lang: Language, token: string, payload: { data: TFavoritesItem }) {
        return instance.post<ResponseApiType<ICurrencyItem>>(`/currency/favorites-remove/${payload.data.id}`,{},{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
    get_wallet_addresses(lang: Language, token: string, data: TGetWalletAddresses) {

        return instance.get<ResponseApiType>(`wallet/addresses`, {
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token
            },
            params: {
                page: data.page,
                'per-page': data.perPage,
                sort: data.sort,
                'UserAddressesSearch[name]': data.name,
                'UserAddressesSearch[full_name]': data.full_name
            }
        }).then(data => data.data)
    },
    send_assets(lang: Language, token: string, payload: { data: ITransactionsSend }) {
        return instance.post<ResponseApiType>('/transaction/send',  qs.stringify(payload.data) ,{
            headers: {
                'Site-language': lang,
                'accept': 'application/json',
                'Authorization': token,
            }
        }).then(data => data.data)
    },
}

export default cabMainApi
