// Core
import React, {FC, memo} from 'react'
import arrayMove from 'array-move';
import clsx from "clsx";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {config} from "utils";
// Ant Components
import {Image, Modal, Table, Input} from 'antd'
// Ant Icon
import {MenuOutlined, SearchOutlined} from '@ant-design/icons'
// Hook
import {useFavoritesEf} from './useFavoritesEf'
// Elements
import {Typography} from "elements";
// Styles
import './Favorites.scss'
// Types
import {IFavoritesItem, TFavoritesItem} from "types/cabinet/cabinet-main-type";
// Icons
import {FavoritesStar} from "icons";



type PropsType = {
    children?: never,
    showFavorites: boolean,
    handlerChangeFavorites: () => void
    handlerCloseFavorites: () => void
}

const Favorites: FC<PropsType> = memo(({showFavorites = false, handlerChangeFavorites, handlerCloseFavorites}) => {
    const {
        parseCurrency, availableAssets, favoritesList, updatingFavorites, hideDragIcon,
        updateFavorites, handlerEventSearch, handlerFavorites, handlerRemoveFavorites, handlerSortFavorites
    } = useFavoritesEf()

    const { Column } = Table;

    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

    const SortableItem = SortableElement((props: any) => <tr {...props} />);
    const SortContainer = SortableContainer((props: any) => <tbody {...props} />);

    const onSortEnd = ({ oldIndex, newIndex }:any ) => {
        const favoritesItemsList:any= favoritesList;

        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(favoritesItemsList), oldIndex, newIndex).filter(el => !!el)
            updateFavorites(newData)
        }
    };

    const DraggableContainer = (props: any) => (
        <SortContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }:any) => {
        const favoritesItemsList:any = favoritesList
        // function findIndex base on Table rowKey props and should always be a right array index
        //@ts-ignore
        const index = favoritesItemsList.findIndex(item => item.id === restProps['data-row-key'])
        return <SortableItem index={index} {...restProps} />
    };

    return <>
        <Modal
            centered
            visible={showFavorites}
            onCancel={handlerChangeFavorites}
            footer={false}
            title={false}
            width={'100%'}
            wrapClassName={'close-account__modal-wrap'}
        >
            <div className={'close-account__header'}>
                <div className="close-account__container close-account__header-container">
                    <Typography type={'text-16'} weight={'medium'} color={'primary'}>Favorites</Typography>
                    <a href={'#'} className={'close-account__header-close'} onClick={() => handlerCloseFavorites()}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.15591 7.99944L15.0153 1.01507C15.1135 0.898996 15.0309 0.722656 14.8791 0.722656H13.0979C12.993 0.722656 12.8925 0.769531 12.8233 0.849888L7.99073 6.61105L3.15814 0.849888C3.09117 0.769531 2.99073 0.722656 2.88358 0.722656H1.10233C0.950549 0.722656 0.867959 0.898996 0.966173 1.01507L6.82555 7.99944L0.966173 14.9838C0.944173 15.0097 0.930058 15.0413 0.925505 15.075C0.920953 15.1086 0.926153 15.1429 0.940489 15.1737C0.954825 15.2045 0.977695 15.2305 1.00638 15.2487C1.03507 15.2669 1.06837 15.2764 1.10233 15.2762H2.88358C2.9885 15.2762 3.08894 15.2294 3.15814 15.149L7.99073 9.38784L12.8233 15.149C12.8903 15.2294 12.9907 15.2762 13.0979 15.2762H14.8791C15.0309 15.2762 15.1135 15.0999 15.0153 14.9838L9.15591 7.99944Z" fill="#332E54"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div className="close-account__body close-account__container favorites__modal-body">
                <div className="favorites__table-head">
                    <Typography type={'title-18'} color={'primary'}>Added Assets</Typography>
                </div>
                {
                    favoritesList &&
                    <Table
                        pagination={false}
                        dataSource={favoritesList}
                        loading={!!updatingFavorites}
                        onChange={handlerSortFavorites}
                        className={clsx('base-table base-table__favorites  base-table__favorites_list',{'hide-drag':hideDragIcon})}
                        rowKey="id"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                            },
                        }}
                    >
                        <Column
                            title={''}
                            dataIndex={'sort'}
                            key={'sort'}
                            fixed={'left'}
                            width={30}
                            className={'drag-visible'}
                            render={() => <DragHandle />}
                        />
                        <Column
                            title={'Assets Name'}
                            dataIndex={'full_name'}
                            key={'full_name'}
                            fixed={'left'}
                            render={(name: string, row: IFavoritesItem) => (
                                <div className={'base-table__icon-wrap'}>
                                    <Image src={config.app.apiURL + row.icon} preview={false}
                                           className={'base-table__icon'}/>
                                    {name}
                                </div>
                            )}
                        />
                        <Column
                            title={'Price'}
                            dataIndex={'price'}
                            key={'price'}
                            sorter={(a: IFavoritesItem, b: IFavoritesItem) => a.price - b.price}
                            fixed={true}
                            width={200}
                            render={price => parseCurrency(price).integer()+parseCurrency(price).fractional()}
                        />
                        <Column
                            title={'Change (24H)'}
                            dataIndex={'percent_change'}
                            key={'percent_change'}
                            sorter={(a: IFavoritesItem, b: IFavoritesItem) => a.percent_change - b.percent_change}
                            fixed={true}
                            width={150}
                            render={(percent_change) => <span className={clsx('favorites__value overview__value',{'red': percent_change < 0,'green': percent_change > 0})}>
                                    {parseCurrency(percent_change).full()+'%'}
                                </span>}
                        />
                        <Column
                            title={'Market cap'}
                            dataIndex={'market_cap'}
                            key={'market_cap'}
                            sorter={(a: TFavoritesItem, b: TFavoritesItem) => a.market_cap - b.market_cap}
                            fixed={true}
                            width={150}
                            render={(market_cap) => parseCurrency(market_cap).integer()+parseCurrency(market_cap).fractional()}
                        />
                        <Column
                            title={'Actions'}
                            key={'actions'}
                            fixed={true}
                            width={150}
                            render={(val, row: IFavoritesItem) => <div className={'favorites__star'}>
                                <button className={'favorites__button'} onClick={() => handlerRemoveFavorites(row)}>
                                    <FavoritesStar active={true} />
                                </button>
                            </div>}
                        />
                    </Table>
                }

                <div className={'favorites__table-head'}>
                    <div className="favorites__title">
                        <Typography type={'title-18'} color={'primary'}>Available Assets</Typography>
                    </div>
                    <Input
                        placeholder="Search by assets name"
                        prefix={<SearchOutlined />}
                        style={{width: 230}}
                        className={'input-item portfolio-wallet__header__action_item'}
                        size={'middle'}
                        onChange={(event) => handlerEventSearch(event)}
                        disabled={!availableAssets}
                        allowClear={true}
                    />
                    {/*<Search*/}
                    {/*    placeholder="Search by assets name"*/}
                    {/*    onSearch={handlerSearch}*/}
                    {/*    disabled={!availableAssets}*/}
                    {/*    onChange={(event) => handlerEventSearch(event)}*/}
                    {/*    // onBlur={(event) => handlerEventSearch(event)}*/}
                    {/*    onPressEnter={(event) => handlerEventSearch(event)}*/}
                    {/*    style={{ width: 260 }}*/}
                    {/*/>*/}
                </div>
                {
                    availableAssets &&
                        <Table
                            dataSource={availableAssets}
                            pagination={false}
                            loading={!!updatingFavorites}
                            rowKey={'id'}
                            className={'base-table base-table__available-assets base-table__favorites base-table__favorites_available'}
                        >
                            <Column
                                title={'Assets Name'}
                                dataIndex={'full_name'}
                                key={'full_name'}
                                fixed={'left'}
                                render={(name: string, row: IFavoritesItem) => (
                                    <div className={'base-table__icon-wrap'}>
                                        <Image src={config.app.apiURL + row.icon} preview={false}
                                               className={'base-table__icon'}/>
                                        {name}
                                    </div>
                                )}
                            />
                            <Column
                                title={'Price'}
                                dataIndex={'price'}
                                key={'price'}
                                sorter={(a: IFavoritesItem, b: IFavoritesItem) => a.price - b.price}
                                fixed={true}
                                width={200}
                                render={price => parseCurrency(price).integer()+parseCurrency(price).fractional()}
                            />
                            <Column
                                title={'Change (24H)'}
                                dataIndex={'percent_change'}
                                key={'percent_change'}
                                sorter={(a: IFavoritesItem, b: IFavoritesItem) => a.percent_change - b.percent_change}
                                fixed={true}
                                width={150}
                                render={(percent_change) => <span className={clsx('favorites__value overview__value',{'red': percent_change < 0,'green': percent_change > 0})}>
                                    {parseCurrency(percent_change).full()+'%'}
                                </span>}
                            />
                            <Column
                                title={'Market cap'}
                                dataIndex={'market_cap'}
                                key={'market_cap'}
                                // sorter={(a: TFavoritesItem, b: TFavoritesItem) => a.market_cap - b.market_cap}
                                fixed={true}
                                width={150}
                                render={(market_cap) => parseCurrency(market_cap).integer()+parseCurrency(market_cap).fractional()}
                            />
                            <Column
                                title={'Actions'}
                                key={'actions'}
                                fixed={true}
                                width={150}
                                render={(val, row: IFavoritesItem) => <div className={'favorites__star'}>
                                                <button className={'favorites__button'} onClick={() => handlerFavorites(row)}>
                                                    <FavoritesStar active={false} />
                                                </button>
                                </div>}
                            />
                        </Table>
                }
            </div>
            {/*<ComingSoon />*/}
        </Modal>
    </>
})

export default Favorites
