// Core
import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
//
import { portfolioSelect } from 'selectors/cabinet'


export const useHeaderPortfolioInfoEf = () => {
	const dispatch = useDispatch()
	const loadCurrencyDetail = useSelector(portfolioSelect.load_currency_detail)
	const currencyDetailsData = useSelector(portfolioSelect.currency_details_data)

	return {
		loadCurrencyDetail, currencyDetailsData
	}
}
