// Core
import {FocusEventHandler, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
// Selectors
import {cabinetMainSelect, homeSelector} from "selectors/cabinet";
// Hooks
import {useParseToCurrency} from "hooks";
// Actions
import {cabinetMainActions, homeActions} from "actions/cabinet";
// Types
import {IFavoritesItem} from "types/cabinet/cabinet-main-type";

export const useFavoritesEf = () => {
    const dispatch = useDispatch()

    const baseFiatSign = useSelector(homeSelector.base_sign_name)
    const favoritesList = useSelector(cabinetMainSelect.currency_favorites)
    const currencyItemsList = useSelector(cabinetMainSelect.currency_items)
    const updatingFavorites = useSelector(homeSelector.home_update_favorites)
    const favoritesLocal = useSelector(cabinetMainSelect.favorites_local)

    const [availableAssets, setAvailableAssets] = useState<IFavoritesItem[] | null>(null)
    const [hideDragIcon, setHideDragIcon] = useState<boolean>(false)

    const parseCurrency = useParseToCurrency(baseFiatSign || '')


    useEffect(() => {
        setAvailableAssets(currencyItemsList)
    },[currencyItemsList])

    useEffect(() => {
        favoritesList && !favoritesList?.length && dispatch(cabinetMainActions.remove_favorites_local())
    },[favoritesList])

    const updateFavorites = (items: IFavoritesItem[]) => {
        dispatch(cabinetMainActions.update_favorites(items))
        items.length && dispatch(homeActions.home_set_currency_list(items))
        items.length && dispatch(cabinetMainActions.set_favorites_local(items))
    }

    const searchAvailableAssets = (searchedRow: string, searchArray: IFavoritesItem[] | null) => {
        return searchArray?.filter(item => item.full_name.toLowerCase().indexOf(searchedRow.toLowerCase()) !== -1)
    }

    const handlerSearch = (value: string) => {
        const searchedItems = searchAvailableAssets(value, currencyItemsList)

        searchedItems && setAvailableAssets(searchedItems)
    }

    const handlerEventSearch = (event: any): void => {
        event.target.value.trim().length === 0 && setAvailableAssets(currencyItemsList)

        const searchedItems = event.target.value.trim().length !== 0 && searchAvailableAssets(event.target.value, currencyItemsList)
        searchedItems && setAvailableAssets(searchedItems)
    }

    const handlerFavorites = (item: IFavoritesItem): void => {
        dispatch(cabinetMainActions.watch_add_favorites(item))
    }

    const handlerRemoveFavorites = (item: IFavoritesItem): void => {
        dispatch(cabinetMainActions.watch_remove_favorites(item))
    }

    const handlerSortFavorites = (pagination: any, filters: any, sorter: any, extra: any) => {

        const sortedFavorites = extra.currentDataSource
        const isColumn = sorter.column

        sortedFavorites.length && updateFavorites(sortedFavorites)

        setHideDragIcon(!!isColumn)

    }

    return {
        baseFiatSign, parseCurrency, currencyItemsList, favoritesList, availableAssets, updatingFavorites, hideDragIcon,
        updateFavorites, handlerSearch, handlerEventSearch, handlerFavorites, handlerRemoveFavorites, handlerSortFavorites
    }
}
