// Core
import React, {FC, memo} from 'react'
// Style
import './LoadCoinLarge.scss'


type PropsType = {
    children?: never
    className?: string
}

const LoadCoinLarge: FC<PropsType> = memo(({className = ''}) => (
    <svg className={`load-coin-large ${className}`} viewBox="0 0 68 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.8925 11L0 25L9 44.5L43 51.5L68 36.9946L58.8924 16.4946L24.8925 11Z" fill="#8DDEE6"/>
        <path d="M45.6222 28.6977L48.2182 30.7487L39.2152 31.2292C38.4504 31.2762 37.6555 31.1668 36.8789 30.9077C36.1023 30.6485 35.3603 30.2451 34.6981 29.7219C34.0358 29.1987 33.4673 28.5667 33.027 27.8644C32.5867 27.1621 32.2839 26.4043 32.137 25.637L30.3403 16.624L32.9353 18.6742L34.3007 25.5218C34.3938 26.0077 34.5856 26.4877 34.8645 26.9325C35.1434 27.3773 35.5035 27.7776 35.9229 28.109C36.3423 28.4403 36.8123 28.6959 37.3042 28.86C37.796 29.0242 38.2995 29.0935 38.7839 29.0638L45.6222 28.6977ZM22.8839 31.8495L20.2879 29.7985L29.3463 29.3144C30.1111 29.2674 30.906 29.3768 31.6826 29.6359C32.4592 29.895 33.2012 30.2985 33.8634 30.8217C34.5256 31.3449 35.0942 31.9769 35.5345 32.6792C35.9748 33.3814 36.2776 34.1393 36.4244 34.9066L38.2326 43.9759L35.6376 41.9257L34.2617 35.0226C34.1687 34.5366 33.9769 34.0566 33.698 33.6118C33.4191 33.1671 33.059 32.7668 32.6396 32.4354C32.2201 32.104 31.7502 31.8485 31.2583 31.6843C30.7665 31.5202 30.263 31.4509 29.7786 31.4806L22.8839 31.8495Z" fill="white"/>
        <path d="M43 101V51.1064L68 37V86.9976L43 101Z" fill="#84D1D9"/>
        <path d="M43 101V51.1085L9 44.4062V95.903L43 101Z" fill="url(#load-coin-large)"/>
        <path d="M9 95V44.4004L0 25V75.5996L9 95Z" fill="#84D1D9"/>
        <defs>
            <linearGradient id="load-coin-large" x1="26" y1="46.3049" x2="26" y2="101" gradientUnits="userSpaceOnUse">
                <stop stopColor="#84D1D9"/>
                <stop offset="0.592543" stopColor="#8DDEE6"/>
            </linearGradient>
        </defs>
    </svg>
))

export default LoadCoinLarge
