// Core
import React, {CSSProperties, FC, memo} from 'react'
// Style
import './ContactCall.scss'


type PropsType = {
    children?: never
    className?: string
    style?: CSSProperties
}

const ContactCall: FC<PropsType> = memo(({className, ...props}) => {

    return (
        <svg className={`contact-call ${className}`} {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M37.0233 13.6949L34.3202 9.01299C34.1876 8.78346 34.0156 8.60971 33.8444 8.51096C33.6694 8.40986 33.4952 8.38713 33.3648 8.46291L24.7836 13.4497C24.7842 13.4494 24.7849 13.4492 24.7853 13.4489C24.7564 13.4655 24.7296 13.4867 24.7055 13.513L20.6064 17.9893C20.4217 18.191 20.4538 18.6252 20.6827 19.0217L22.1739 21.6041C23.0242 23.0769 23.1337 24.6503 22.506 25.4115L21.3844 26.0507C20.89 26.3322 20.2436 26.2424 19.5979 25.8696C18.9523 25.4969 18.3074 24.8411 17.8163 23.9906L16.3255 21.408C16.1956 21.1829 16.023 21.0061 15.849 20.9056C15.7165 20.8292 15.5832 20.7969 15.4671 20.8213L9.50845 22.0767C9.47204 22.0843 9.43899 22.0971 9.40915 22.1145L0.828056 27.101C0.835947 27.0964 0.844697 27.0931 0.853056 27.0891C0.573838 27.2205 0.553994 27.7197 0.828603 28.1955L3.53204 32.8776C5.1036 35.5996 7.16743 37.6984 9.23384 38.8915C11.3003 40.0846 13.3693 40.3717 14.951 39.4703L28.4049 31.8034L28.4049 31.8033L36.986 26.8165C40.1496 25.0138 40.1663 19.1392 37.0233 13.6949Z" fill="#6049EA"/>
            <path d="M0.828125 27.1008L9.4093 22.1139C9.43906 22.0966 9.47219 22.0838 9.50859 22.0762L0.927422 27.0631C0.890937 27.0708 0.857891 27.0835 0.828125 27.1008Z" fill="#6049EA"/>
            <path d="M0.925781 27.0635L9.50695 22.0766L15.4656 20.8213L6.88445 25.8082L0.925781 27.0635Z" fill="#5E60BE"/>
            <path d="M21.3851 26.0507C20.8907 26.3322 20.2443 26.2424 19.5986 25.8696C18.953 25.4969 18.3081 24.8411 17.817 23.9906L16.3262 21.408C16.1962 21.1829 16.0237 21.0061 15.8497 20.9056C15.7172 20.8291 15.5839 20.7969 15.4678 20.8213L6.88672 25.8082C7.00281 25.7838 7.13609 25.8159 7.26859 25.8925C7.44258 25.993 7.61516 26.1698 7.74508 26.3948L9.23586 28.9775C9.72695 29.828 10.3719 30.4837 11.0175 30.8565C11.6631 31.2292 12.3096 31.319 12.804 31.0376L22.163 25.7037L30.7442 20.7168L21.3851 26.0507Z" fill="#4F50B6"/>
            <path d="M25.2633 13.4983C25.4345 13.5971 25.6065 13.7709 25.7392 14.0003L28.4422 18.6823C31.5852 24.1265 31.5686 30.0012 28.4051 31.804L14.9512 39.471C13.3695 40.3724 11.3004 40.0851 9.23404 38.8921C7.16763 37.6992 5.10373 35.6002 3.53224 32.8782L0.828805 28.1961C0.53154 27.6808 0.578727 27.137 0.927555 27.064L6.88623 25.8086C7.00232 25.7842 7.1356 25.8163 7.2681 25.8929C7.44209 25.9934 7.61466 26.1703 7.74459 26.3953L9.23537 28.9779C9.72646 29.8284 10.3714 30.4842 11.017 30.8569C11.6626 31.2296 12.3091 31.3194 12.8035 31.038L22.1626 25.7041C23.1509 25.1409 23.1561 23.3053 22.1741 21.6045L20.6829 19.0221C20.454 18.6256 20.4219 18.1915 20.6066 17.9898L24.7057 13.5135C24.8387 13.3682 25.0504 13.3753 25.2633 13.4983Z" fill="#696CD2"/>
            <path d="M37.0209 13.6949L34.3179 9.01299C34.1852 8.78346 34.0132 8.60971 33.842 8.51096C33.667 8.40986 33.4928 8.38713 33.3624 8.46291L24.7812 13.4497C24.9116 13.3739 25.0858 13.3967 25.2609 13.4977C25.432 13.5966 25.6041 13.7703 25.7367 13.9998L28.4398 18.6817C31.5827 24.1259 31.5661 30.0006 28.4026 31.8034L36.9837 26.8166C40.1473 25.0138 40.1639 19.1392 37.0209 13.6949Z" fill="#5E60BE"/>
            <path d="M19.307 11.4842L16.7073 12.9658L9.22149 0L4.9211 2.45016L1.46484 4.45875L8.95086 17.4248L6.35078 18.9062L17.2265 26.81L20.6829 24.8014L19.307 11.4842Z" fill="#6FB59D"/>
            <path d="M5.76562 2.00859L9.22195 0L16.7077 12.9658L13.2513 14.9745L5.76562 2.00859Z" fill="#A3EAF1"/>
            <path d="M1.46484 4.45875L4.92109 2.45016L9.22149 0L5.76516 2.00859L1.46484 4.45875Z" fill="#6FB59D"/>
            <path d="M15.8516 13.493L19.3079 11.4844L20.6838 24.8016L17.2274 26.8102L15.8516 13.493Z" fill="#A3EAF1"/>
            <path d="M13.25 14.9746L16.7064 12.9659L19.3061 11.4844L15.8498 13.493L13.25 14.9746Z" fill="#6FB59D"/>
            <path d="M5.76516 2.00879L13.2509 14.9747L15.8506 13.493L17.2265 26.8102L6.35078 18.9064L8.95086 17.425L1.46484 4.45895L5.76516 2.00879Z" fill="#8DDFE7"/>
        </svg>
    )
})

export default ContactCall
