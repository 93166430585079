// Core
import React, {FC, memo} from 'react'
// Style
import './ArrowLine.scss'


type PropsType = {
    children?: never
    className?: string
}

const ArrowLine: FC<PropsType> = memo(({className}) => {

    return (
        <svg className={`arrow-line ${className}`} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4419 13.1528L12.9584 5.55558C12.9631 5.48687 12.953 5.41787 12.9287 5.35324C12.9045 5.28862 12.8666 5.22989 12.8177 5.18103C12.7689 5.13217 12.7102 5.09432 12.6455 5.07006C12.5809 5.04579 12.5119 5.03566 12.4432 5.04037L4.84704 5.55797C4.82412 5.5595 4.80216 5.5676 4.78377 5.58128C4.76539 5.59497 4.75135 5.61368 4.74335 5.63518C4.73534 5.65667 4.73369 5.68005 4.7386 5.70254C4.74351 5.72502 4.75477 5.74565 4.77104 5.76197L5.63506 6.62599C5.68413 6.67506 5.75227 6.70078 5.82246 6.69673L10.8334 6.35576L4.86103 12.3281C4.81437 12.3748 4.81459 12.4514 4.86152 12.4983L5.50153 13.1383C5.54847 13.1853 5.62505 13.1855 5.67172 13.1388L11.6441 7.16645L11.3031 12.1774C11.298 12.2465 11.3237 12.3147 11.3739 12.3648L12.2379 13.2288C12.3104 13.3013 12.4347 13.256 12.4419 13.1528Z" fill="#7A7790"/>
        </svg>

    )
})

export default ArrowLine
