// Core
import React, {FC, memo} from 'react'
// Style
import './EmptyStatus.scss'


type PropsType = {
	children?: never
	className?: string
}

const EmptyStatus: FC<PropsType> = memo(({className}) => (
	<svg className={`empty-status ${className}`} width="120" height="114" viewBox="0 0 120 114" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="55" cy="60" r="54" fill="#FAF7FE"/>
		<path d="M3.06304 37.5225L3.06303 37.5225C3.03657 37.5043 3.01508 37.5 3 37.5C2.98492 37.5 2.96343 37.5043 2.93697 37.5225L2.93696 37.5225L0.629808 39.1097C0.629806 39.1097 0.629803 39.1097 0.629801 39.1097C0.602253 39.1286 0.56934 39.1631 0.542787 39.2179C0.516211 39.2728 0.500003 39.3402 0.5 39.4127L3.06304 37.5225ZM3.06304 37.5225L5.37019 39.1097M3.06304 37.5225L5.37019 39.1097M5.37019 39.1097C5.37019 39.1097 5.3702 39.1097 5.3702 39.1097C5.39775 39.1286 5.43066 39.1631 5.45721 39.2179C5.48379 39.2728 5.5 39.3402 5.5 39.4127C5.5 39.4127 5.5 39.4127 5.5 39.4127V42.5873L5.37019 39.1097ZM5.5 42.5873C5.5 42.6598 5.48379 42.7272 5.45721 42.7821C5.43066 42.8369 5.39775 42.8714 5.3702 42.8903C5.3702 42.8903 5.37019 42.8903 5.37019 42.8903L3.06304 44.4775L3.06303 44.4775C3.03657 44.4957 3.01508 44.5 3 44.5C2.98492 44.5 2.96343 44.4957 2.93697 44.4775L2.93696 44.4775L0.629809 42.8903C0.629806 42.8903 0.629803 42.8903 0.629801 42.8903C0.602253 42.8714 0.56934 42.8369 0.542787 42.7821C0.516211 42.7272 0.500003 42.6598 0.5 42.5873C0.5 42.5873 0.5 42.5873 0.5 42.5873V39.4127L5.5 42.5873Z" fill="white" stroke="#665EC8"/>
		<path opacity="0.6" fillRule="evenodd" clipRule="evenodd" d="M93.7863 0.0829522L95.7073 1.2733C95.795 1.32764 95.8678 1.4058 95.9184 1.49992C95.9691 1.59405 95.9957 1.70081 95.9957 1.8095V4.1905C95.9957 4.29919 95.9691 4.40595 95.9184 4.50008C95.8678 4.5942 95.795 4.67236 95.7073 4.7267L93.7863 5.91705C93.6986 5.97139 93.5991 6 93.4979 6C93.3966 6 93.2971 5.97139 93.2094 5.91705L91.2884 4.7267C91.2007 4.67236 91.1279 4.5942 91.0773 4.50008C91.0267 4.40595 91 4.29919 91 4.1905V1.8095C91 1.70081 91.0267 1.59405 91.0773 1.49992C91.1279 1.4058 91.2007 1.32764 91.2884 1.2733L93.2094 0.0829522C93.2971 0.028609 93.3966 0 93.4979 0C93.5991 0 93.6986 0.028609 93.7863 0.0829522Z" fill="#665EC8"/>
		<path d="M85.9689 99.0387L88.5 97.5774L91.0311 99.0387V101.961L88.5 103.423L85.9689 101.961V99.0387Z" fill="white" stroke="#665EC8"/>
		<path d="M13 80L44.3158 43L71.1579 76.3L98 55.58" stroke="#665EC8"/>
		<path d="M105.597 26.9301L105.848 26.4981L105.597 26.9301L117.902 34.0722C118.387 34.3541 118.791 34.7597 119.072 35.2485C119.352 35.7374 119.5 36.2921 119.5 36.857V51.143C119.5 51.7079 119.352 52.2626 119.072 52.7515C118.791 53.2403 118.387 53.6459 117.902 53.9278L105.597 61.0699L105.848 61.5023L105.597 61.0699C105.111 61.3517 104.561 61.5 104 61.5C103.44 61.5 102.889 61.3517 102.404 61.0699L90.0988 53.9278C89.6132 53.6459 89.2096 53.2403 88.9289 52.7515C88.6482 52.2626 88.5003 51.7079 88.5002 51.143V36.857C88.5003 36.2921 88.6482 35.7374 88.9289 35.2485C89.2096 34.7597 89.6132 34.3541 90.0988 34.0722C90.0988 34.0722 90.0988 34.0722 90.0988 34.0722L102.404 26.9301L102.404 26.9301C102.889 26.6483 103.44 26.5 104 26.5C104.561 26.5 105.111 26.6483 105.597 26.9301Z" fill="white" stroke="#554CC2"/>
		<path d="M101 42C101 42.394 100.922 42.7841 100.772 43.1481C100.621 43.512 100.4 43.8427 100.121 44.1213C99.8427 44.3999 99.512 44.6209 99.1481 44.7716C98.7841 44.9224 98.394 45 98 45C97.606 45 97.2159 44.9224 96.8519 44.7716C96.488 44.6209 96.1573 44.3999 95.8787 44.1213C95.6001 43.8427 95.3791 43.512 95.2284 43.1481C95.0776 42.7841 95 42.394 95 42H95.6614C95.6614 42.3071 95.7219 42.6112 95.8394 42.895C95.9569 43.1787 96.1292 43.4365 96.3463 43.6537C96.5635 43.8708 96.8213 44.0431 97.105 44.1606C97.3888 44.2781 97.6929 44.3386 98 44.3386C98.3071 44.3386 98.6112 44.2781 98.895 44.1606C99.1787 44.0431 99.4365 43.8708 99.6537 43.6537C99.8708 43.4365 100.043 43.1787 100.161 42.895C100.278 42.6112 100.339 42.3071 100.339 42H101Z" fill="#554CC2"/>
		<path d="M112 42C112 42.394 111.922 42.7841 111.772 43.1481C111.621 43.512 111.4 43.8427 111.121 44.1213C110.843 44.3999 110.512 44.6209 110.148 44.7716C109.784 44.9224 109.394 45 109 45C108.606 45 108.216 44.9224 107.852 44.7716C107.488 44.6209 107.157 44.3999 106.879 44.1213C106.6 43.8427 106.379 43.512 106.228 43.1481C106.078 42.7841 106 42.394 106 42H106.661C106.661 42.3071 106.722 42.6112 106.839 42.895C106.957 43.1787 107.129 43.4365 107.346 43.6537C107.564 43.8708 107.821 44.0431 108.105 44.1606C108.389 44.2781 108.693 44.3386 109 44.3386C109.307 44.3386 109.611 44.2781 109.895 44.1606C110.179 44.0431 110.436 43.8708 110.654 43.6537C110.871 43.4365 111.043 43.1787 111.161 42.895C111.278 42.6112 111.339 42.3071 111.339 42H112Z" fill="#554CC2"/>
		<rect x="100" y="48" width="6" height="1" fill="#554CC2"/>
		<circle cx="14" cy="79" r="5.5" fill="white" stroke="#665EC8"/>
		<circle cx="44" cy="43" r="5.5" fill="white" stroke="#665EC8"/>
		<circle cx="71" cy="76" r="5.5" fill="white" stroke="#665EC8"/>
	</svg>
))

export default EmptyStatus
