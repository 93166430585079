// Core
import React, {FC, memo} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
// Ant Components
import {Image, Modal, Select, Input, Button} from 'antd'
// Ant Icon
import {CopyOutlined} from '@ant-design/icons'
// Style
import './ReceiveAssetsModal.scss'
// Hook
import {useReceiveAssetsModalEf} from './useReceiveAssetsModalEf'
// Elements
import {ComingSoon} from "elements"
// Utils
import {config as configUtils} from "utils";
// QR code component
const QRCode = require('qrcode.react');


type PropsType = {
    children?: never
}

const ReceiveAssetsModal: FC<PropsType> = memo(() => {
    const {
        receiveAssetsMode, wallets, wallet, isQrCode, selected, config, title,
        handlerSelectChange, handlerClose, handlerCopy
    } = useReceiveAssetsModalEf()

    const { Option } = Select;

    return (
        <Modal
            title={title ? title : 'Receive Assets '}
            centered
            visible={receiveAssetsMode}
            onCancel={config?.handlerClose ? config?.handlerClose : handlerClose}
            width={450}
            footer={false}
        >
            <div className={'receive-assets__wrap'}>
                {
                    config && !config.showSelector ?
                        <></>
                    :
                        <>
                            <span className={'receive-assets__text'}>To</span>
                            <Select
                            placeholder="Select assets"
                            optionFilterProp="children"
                            onChange={handlerSelectChange}
                            disabled={isQrCode}
                            value={selected}
                            className={'receive-assets__select'}
                            id={'select-currency'}
                            >
                            {
                                wallets && wallets.items.map((item, key) =>
                                    <Option value={item.id} key={item.id}>
                                        <Image src={configUtils.app.apiURL + item.icon} preview={false}
                                               className={'base-table__icon price-chart__icon'}/>
                                        {item.name}
                                    </Option>
                                )
                            }
                            </Select>
                        </>
                }
                {
                    wallet &&
                    <div>
                        <div className={'twofa__qr'}>
                            <div className={'twofa__qr-box'}>
                                <QRCode value={config ? config.address : wallet.address} size={140} fgColor={'#332E54'}/>
                            </div>
                        </div>
                        <div className={'receive-assets__address'}>
                            <Input value={config ? config.address : wallet.address} disabled className={'receive-assets__input'}/>
                            <CopyToClipboard text={config ? config.address : wallet.address || ''}>
                                <Button
                                    onClick={handlerCopy}
                                    type={'default'}
                                    className={'header-profile-action__action__currency__wallet__address__copy'}
                                >
                                    <CopyOutlined className={'header-profile-action__action__currency__wallet__address__copy'} />
                                </Button>
                            </CopyToClipboard>
                        </div>
                        <Button
                            className={'receive-assets__button'}
                            onClick={config?.handlerClose ? config?.handlerClose : handlerClose}
                            type="primary"
                        >
                            Done
                        </Button>
                    </div>
                }
            </div>
        </Modal>
    )
})

export default ReceiveAssetsModal
