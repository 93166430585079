// Core
import React, {FC, memo} from 'react'
// Ant Components
import {} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
// Style
import './Close.scss'


type PropsType = {
    children?: never
}

const Close: FC<PropsType> = memo(() => {
    return (
        <svg className={'close-icon'} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.60713 6L9.6833 2.3332C9.73486 2.27227 9.6915 2.17969 9.61181 2.17969H8.67666C8.62158 2.17969 8.56884 2.2043 8.53252 2.24648L5.99541 5.27109L3.4583 2.24648C3.42314 2.2043 3.37041 2.17969 3.31416 2.17969H2.379C2.29931 2.17969 2.25595 2.27227 2.30752 2.3332L5.38369 6L2.30752 9.6668C2.29597 9.68038 2.28856 9.69699 2.28617 9.71466C2.28377 9.73233 2.2865 9.75031 2.29403 9.76648C2.30156 9.78264 2.31356 9.79631 2.32863 9.80585C2.34369 9.81539 2.36117 9.82041 2.379 9.82031H3.31416C3.36923 9.82031 3.42197 9.7957 3.4583 9.75352L5.99541 6.72891L8.53252 9.75352C8.56767 9.7957 8.62041 9.82031 8.67666 9.82031H9.61181C9.6915 9.82031 9.73486 9.72774 9.6833 9.6668L6.60713 6Z" fill="#D23E50"/>
        </svg>
    )
})

export default Close
