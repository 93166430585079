// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button, Card, Modal, Skeleton, Table} from 'antd'
// Ant Icon
import {} from '@ant-design/icons'
// Hook
import {useResentTransactionsEf} from './useResentTransactionsEf'
// Element
import {NodeError} from "#/elements"
import {Typography} from "elements"
// Icon
import {EmptyStatus, TransactionsType} from 'icons'
// Types
import {TTransactionsTable} from 'types/cabinet/transactions'
// Utils
import {converterTime} from 'utils'



type PropsType = {
    children?: never
}

const ResentTransactions: FC<PropsType> = memo(() => {
    const {
        loadTransactions, transactions, emptyResentTransactions, showTransactionsDetails, transaction,
        handleReceiveAssets, handlerAllTransactions, handlerTransactionsDetails, handlerShowTransactions
    } = useResentTransactionsEf()

    const { Column } = Table;

    return (
        <>
            <Card className={'home-card home-card_transactions'}>
                <div className={'home-card__header'}>
                    <h2 className="cab-home__title">Resent Transactions</h2>
                    {
                        transactions &&  <Button type="link" className={'ant-btn-default'} onClick={() => handlerAllTransactions()}>All Transaction</Button>
                    }
                </div>
                <div className={'home-card__body'}>
                    {
                        !loadTransactions ?
                            transactions ?
                                <Table
                                    dataSource={transactions.length > 5 ? transactions.slice(0,5) : transactions}
                                    pagination={false}
                                    rowKey="id"
                                    className={'base-table base-table__transactions'}
                                    onRow={(record: TTransactionsTable) => {
                                        return {
                                            onClick: event => {
                                                handlerShowTransactions(record)
                                            }
                                        }
                                    }}
                                >
                                    <Column
                                        title={'Date'}
                                        dataIndex={'created_at'}
                                        key={'created_at'}
                                        fixed={'left'}
                                        width={120}
                                        render={(value) => converterTime.get_transactions_date(value)}
                                    />
                                    <Column
                                        title={'Type'}
                                        dataIndex={'type_id'}
                                        key={'type_id'}
                                        render={(value) => <span className={`type ${value.toLowerCase()}`}><TransactionsType/>{value.toLowerCase()}</span>}
                                    />
                                    <Column
                                        title={'Amount'}
                                        dataIndex={'amount'}
                                        key={'amount'}
                                        className={'amount'}
                                        render={(value, record: TTransactionsTable) =>
                                            <Typography type={'text-16'} color={record.type_id === 'OUT' ? 'error' : 'success'}>
                                                {record.type_id === 'OUT' ? '- ' : '+ '}
                                                {record.amount}
                                                <span style={{marginLeft: 5}}>{record.name}</span>
                                            </Typography>
                                        }
                                    />
                                    <Column
                                        title={'Status'}
                                        dataIndex={'status'}
                                        key={'status'}
                                        fixed={true}
                                        render={(value) => <span className={`status ${value.toLowerCase()}`}>{value.toLowerCase()}</span>}
                                    />
                                </Table>
                            :
                                <NodeError
                                    errorImage={<EmptyStatus/>}
                                    className={'home-card__error'}
                                    description={'You don’t own any crypto. Send yourself some crypto to get started.'}
                                    withHandler={true}
                                    handler={() => handleReceiveAssets()}
                                    requestButtonName={'Receive Assets'}
                                />
                        :
                            <Table
                                dataSource={emptyResentTransactions}
                                pagination={false}
                                rowKey="id"
                                className={'base-table base-table__transactions'}
                            >
                                <Column
                                    title={'Date'}
                                    dataIndex={'created_at'}
                                    key={'created_at'}
                                    fixed={'left'}
                                    width={120}
                                    render={() => <Skeleton.Input active={true} style={{width: 60, height: 24}} size={'small'} />}
                                />
                                <Column
                                    title={'Type'}
                                    dataIndex={'type_id'}
                                    key={'type_id'}
                                    render={() => <Skeleton.Input active={true} style={{width: 60, height: 24}} size={'small'} />}
                                />
                                <Column
                                    title={'Amount'}
                                    dataIndex={'amount'}
                                    key={'amount'}
                                    render={() => <Skeleton.Input active={true} style={{width: 60, height: 24}} size={'small'} />}
                                />
                                <Column
                                    title={'Status'}
                                    dataIndex={'status'}
                                    key={'status'}
                                    render={() => <Skeleton.Input active={true} style={{width: 60, height: 24}} size={'small'} />}
                                />
                            </Table>
                    }
                    <Modal title={'Transaction details '} className={'transactions-details'} centered visible={showTransactionsDetails} onCancel={handlerTransactionsDetails} footer={false}>
                        {
                            transaction &&
                            <Table
                                dataSource={transaction}
                                pagination={false}
                                rowKey="id"
                                className={'base-table base-table__vertical transactions'}
                            >
                                <Column
                                    title={'Date'}
                                    dataIndex={'created_at'}
                                    key={'created_at'}
                                    fixed={'left'}
                                    width={200}
                                    render={(value) => converterTime.get_transactions_full_date(value)}
                                />
                                <Column
                                    title={'Type'}
                                    dataIndex={'type_id'}
                                    key={'type_id'}
                                    render={(value) => <span
                                        className={`type ${value.toLowerCase()}`}><TransactionsType/>{value.toLowerCase()}</span>}
                                />
                                <Column
                                    title={'Amount'}
                                    dataIndex={'amount'}
                                    key={'amount'}
                                    render={(value, row: TTransactionsTable) => `${value} ${row.name}`}
                                />
                                <Column
                                    title={'Status'}
                                    dataIndex={'status'}
                                    key={'status'}
                                    render={(value) => <span className={`status ${value.toLowerCase()}`}>{value}</span>}
                                />
                            </Table>
                        }
                    </Modal>
                </div>
            </Card>
        </>
    )
})

export default ResentTransactions
