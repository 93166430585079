// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Button, Tooltip} from 'antd'
// Ant Icon
import {CopyOutlined} from '@ant-design/icons'
// Style
import './TransactionInfo.scss'
// Element
import {Typography} from 'elements'
// Hook
import {useTransactionInfoEf} from './useTransactionInfoEf'
// Type
import { TTransactionItem } from 'types/cabinet/transactions'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {prepareWalletAddress} from 'utils'


type PropsType = {
	children?: never
	data: TTransactionItem
}

const TransactionInfo: FC<PropsType> = memo(({data}) => {
	const {
		handlerCopy,
	} = useTransactionInfoEf()


	return (
		<div className={'transactions-info'}>
			<div className="transactions-info__row">
				<div className="transactions-info__row__item">
					<Typography type={'text-16'} color={'gray'}>
						Transaction ID
					</Typography>
					<div className="transactions-info__row__item__action">
						<Tooltip placement="top" title={data.hash ? data.hash : '-'}>
							<span> {prepareWalletAddress(data.hash ? data.hash : '-', 8)}</span>
						</Tooltip>
						{
							data.hash &&
							<CopyToClipboard text={data.hash}>
								<Button
									onClick={() => handlerCopy(data, true)}
									type={'default'}
									className={''}
								>
									<CopyOutlined className={''} />
								</Button>
							</CopyToClipboard>
						}

					</div>
				</div>
				<div className="transactions-info__row__item">
					<Typography type={'text-16'} color={'gray'}>
						Send to
					</Typography>
					<div className="transactions-info__row__item__action">
						<Tooltip placement="top" title={data.address_to ? data.address_to : '-'}>
							<span> {prepareWalletAddress(data.address_to ? data.address_to : '-', 8)}</span>
						</Tooltip>
						{
							data.address_to &&
								<CopyToClipboard text={data.address_to}>
									<Button
										onClick={() => handlerCopy(data, false)}
										type={'default'}
										className={''}
									>
										<CopyOutlined className={''} />
									</Button>
								</CopyToClipboard>
						}

					</div>
				</div>
				<div className="transactions-info__row__item">
					<Typography type={'text-16'} color={'gray'}>
						Transaction Fee
					</Typography>
					<Typography className="transactions-info__row__item__text" type={'text-16'} color={'primary'}>
						{data.fee + ' ' + data.name}
					</Typography>
				</div>
				<div className="transactions-info__row__item">
					<Typography type={'text-16'} color={'gray'}>
						Send from
					</Typography>
					{
						data.addresses_from ? data.addresses_from.map((item) =>
							<div className="transactions-info__row__item__action" key={item.id}>
								<Tooltip placement="top" title={item.address}>
									<span> {prepareWalletAddress(item.address,8)}</span>
								</Tooltip>
								<CopyToClipboard text={item.address}>
									<Button
										onClick={() => handlerCopy(data, false)}
										type={'default'}
										className={''}
									>
										<CopyOutlined className={''} />
									</Button>
								</CopyToClipboard>
							</div>
						)
						:
						'-'
					}

				</div>
			</div>
			<div className="transactions-info__row">
				<div className="transactions-info__row__item">
					<Typography type={'text-16'} color={'gray'}>
						Notes
					</Typography>
					<Typography className="transactions-info__row__item__text" type={'text-16'} color={'primary'}>
						{data.description ? data.description : '-'}
					</Typography>
				</div>
			</div>
		</div>
	)
})

export default TransactionInfo
