// Core
import React, {FC, memo} from 'react'
// Ant Components
import {Modal, Radio, Tooltip} from 'antd'
// Ant Icon
import {InfoCircleOutlined, LoadingOutlined} from '@ant-design/icons'
// Style
import './ImportAddress.scss'
// Hook
import {useImportAddressEf} from './useImportAddressEf'
import * as Yup from "yup";
import {Formik} from "formik";
import {Form, Input} from "formik-antd";
import {Button} from "../../../../elements";
import {FormikHandler} from "../../../../components";


type PropsType = {
    children?: never
}

const ImportAddressScheme = Yup.object().shape({
    private_key: Yup.string()
        .required('Required'),
    address: Yup.string()
        .required('Required'),
    label: Yup.string()
})

const ImportAddress: FC<PropsType> = memo(() => {
    const {
        mode, canSubmit, formErrors, load,
        handlerClose, handlerSubmit, handlerChangeInput
    } = useImportAddressEf()

    const init = {
        private_key: '',
        address: '',
        description: ''
    }

    return  <Modal
        title={'Import Address'}
        centered
        visible={mode}
        onCancel={handlerClose}
        width={450}
        footer={false}
    >
        <Formik onSubmit={handlerSubmit} initialValues={init} validationSchema={ImportAddressScheme} autoComplete={'off'}>
            {({errors, values, touched,setFieldValue}) => (
                <Form>
                    <div>
                        <label className={'import-address__label'}>Enter your private key</label>
                        <Tooltip placement="right" title={'Private key format must be compressed WIF. If you are importing a private key that is in an uncompressed format, please convert it to compressed prior to importing.'}>
                            <button className={'cab-settings__icon'}>
                                <InfoCircleOutlined />
                            </button>
                        </Tooltip>
                    </div>
                    <Form.Item
                        name={'private_key'}
                        wrapperCol={{span: 24}}
                    >
                        <Input
                            type={'text'}
                            className={'input-item medium'}
                            size={'middle'}
                            name='private_key'
                            onChange={handlerChangeInput}
                            placeholder={'Enter private key'}
                        />
                    </Form.Item>
                    <label className={'import-address__label'}>Address</label>
                    <Form.Item
                        name={'address'}
                        wrapperCol={{span: 24}}
                    >
                        <Input
                            type={'text'}
                            className={'input-item medium'}
                            size={'middle'}
                            name='address'
                            onChange={handlerChangeInput}
                            placeholder={'Enter address'}
                        />
                    </Form.Item>
                    <Form.Item
                        name={'description'}
                        label={'Enter a label (optional)'}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <Input.TextArea
                            className={'input-item in-field'}
                            size={'large'}
                            name='description'
                            showCount
                            maxLength={100}
                            placeholder={'Enter descriptions for address (optional)'}
                        />
                    </Form.Item>
                    <Button
                        className={'send-assets__submit'}
                        type="submit"
                        size={"full"}
                        color={"secondary"}
                        variant={"contained"}
                        loaderPosition={"center"}
                        disabled={canSubmit}
                        loader={
                            load
                                ? () => <LoadingOutlined className={'sign__loader-icon'} spin/>
                                : null
                        }
                    >
                        Import Address
                    </Button>
                    <FormikHandler data={formErrors}/>
                </Form>
                )}
        </Formik>
    </Modal>
})

export default ImportAddress
